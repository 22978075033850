import {Component} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {FileTypes} from "../../components/file-picker/FileTypes.enum";
import {MediaGalleryItem} from "../../components/media-gallery/media-gallery-item.interface";
import {MediaListItem} from "../../components/media-list/MediaListItem.interface";

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss']
})
export class TestPageComponent {

  serverMediaList: MediaListItem[] = [];
  galleryMediaList: MediaGalleryItem[] = [];

  mediaField1 = new FormControl(null);
  mediaFiled2 = new FormControl(null, [Validators.required]);
  mediaFiled3 = new FormControl(null, [Validators.required]);

  geocodeTextbox = new FormControl('starting text');

  list: any[] = [];

  constructor() {
    this.mediaField1.valueChanges.subscribe((v: any) => console.log('mediaField1', v));
    this.mediaFiled2.valueChanges.subscribe((v: any) => console.log('mediaFiled2', v));
    this.mediaFiled3.valueChanges.subscribe((v: any) => console.log('mediaFiled3', v));
    (window as any).mediaField1 = this.mediaField1;
    (window as any).mediaFiled2 = this.mediaFiled2;
    (window as any).mediaFiled3 = this.mediaFiled3;
    (window as any).geocodeTextbox = this.geocodeTextbox;
    for (let i = 0; i < 10; i++) {
      this.serverMediaList.push(
        {
          mediaType: 'image',
          mediaName: `image${i + 1}`,
          thumbnailUrl: 'assets/logo-o.png'
        }
      );

      this.galleryMediaList.push(
        {
          // mediaName: "Image 1",
          mediaType: 'image',
          mediaUrl: 'assets/logo-o.png',
          resolveMediaUrl: false,
          resolveThumbnailUrl: false,
          thumbnailUrl: 'assets/logo-o.png'
        }
      );

      this.list.push({
        label: `Test ${i+1}`,
        removable: i % 2 === 0
      });
    }

    this.galleryMediaList.push(
      {
        // mediaName: "Image 1",
        mediaType: 'video',
        mediaUrl: 'assets/logo-o.png',
        resolveMediaUrl: false,
        resolveThumbnailUrl: false,
        thumbnailUrl: 'assets/logo-o.png'
      }
    );
    this.galleryMediaList.push(
      {
        // mediaName: "Image 1",
        mediaType: 'audio',
        mediaUrl: 'assets/logo-o.png',
        resolveMediaUrl: false,
        resolveThumbnailUrl: false,
        thumbnailUrl: 'assets/logo-o.png'
      }
    );
  }

  protected readonly FileTypes = FileTypes;
}
