<div class="wrapper">
  <div class="left">
    <div class="content">
      <div class="logo">
        <app-logo></app-logo>
      </div>
      <div class="form">
        <app-login-form></app-login-form>
      </div>
    </div>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
  <div class="right">

  </div>
</div>
