import {Component, Input} from '@angular/core';

export interface ShadingStepArray extends Array<[string, number]> {}
export const DEFAULT_SHADING: ShadingStepArray = [['white', 0], ['black', 100]];
export const LIGHT_BLUE_SHADING: ShadingStepArray = [['white', 0], ['rgb(193, 213, 254)', 50], ['rgb(177, 202, 254)', 100]];
export const LIGHT_GREEN_SHADING: ShadingStepArray = [['white', 0], ['rgb(100,192,97)', 50], ['rgb(55,197,67)', 100]];
export const LIGHT_RED_SHADING: ShadingStepArray = [['white', 0], ['rgb(209, 74, 74)', 50], ['rgb(197, 55, 55)', 100]];


@Component({
  selector: 'app-shaded-placeholder-icon',
  templateUrl: './shaded-placeholder-icon.component.html',
  styleUrls: ['./shaded-placeholder-icon.component.scss']
})
export class ShadedPlaceholderIconComponent {

  @Input() angle: number = 155;
  @Input() shadeSteps: [color: string, percent: number][] = DEFAULT_SHADING;
  @Input() gradientFunction: string = 'linear-gradient';

  @Input() backgroundColor?: string;
  @Input() shadeColor?: string;

  generateGradient(): string {
    if (this.backgroundColor || this.shadeColor) {
      return this.generateSimpleGradient();
    }

    return this.gradient(this.gradientFunction, this.angle, this.shadeSteps);
  }

  private generateSimpleGradient(): string {
    const shadeColor = this.shadeColor ?? 'white';
    const bgColor = this.backgroundColor ?? 'black';
    const steps: ShadingStepArray = [[shadeColor, 0], [bgColor, 100]];
    return this.gradient(this.gradientFunction, this.angle, steps);
  }

  private gradient(fn: string, angle: number, shadeSteps: ShadingStepArray): string {
    const steps = shadeSteps.map(([color, percentage]) => {
      return `${color} ${percentage}%`
    }).join(', ');
    return `${(fn)}(${angle}deg, ${steps})`;
  }

}
