import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import * as turf from "@turf/turf";
import {firstValueFrom, take} from "rxjs";
import {SystemCityFormComponent} from "../../components/forms/system-city-form/system-city-form.component";
import {SystemCityDto} from "../../dtos/local/SystemCity.dto";
import {MapboxGeocodeFeature} from "../../dtos/remote/mapbox/MapboxGeocodeFeature.interface";
import {CitiesService} from "../../services/api/cities.service";
import {ItineroMap} from "../../utility/maps/ItineroMap";
import * as geoutils from '../../utility/maps/lib/GeoUtils';

@Component({
  selector: 'app-system-city-detail-page',
  templateUrl: './system-city-detail-page.component.html',
  styleUrls: ['./system-city-detail-page.component.scss']
})
export class SystemCityDetailPageComponent implements OnInit {

  @ViewChild('form') form?: SystemCityFormComponent;

  map!: ItineroMap;
  id?: number;
  dto?: SystemCityDto;
  onlyMap = false;
  titleParams: any = {
    cityName: ''
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snack: MatSnackBar,
    private citysvc: CitiesService,
    private tx: TranslocoService
  ) {
  }

  async ngOnInit() {
    const map = new ItineroMap('map');
    this.map = map;
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      const param = params.get('cityId');
      if (param === 'new') {
        console.log('Entering create city mode');
        this.id = -1;
        this.onlyMap = true;

        this.titleParams.cityName = await firstValueFrom(this.tx.selectTranslate('generic.entity.city.new'));
        this.enablePointSelectionMode();
        return;
      }

      const cityId = Number(param);
      if (isNaN(cityId) || cityId === undefined || cityId === null) {
        console.warn("No cityId param was specified.");
        return;
      }

      const dto = await this.citysvc.getCity(cityId);
      const center = geoutils.NumberArrayAsCoordinates(dto.coordinates);
      this.id = cityId;
      this.dto = dto;
      this.titleParams.cityName = dto.name;

      map.addMarker(center);
      map.api.jumpTo({
        center: center,
        zoom: 11
      });
    });
  }

  public async handleInitialGeocodingSelection($event: MapboxGeocodeFeature) {
    console.log(`geocoding event:`, $event);
    this.form?.form.controls.center.setValue(turf.point($event.center));
    this.form?.form.controls.name.setValue($event.text);
    this.map.deactivateCurrentMode();
    this.onlyMap = false;
  }

  public async enablePointSelectionMode() {
    const point = await this.map.selectPoint(true);
    if (point) {
      this.form?.form.controls.center.setValue(point);
      this.onlyMap = false;
    }
  }

  async save(form: SystemCityFormComponent) {
    await form.save();
    this.titleParams.cityName = form.form.controls.name.value;
    this.snack.open(this.tx.translate('generic.entity.saved'), 'OK', { duration: 1500 });
  }
}
