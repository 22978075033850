import {Component, Input} from '@angular/core';
import {LayoutService} from "../../../services/layout.service";

@Component({
  selector: 'app-system-page-layout',
  templateUrl: './system-page-layout.component.html',
  styleUrls: ['./system-page-layout.component.scss']
})
export class SystemPageLayoutComponent {

  @Input() titleParams: any;
  @Input() backButton = false;
  @Input() fullwidth = true;

  @Input() overrideTitle?: string;

  constructor(
    private layoutsvc: LayoutService
  ) {
    this.layoutsvc.showHomeLayoutLeftPane();
  }

}
