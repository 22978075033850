import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {SaveAvatarPayload} from "../../dtos/local/SaveAvatarPayload";
import {SystemAvatarDto} from "../../dtos/local/SystemAvatar.dto";
import {generateMediaFieldFormDataEntries} from "../../utility/formdata.utility";

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  private readonly baseUrl = `${environment.api.host}/avatar`;

  constructor(
    private http: HttpClient
  ) { }

  async getAvatars(): Promise<SystemAvatarDto[]> {
    return firstValueFrom(
      this.http.get<SystemAvatarDto[]>(
        this.baseUrl
      )
    );
  }

  async getAvatarById(id: number): Promise<SystemAvatarDto> {
    return firstValueFrom(
      this.http.get<SystemAvatarDto>(
        `${this.baseUrl}/getbyid/${id}`
      )
    );
  }

  async updateAvatar(id: number, payload: SaveAvatarPayload) {
    const fd = this.getSaveAvatarFormData(payload);
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/update/${id}`,
        fd
      )
    );
  }

  async createAvatar(payload: SaveAvatarPayload) {
    const fd = this.getSaveAvatarFormData(payload);
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/create`,
        fd
      )
    );
  }

  private getSaveAvatarFormData(payload: SaveAvatarPayload) {
    const formData = new FormData();
    formData.append('name', payload.name);
    generateMediaFieldFormDataEntries(
      formData,
      payload.thumbnail,
      'file'
    );
    
    return formData;
  }

  async deleteAvatar(id: number) {
    return firstValueFrom(
      this.http.delete(`${this.baseUrl}/delete/${id}`)
    );
  }

}
