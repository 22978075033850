import {LabelValueDto} from "../../dtos/local/LabelValue.dto";

export const intensityDomain: LabelValueDto<number>[] = [
  {
    label: "domains.intensity.easy",
    value: 0
  },
  {
    label: "domains.intensity.medium",
    value: 1
  },
  {
    label: "domains.intensity.hard",
    value: 2
  },
];

export const intensityIcons: { [value: number]: string } = {
  0: 'assets/icons/intensity/easy.png',
  1: 'assets/icons/intensity/medium.png',
  2: 'assets/icons/intensity/hard.png',
};
