export function formDataEntries (val: (string | string[] | File | File[] | (string | File)[]), name: string): [string, string | File][] {
  if (Array.isArray(val)) {
    const files: [string, File][] = [];
    const fileOrder: [string, string][] = [];

    (val as (string|File)[]).forEach((item: string | File) => {
      if (item instanceof File) {
        files.push([name, item]);
        fileOrder.push([`${name}Order`, item.name]);
      } else {
        fileOrder.push([`${name}Order`, item]);
      }
    });

    return [...files, ...fileOrder];
  } else {
    if (val instanceof File) {
      return [[name, val]];
    } else {
      return [];
    }
  }
}

export function generateMediaFieldFormDataEntries(
  formData: FormData,
  mediaFieldValue: (string | string[] | File | File[] | (string | File)[]),
  formDataMediaFieldName: string
) {
  formDataEntries(mediaFieldValue, formDataMediaFieldName).forEach(entry => {
    const [fdEntryName, fdEntryContent] = entry;
    formData.append(fdEntryName, fdEntryContent);
  });
}
