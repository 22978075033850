import * as turf from "@turf/turf";
import * as geojson from "geojson";
import * as maplibre from "maplibre-gl";
import {GeoJSONSource, MapOptions} from "maplibre-gl";
import {BehaviorSubject, filter, take} from "rxjs";
import {ItineroMapDefaultAssets, ItineroMapDefaultLayers, ItineroMapDefaults} from '../../config/map';
import * as geoutils from './lib/GeoUtils';
import {ItineroLayer} from "./lib/ItineroLayer.interface";
import {MapModeInterface} from "./lib/MapMode.interface";
import {MapModeEventTypeEnum} from "./lib/MapModeEventType.enum";
import {PointSelectMode} from "./modes/PointSelectMode";

interface InitializedItineroLayer extends ItineroLayer {
  data: geojson.FeatureCollection;
  source: GeoJSONSource;
}

export class ItineroMap {

  api!: maplibre.Map;

  constructor(
    private container: string | HTMLElement,
    private options?: Partial<maplibre.MapOptions>,
  ) {
    this.initialize();
  }

  public addMarker(lng: maplibre.LngLatLike | geojson.Feature<geojson.Point> | geojson.Point, lat?: number) {
    this.whenReady(() => {
      const layer = this.markersLayer;
      layer.data.features.push(turf.point(geoutils.LngLatLikeToCoordinates(lng, lat)));
      layer.source.setData(layer.data);
    });
  }

  public addLine(geom: geojson.LineString) {
    this.whenReady(() => {
      const layer = this.linesLayer;
      layer.data.features.push(turf.lineString(geom.coordinates));
      layer.source.setData(layer.data);
    });
  }

  public addCircle(point: geojson.Feature<geojson.Point, { radius: number }>) {
    this.whenReady(() => {
      const feature = turf.circle(
        point,
        point.properties.radius,
        { units: 'meters' }
      );
      const layer = this.circlesLayer;
      layer.data.features.push(feature);
      layer.source.setData(layer.data);
    });
  }

  public addShadowMarker(lng: maplibre.LngLatLike | geojson.Feature<geojson.Point> | geojson.Point, lat?: number) {
    this.whenReady(() => {
      const layer = this.shadowMarkersLayer;
      layer.data.features.push(turf.point(geoutils.LngLatLikeToCoordinates(lng, lat)));
      layer.source.setData(layer.data);
    });
  }

  public addSoloShadowMarker(lng: maplibre.LngLatLike | geojson.Feature<geojson.Point> | geojson.Point, lat?: number) {
    this.whenReady(() => {
      this.clearShadowMarkers();
      const layer = this.shadowMarkersLayer;
      layer.data.features.push(turf.point(geoutils.LngLatLikeToCoordinates(lng, lat)));
      layer.source.setData(layer.data);
    });
  }

  public clearMarkers(clearShadows = false) {
    this.whenReady(() => {
      this.clearLayer(this.markersLayer);
      if (clearShadows) {
        this.clearShadowMarkers();
      }
    });
  }

  public clearLines() {
    this.whenReady(() => {
      this.clearLayer(this.linesLayer);
    });
  }

  public clearCircles() {
    this.whenReady(() => {
      this.clearLayer(this.circlesLayer);
    });
  }

  public clearShadowMarkers() {
    this.whenReady(() => {
      this.clearLayer(this.shadowMarkersLayer);
    });
  }

  public addSoloMarker(lng: maplibre.LngLatLike | geojson.Feature<geojson.Point> | geojson.Point, lat?: number) {
    this.clearMarkers();
    this.addMarker(lng, lat);
  }

  public async selectPoint(caged = false): Promise<geojson.Feature<geojson.Point> | null> {
    this.activateMode(this._pointSelectMode, { caged: caged });
    return new Promise((res, rej) => {
      this._pointSelectMode.bus
        .pipe(
          filter(ev => [MapModeEventTypeEnum.Data, MapModeEventTypeEnum.Deactivated].includes(ev.type)),
          take(1)
        )
        .subscribe((event) => {
          if (event.type === MapModeEventTypeEnum.Deactivated) {
            this._currentMode = undefined;
            return res(null);
          }

          res(event.data!);
          this._currentMode!.deactivate();
        });
    });
  }

  public whenReady(action: Function) {
    // subscribe to first true value from the "_installed" subject (wait for first true value, emits @ subscribe)
    this._installed.pipe(
      filter(v => v),
      take(1)
    ).subscribe(() => {
      action();
    });
  }

  public deactivateCurrentMode(params?: any) {
    if (this._currentMode) {
      this._currentMode.deactivate(params);
    }
  }

  private get markersLayer(): InitializedItineroLayer {
    return this.defaultGeojsonLayers['defaultMarkers'] as InitializedItineroLayer;
  }

  private get shadowMarkersLayer(): InitializedItineroLayer {
    return this.defaultGeojsonLayers['defaultMarkersShadow'] as InitializedItineroLayer;
  }

  private get linesLayer(): InitializedItineroLayer {
    return this.defaultGeojsonLayers['defaultLineStrings'] as InitializedItineroLayer;
  }

  private get circlesLayer(): InitializedItineroLayer {
    return this.defaultGeojsonLayers['defaultCircles'] as InitializedItineroLayer;
  }

  private async initialize() {
    const mapOptions = Object.assign({
      container: this.container,
      ...ItineroMapDefaults
    } as MapOptions, this.options) as MapOptions;

    this.api = new maplibre.Map(mapOptions);
    this.initializeLoadingOverlay();

    this.api.once('load', async () => {
      await this.install();
      this.whenReady(() => {
        this.fadeOverlay();
      });
    });

    (window as any).map = this;
  }

  private initializeLoadingOverlay() {
    const container: HTMLElement = this.api.getContainer();
    container.style.position = 'relative';
    container.style.outline = 'none';

    const overlay = this._loadingOverlay;
    overlay.style.display = 'block';
    overlay.style.height= '100%';
    overlay.style.width = '100%';
    overlay.style.zIndex = "100";
    overlay.style.position = 'absolute';
    overlay.style.left = '0';
    overlay.style.top = '0';
    overlay.style.backgroundColor = 'white';
    overlay.style.transition = 'all 150ms linear';

    container.appendChild(this._loadingOverlay);
  }

  private fadeOverlay() {
    const overlay = this._loadingOverlay;
    overlay.style.backgroundColor = 'transparent';
    overlay.style.pointerEvents = 'none';
  }

  private async install() {
    await this.loadAssets();
    this.createDefaultLayersAndSources();
    this.api.resize();

    this._installed.next(true);
  }

  private async loadAssets() {
    const assets = Object.entries(ItineroMapDefaultAssets);
    return Promise.all(assets.map(assetEntry => {
      const [assetName, assetInfo] = assetEntry;
      return new Promise((res, rej) => {
        this.api.loadImage(assetInfo.url, (err, img) => {
          if (err || !img) {
            rej(`Map initialization failure: could not load asset '${assetName}'.`);
            return;
          }

          this.api.addImage(assetInfo.id, img);
          res(null);
        });
      });
    }));
  }

  private createDefaultLayersAndSources() {
    const layers = Object.entries(this.defaultGeojsonLayers);
    layers.forEach(([layerName, meta]) => {
      const sourceId = `${meta.id}-source`;
      const layerId = `${meta.id}-layer`;

      const data: geojson.FeatureCollection = turf.featureCollection([]);
      const sourceSettings = {
        type: 'geojson',
        data: data
      } as maplibre.SourceSpecification;

      const layerSettings: maplibre.AddLayerObject = {
        id: layerId,
        source: sourceId as any,
        ...meta.layerSettings as any
      };

      this.api.addSource(sourceId, sourceSettings);
      this.api.addLayer(layerSettings);

      (meta as InitializedItineroLayer).data = data;
      (meta as InitializedItineroLayer).source = this.api.getSource(sourceId) as maplibre.GeoJSONSource;
    });
  }

  private clearLayer(layer: InitializedItineroLayer) {
    layer.data = turf.featureCollection([]);
    layer.source.setData(layer.data);
  }

  private activateMode(mode: MapModeInterface, activationParameters?: any) {
    if (this._currentMode) {
      this._currentMode.deactivate();
      this._currentMode = undefined;
    }
    this._currentMode = mode;
    this._currentMode.activate(activationParameters);
  }

  private _loadingOverlay = document.createElement('div');

  private _installed = new BehaviorSubject(false);

  private _currentMode?: MapModeInterface;
  private _pointSelectMode = new PointSelectMode(this);

  private readonly defaultGeojsonLayers = ItineroMapDefaultLayers;

}
