<app-system-page-layout [titleParams]="{ name: entity?.name }" [backButton]="true">
  <ng-container header-title-controls>
    <button mat-raised-button id="delete" class="accent_error" style="margin-right: 20px;" *ngIf="!this.create" (click)="formRef.delete()">
      <mat-icon>delete</mat-icon>
      {{"generic.actions.deleteEntity" | transloco}}
    </button>
    <button mat-raised-button class="accent_success"
            [disabled]="formRef.form.invalid || formRef.form.pristine" (click)="formRef.save()"
    >
      <mat-icon>save</mat-icon>
      {{"generic.actions.saveModifications" | transloco}}
    </button>
  </ng-container>
  <ng-container content>
    <mat-card class="content-wrapper">
      <ng-container id="editor">
        <h2>{{'pages.systemAvatars.editorHeader' | transloco}}</h2>
        <app-system-avatar-form #formRef [dto]="entity"></app-system-avatar-form>
      </ng-container>
    </mat-card>
  </ng-container>
</app-system-page-layout>
