import {Component, ElementRef, forwardRef, Input, Renderer2, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileTypes} from "../file-picker/FileTypes.enum";

@Component({
  selector: 'app-media-picker',
  templateUrl: './media-picker.component.html',
  styleUrls: ['./media-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaPickerComponent),
      multi: true
    }
  ],
  host: {
    // '(change)': '_onChange($event)',
    // '(change)': '_onChange($event.target.value)'
    '(blur)': '_onTouched()'
  }
})
export class MediaPickerComponent implements ControlValueAccessor {

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;

  @Input({ required: true }) type!: FileTypes;
  @Input() multi: boolean = false;
  @Input() max?: number = Infinity;

  selection: File[] = [];
  // fileList: MediaListItem[] = [];

  _onChange!: (_: any) => void;
  _onTouched!: any;

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private snack: MatSnackBar
  ) {
    // (window as any).picker = this;
  }

  // <editor-fold desc="Event handlers">
  handleDrop($event: DragEvent, wrapper: HTMLDivElement) {
    console.log('handleDrop', $event);
    $event.preventDefault();
    this.unsetFileHoverStyle(wrapper);

    const files = ($event.dataTransfer?.items
      ? Array.from($event.dataTransfer.items)
        .filter((item: any) => item.kind === 'file')
        .map((item: any) => item.getAsFile())
      : Array.from($event.dataTransfer?.files ?? [])) || [];

    const allowedExtensions = this.type.split(',').map(ext => ext.trim());
    const unsupported = files.find(file => !allowedExtensions.some(ext => file.name.endsWith(ext)));
    if (unsupported) {
      const extension = unsupported.name.split('.').pop() ?? '???';
      this.snack.open(`'.${extension}' files are not supported.`, 'OK', { duration: 3000 });
      return;
    }

    this.selectFilesFromList(
      files.filter((file: any) => file) as File[]
    );
  }

  handleDragover($event: DragEvent, wrapper: HTMLDivElement) {
    $event.preventDefault();
  }

  handleDragEnter($event: DragEvent, wrapper: HTMLDivElement) {
    this.setFileHoverStyle(wrapper);
  }

  handleDragLeave($event: DragEvent, wrapper: HTMLDivElement) {
    this.unsetFileHoverStyle(wrapper);
  }

  handleClick($event: MouseEvent, wrapper: HTMLDivElement, input: HTMLInputElement) {
    input.click();
  }

  handleFileSelected($event: Event, input: HTMLInputElement) {
    this.selectFilesFromList(Array.from(input.files ?? []));
  }

  private setFileHoverStyle(wrapper: HTMLDivElement) {
    wrapper.classList.toggle('dragover', true);
  }

  private unsetFileHoverStyle(wrapper: HTMLDivElement) {
    wrapper.classList.toggle('dragover', false);
  }
  // </editor-fold>

  // <editor-fold desc="CVA Interface Members">
  registerOnChange(fn: ($event: any) => void): void {
    // console.log('registering onchange', fn);
    // this._onChange = (x) => {
    //   console.log('onchange called');
    //   fn(x);
    // }; // fn;
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
  }
  // </editor-fold>

  // handleItemRemoved($event: { item: MediaListItem; index: number }, input: HTMLInputElement) {
  //   if (!this.multi) {
  //     input.value = '';
  //   }
  //   this.selection.splice($event.index, 1);
  //   this.emitCVAChangedEvent();
  // }
  //
  // handleItemMoved($event: { item: MediaListItem; oldIndex: number; newIndex: number }) {
  //   moveItemInArray(this.selection, $event.oldIndex, $event.newIndex);
  //   this.emitCVAChangedEvent();
  // }

  reset() {
    if (this.input) {
      this.input.nativeElement.value = '';
    }
  }

  private selectFilesFromList(files: File[]) {
    if (files.length === 0) {
      return;
    }

    let value;
    if (this.multi) {
      value = files.slice(0, Math.min(this.max ?? Infinity, files.length));
    } else {
      value = [files[0]];
    }

    let mediaType: 'image' | 'audio' | 'video';
    switch (this.type) {
      case FileTypes.Images:
        mediaType = 'image';
        break;
      case FileTypes.Videos:
        mediaType = 'video';
        break;
      case FileTypes.Audio:
        mediaType = 'audio';
        break;
    }

    this.selection = value;
    // this.fileList.forEach(oldFile => URL.revokeObjectURL(oldFile.thumbnailUrl));
    // this.fileList = this.selection.map(file => ({
    //   mediaType: mediaType,
    //   mediaName: file.name,
    //   thumbnailUrl: mediaType === 'image' ? URL.createObjectURL(file) : '',
    //   resolveThumbnailUrl: false
    // }));

    this.emitCVAChangedEvent();
  }

  private emitCVAChangedEvent() {
    if (this.selection.length === 0) {
      this._onChange(null);
    } else {
      // this._onChange({
      //   selection: this.selection,
      //   fileList: this.fileList
      // });
      this._onChange(this.selection);
    }
  }

  protected readonly Infinity = Infinity;
}
