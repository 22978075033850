import {Component, Inject} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChoiceDialogComponent} from "../choice-dialog/choice-dialog.component";

export interface InputDialogData {
  message: string;
  messageParameters?: any;
  confirmText?: string;
  denyText?: string;
  inputType?: string;
}

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent {

  input = new FormControl<string>('');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputDialogData,
    private ref: MatDialogRef<ChoiceDialogComponent>
  ) {
    this.ref.updateSize(window.innerWidth * .35 + 'px', window.innerHeight * .35 + 'px');
  }

  deny() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(this.input.value);
  }

}
