import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {CreatePathPayload} from "../../dtos/local/CreatePathPayload.interface";
import {SavePathTypePayload} from "../../dtos/local/SavePathTypePayload";
import {SystemCityDto} from "../../dtos/local/SystemCity.dto";
import {SystemPathDto} from "../../dtos/local/SystemPath.dto";
import {SystemPathTypeDto} from "../../dtos/local/SystemPathType.dto";
import {UpdatePathPayload} from "../../dtos/local/UpdatePathPayload.interface";
import {WaypointPayload} from "../../dtos/local/WaypointPayload.interface";
import {formDataEntries, generateMediaFieldFormDataEntries} from "../../utility/formdata.utility";

@Injectable({
  providedIn: 'root'
})
export class PathsService {
  private readonly baseUrl = `${environment.api.host}/path`;

  constructor(
    private http: HttpClient
  ) { }

  async getPathTypes() {
    return firstValueFrom(
      this.http.get<SystemPathTypeDto[]>(
        `${this.baseUrl}/getallpathtypes`
      )
    );
  }

  async getPathTypeById(id: number) {
    return firstValueFrom(
      this.http.get<SystemPathTypeDto>(
        `${this.baseUrl}/getpathtypebyid/${id}`
      )
    );
  }

  async getPathsByCity(cityCode: string, page = 1, size = 1000) {
    return firstValueFrom(
      this.http.get<SystemPathDto[]>(
        `${this.baseUrl}/getallpathfilterbycity`,
        {
          params: {
            cityCode: cityCode,
            page: page,
            pageSize: size
          }
        }
      )
    );
  }

  async getPathById(id: number, lc?: string) {
    let params: any = {};
    if (lc) {
      params.langCode = lc;
    }

    return firstValueFrom(
      this.http.get<SystemPathDto>(
        `${this.baseUrl}/getpathbyid/${id}`,
        {
          params: params
        }
      )
    );
  }

  async createPath(payload: CreatePathPayload) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/createpath`,
        this.serializeCreatePathPayload(payload)
      )
    );
  }

  async updatePath(id: number, payload: UpdatePathPayload) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/updatepath/${id}`,
        this.serializeUpdatePathPayload(payload)
      )
    );
  }

  async updatePathType(id: number, payload: SavePathTypePayload) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/updatepathtype/${id}`,
        payload
      )
    );
  }

  async createPathType(payload: SavePathTypePayload) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/createpathtype`,
        payload
      )
    );
  }

  serializeCreatePathPayload(createPathPayload: CreatePathPayload): FormData {
    const formData = new FormData();

    // Serialize top-level properties
    formData.append('pathGroupId', createPathPayload.pathGroupId.toString());
    formData.append('pathTypeId', createPathPayload.pathType.toString());
    formData.append('name', createPathPayload.name);
    formData.append('description', createPathPayload.description);
    formData.append('langContentCode', createPathPayload.langContentCode);
    formData.append('intensity', createPathPayload.intensity.toString());
    formData.append('durationMinutes', createPathPayload.durationMinutes.toString());
    formData.append('lengthMeters', createPathPayload.lengthMeters.toString());
    formData.append('version', createPathPayload.version.toString());
    formData.append('published', JSON.stringify(createPathPayload.published));

    // Serialize images array
    if (createPathPayload.images) {
      generateMediaFieldFormDataEntries(
        formData,
        createPathPayload.images,
        'images'
      );
    }

    // Serialize pathPois array
    if (createPathPayload.pathPois) {
      this.serializeWaypointsPayload(formData, createPathPayload.pathPois);
    }

    return formData;
  };

  serializeUpdatePathPayload(updatePathPayload: UpdatePathPayload): FormData {
    const formData = new FormData();

    // Serialize top-level properties
    formData.append('pathTypeId', updatePathPayload.pathType.toString());
    formData.append('name', updatePathPayload.name);
    formData.append('description', updatePathPayload.description);
    formData.append('langContentCode', updatePathPayload.langContentCode);
    formData.append('durationMinutes', updatePathPayload.durationMinutes.toString());
    formData.append('lengthMeters', updatePathPayload.lengthMeters.toString());
    formData.append('intensity', updatePathPayload.intensity.toString());
    formData.append('version', updatePathPayload.version.toString());
    formData.append('published', JSON.stringify(updatePathPayload.published));

    if (updatePathPayload.audio) {
      formData.append('Audio', updatePathPayload.audio);
    }

    // Serialize images array
    if (updatePathPayload.images) {
      generateMediaFieldFormDataEntries(
        formData,
        updatePathPayload.images,
        'images'
      );
    }

    // Serialize pathPois array
    if (updatePathPayload.pathPois) {
      this.serializeWaypointsPayload(formData, updatePathPayload.pathPois);
    }

    return formData;
  };

  serializeWaypointsPayload(formData: FormData, waypoints: WaypointPayload[]) {
    (waypoints ?? []).forEach((waypoint, index) => {
      const coordinates = waypoint.coordinates.split(',').map(s => s.trim());
      coordinates.forEach((coordinate, i) => {
        formData.append(`pathPois[${index}][coordinates][${i}]`, coordinate);
      });

      // Use a unique key for each array element
      formData.append(`pathPois[${index}][name]`, waypoint.name);
      formData.append(`pathPois[${index}][description]`, waypoint.description);
      formData.append(`pathPois[${index}][areaRadiusMeters]`, waypoint.areaRadiusMeters.toString());

      // Add optional properties
      if (waypoint.langContentCode) {
        formData.append(`pathPois[${index}][langContentCode]`, waypoint.langContentCode);
      }

      if (waypoint.pathPoiId) {
        formData.append(`pathPois[${index}][pathPoiId]`, waypoint.pathPoiId.toString());
      }

      if (waypoint.images) {
        generateMediaFieldFormDataEntries(
          formData,
          waypoint.images,
          `pathPois[${index}][images]`
        );
        // waypoint.images.forEach((image, imageIndex) => {
        //   formData.append(`pathPois[${index}][images][${imageIndex}]`, image);
        // });
      }

      if (waypoint.video) {
        formData.append(`pathPois[${index}][video]`, waypoint.video);
      }

      if (waypoint.audio) {
        formData.append(`pathPois[${index}][audio]`, waypoint.audio);
      }
    });
  }

  async delete(id: number) {
    return firstValueFrom(
      this.http.delete(`${this.baseUrl}/deletepath/${id}`)
    );
  }

  async deletePathType(id: number) {
    return firstValueFrom(
      this.http.delete(`${this.baseUrl}/deletepathtype/${id}`)
    );
  }

  async getPathTypesByCity(cityCode: string) {
    return firstValueFrom(
      this.http.get<{ [pathTypeId: number]: SystemPathDto[] }>(
        `${this.baseUrl}/getallpathtypefilteredbycity`,
        {
          params: {
            cityCode: cityCode
          }
        }
      )
    );
  }

  async duplicatePath(sourcePathId: number, newIntensity: number) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/duplicatepath`,
        { id: sourcePathId, intensity: newIntensity }
      )
    );
  }

}
