<mat-accordion>
  <mat-expansion-panel class="panel" style="box-shadow: none;">
    <mat-expansion-panel-header class="panel-header">
      <mat-panel-title class="panel-header-wrapper">
        <div class="icon">
          <ng-content select="icon"></ng-content>
        </div>
        <div class="panel-title-wrapper">
          <ng-content select="title"></ng-content>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container class="panel-content-wrapper">
      <ng-content select="content"></ng-content>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
