import {RawJwtPayloadDto} from "../app/dtos/remote/JwtPayload.dto";
import LoginRequestDto from "../app/dtos/remote/LoginRequest.dto";

const mock_permissions = [{"Id":5,"Code":"CAN_CREATE_ROLE"},{"Id":6,"Code":"CAN_UPDATE_ROLE"},{"Id":7,"Code":"CAN_DELETE_ROLE"},{"Id":8,"Code":"CAN_GET_ROLE"},{"Id":9,"Code":"CAN_CREATE_PERMISSION"},{"Id":10,"Code":"CAN_READ_PERMISSION"},{"Id":11,"Code":"CAN_UPDATE_PERMISSION"},{"Id":12,"Code":"CAN_DELETE_PERMISSION"},{"Id":13,"Code":"CAN_CREATE_USER"},{"Id":14,"Code":"CAN_READ_USER"},{"Id":15,"Code":"CAN_READ_USERS"},{"Id":16,"Code":"CAN_UPDATE_USER"},{"Id":17,"Code":"CAN_DELETE_USER"},{"Id":18,"Code":"CAN_DELETE_USERS"},{"Id":20,"Code":"CAN_DELETE_GROUP"},{"Id":21,"Code":"CAN_CREATE_GROUP"},{"Id":22,"Code":"CAN_UPDATE_GROUP"},{"Id":23,"Code":"CAN_READ_GROUP"},{"Id":24,"Code":"CAN_READ_GROUPS"},{"Id":25,"Code":"CAN_READ_PATH_TYPE"},{"Id":26,"Code":"CAN_CREATE_PATH_TYPE"},{"Id":27,"Code":"CAN_UPDATE_PATH_TYPE"},{"Id":28,"Code":"CAN_DELETE_PATH_TYPE"},{"Id":29,"Code":"CAN_READ_PATH"},{"Id":30,"Code":"CAN_READ_PATHS"},{"Id":31,"Code":"CAN_CREATE_PATH"},{"Id":32,"Code":"CAN_UPDATE_PATH"},{"Id":33,"Code":"CAN_DELETE_PATH"},{"Id":34,"Code":"CAN_READ_SPOT"},{"Id":35,"Code":"CAN_READ_SPOTS"},{"Id":36,"Code":"CAN_CREATE_SPOT"},{"Id":37,"Code":"CAN_UPDATE_SPOT"},{"Id":38,"Code":"CAN_DELETE_SPOT"},{"Id":39,"Code":"CAN_READ_AVATAR"},{"Id":40,"Code":"CAN_CREATE_AVATAR"},{"Id":41,"Code":"CAN_UPDATE_AVATAR"},{"Id":42,"Code":"CAN_DELETE_AVATAR"},{"Id":43,"Code":"CAN_DOWNLOAD_CONTENT"},{"Id":44,"Code":"CAN_UPLOAD_CONTENT"},{"Id":45,"Code":"CAN_DELETE_CONTENT"},{"Id":46,"Code":"CAN_CREATE_NEW_LANGUAGE"},{"Id":47,"Code":"CAN_GET_LANGUAGE"},{"Id":48,"Code":"CAN_UPDATE_LANGUAGE"},{"Id":49,"Code":"CAN_CREATE_CITY"},{"Id":50,"Code":"CAN_READ_CITY"},{"Id":51,"Code":"CAN_UPDATE_CITY"},{"Id":52,"Code":"CAN_DELETE_CITY"},{"Id":53,"Code":"CAN_CREATE_POI"},{"Id":54,"Code":"CAN_READ_POI"},{"Id":55,"Code":"CAN_UPDATE_POI"},{"Id":56,"Code":"CAN_DELETE_POI"}];

export default {
  jwt: (dto: LoginRequestDto) => {
    const payload: RawJwtPayloadDto = {
      User: JSON.stringify({
        name: 'Khryus (mock)',
        email: dto.email,
        group: {
          id: 1,
          name: "Test"
        }
      }),
      User_Groups: JSON.stringify([
        { GroupId: 1, GroupName: "Test", UserRoleInGroup: [{Id: 1, NameId: 1, Permissions: mock_permissions }] },
        { GroupId: 2, GroupName: "Test 1", UserRoleInGroup: [{Id: 2, NameId: 2, Permissions: mock_permissions }] },
        { GroupId: 3, GroupName: "Test 2", UserRoleInGroup: [{Id: 3, NameId: 3, Permissions: mock_permissions }] },
      ])
    };

    const payloadString = JSON.stringify(payload);
    const base64Payload = btoa(payloadString);
    return `mock.${base64Payload}.signature`;
  }
};
