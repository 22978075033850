<div class="list-wrapper">
  <div class="avatar-item"
       *ngFor="let avatar of avatars"
       [class.disabled]="control.disabled"
       [class.selected]="selectable && valueComparator(avatar, control.value)"
       (click)="select(avatar)"
  >
    <div class="thumbnail">
      <img [src]="avatar.url | mediaUrl" alt="thumbnail" fill priority width="56px" height="56px" draggable="false"/>
    </div>
    <div class="title">
      <p>La tua guida:</p>
      <span class="name">
      {{avatar.name}}
    </span>
    </div>
  </div>
  <button *ngIf="showCreateButton" [disabled]="control.disabled" mat-raised-button class="accent_info createButton" (click)="createButton()">
    <mat-icon>
      add_circle
    </mat-icon>
    {{ 'generic.actions.createNewAvatarGuide' | transloco }}
  </button>
</div>
