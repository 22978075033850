<div class="group-selector" *ngIf="umd.userdata && umd.currentGroup" mat-ripple [matMenuTriggerFor]="menu" (menuOpened)="injectCustomStyle(menu)">
  <div class="group-pic"></div>
  <div class="group-details">
    <div class="name">{{umd.currentGroup.GroupName}}</div>
  </div>
  <mat-icon>expand_more</mat-icon>
  <mat-menu xPosition="before" #menu class="group-selector-menu">
    <button mat-menu-item *ngFor="let group of umd.userdata.User_Groups"
            (click)="handleItemSelection(group)"
            [disabled]="group.GroupId === umd.currentGroup.GroupId"
    >
      {{group.GroupName}}
    </button>
  </mat-menu>
</div>
