import {Component, EventEmitter, Input, OnInit, Output, ViewChildren} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Router} from "@angular/router";
import {systemAvatarDetailRoute} from "../../../config/routes";
import {SystemAvatarDto} from "../../../dtos/local/SystemAvatar.dto";
import {AvatarService} from "../../../services/api/avatar.service";
import {parameterizePathString} from "../../../utility/router.utility";

@Component({
  selector: 'app-system-avatar-selector',
  templateUrl: './system-avatar-selector.component.html',
  styleUrls: ['./system-avatar-selector.component.scss']
})
export class SystemAvatarSelectorComponent implements OnInit {

  @Input({required: true}) control!: FormControl;
  @Input() valueSetter: (dto: SystemAvatarDto) => any = (dto: SystemAvatarDto) => dto.avatarId;
  /**
   * Compares DTO with control value. Expects passed FormControl to be of type Number.
   * Overwrite to customize comparison logic.
   * */
  @Input() valueComparator: (dto: SystemAvatarDto, controlValue: any) => boolean = (dto: SystemAvatarDto, controlValue: any) => dto.avatarId === controlValue;
  @Input() showCreateButton = true;
  @Input() selectable = true;

  avatars: SystemAvatarDto[] = [];

  constructor(private svc: AvatarService, private router: Router) {
    (window as any).avatars = this;
  }

  async ngOnInit() {
    this.avatars = await this.svc.getAvatars();
  }

  select(avatar: SystemAvatarDto) {
    if (!this.selectable) {
      return;
    }

    this.control.setValue(this.valueSetter(avatar));
    this.control.markAsDirty();
    this.control.updateValueAndValidity();
  }

  async createButton() {
    const pathUrl = parameterizePathString(
      systemAvatarDetailRoute.path!,
      { avatarId: 'new' }
    );
    await this.router.navigate([pathUrl]);
  }
}
