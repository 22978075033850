import { Injectable } from '@angular/core';
import {FormGroup} from "@angular/forms";
import {BehaviorSubject, Observable} from "rxjs";

/**
 * This service acts as a bridge for the GroupPathDetailPage component.
 * It holds a reference of the Path Details form when the molecule couldn't otherwise be able to reference it.
 */

@Injectable({
  providedIn: 'root'
})
export class GroupPathDetailPageService {

  detailsForm?: FormGroup;

  constructor() { }

}
