import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {isNumber} from "@ngneat/transloco";
import {importanceDomain, importanceIcons} from "../../../config/domains/importance";
import {languageIcons} from "../../../config/languages";
import {systemPoisRoute} from "../../../config/routes";
import {LabelValueDto} from "../../../dtos/local/LabelValue.dto";
import {SystemLanguageDto} from "../../../dtos/local/SystemLanguage.dto";
import {SystemPoiDto} from "../../../dtos/local/SystemPoi.dto";
import {PoiService} from "src/app/services/api/poi.service";
import {formDataEntries, generateMediaFieldFormDataEntries} from "../../../utility/formdata.utility";
import {ItineroMap} from "../../../utility/maps/ItineroMap";
import {FilePickerComponent} from "../../file-picker/file-picker.component";
import {FileTypes} from "../../file-picker/FileTypes.enum";
import {FormComponentInterface} from "../FormComponent.interface";
import { LanguageService } from 'src/app/services/api/language.service';
import * as turf from '@turf/turf';

@Component({
  selector: 'app-system-poi-form',
  templateUrl: './system-poi-form.component.html',
  styleUrls: ['./system-poi-form.component.scss']
})
export class SystemPoiFormComponent implements OnInit, OnChanges, FormComponentInterface {

  @Input({required: true}) poiId?: number;
  @Input({required: true}) map!: ItineroMap;

  poi?: SystemPoiDto;
  loaded = false;

  form = new FormGroup({
    language: new FormControl<string | null>('', [Validators.required]),
    importance: new FormControl<number | null>(null, [Validators.required]),
    name: new FormControl<string | null>(null, [Validators.required]),
    description: new FormControl<string | null>(null, [Validators.required]),
    coordinates: new FormControl<string | null>({value: null, disabled: true}, [Validators.required]),
    thumbnail: new FormControl<string | File | null>(null, [Validators.required]),
    video: new FormControl<string | File | null>(null),
    audio: new FormControl<string | File | null>(null),
    images: new FormControl<string[] | null>(null),
    isPublished: new FormControl<boolean>(false, [Validators.required]),
  });

  public readonly importances: LabelValueDto<number>[] = importanceDomain;
  public languages: SystemLanguageDto[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public languagesvc: LanguageService,
    public poisvc: PoiService,
  ) {
    (window as any).form = this.form;
    this.form.controls.importance.setValue(this.importances[0].value);
  }

  async ngOnInit() {
    this.languages = await this.languagesvc.getLanguages();
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.loaded = false;
    if (changes.hasOwnProperty('poiId')) {
      await this.initialize();
      this.form.controls.language.valueChanges.subscribe(async (value) => {
        if (this._isCreate) {
          return;
        }

        const lc = value as string;
        await this.loadPoiData(this.poiId!, lc);
      });
    }
  }

  async initialize() {
    const poiId = this.poiId;
    if (!isNumber(poiId)) {
      return;
    }

    if (this.poiId === -1) {
      this._isCreate = true;
      this.form.controls.language.disable();
      this.form.controls.isPublished.disable();
      return;
    } else {
      this._isCreate = false;
    }

    await this.loadPoiData(this.poiId!);
  }

  async loadPoiData(id: number, lang?: string) {
    this.loaded = false;
    const poi = await this.poisvc.getPoi(id, lang);
    this.form.setValue({
      // todo: remove default
      language: poi.langContentCode ?? 'it',
      importance: poi.importance,
      name: poi.name,
      description: poi.description,
      coordinates: poi.coordinates.toString(),
      thumbnail: poi.thumbnail,
      video: poi.video,
      audio: poi.audio,
      images: poi.images,
      isPublished: poi.isPublished,
    }, {emitEvent: false});
    this.poi = poi;
    this.loaded = true;
  }

  reset(): void {
  }

  async delete() {
    try {
      await this.poisvc.delete(this.poiId!);
      await this.router.navigate(['..'], { relativeTo: this.route });
    } catch (err) {
      console.log('error during delete', err);
    }
  }

  async save() {
    const form = this.form.getRawValue();
    const formData = new FormData();
    const coordinates = form.coordinates!
      .split(',')
      .map(p => p.trim())
    ;

    formData.append('name', form.name!);
    formData.append('description', form.description!);
    formData.append('isPublished', JSON.stringify(form.isPublished!));
    formData.append('importance', JSON.stringify(form.importance!));
    formData.append('Coordinates[0]', coordinates[0]);
    formData.append('Coordinates[1]', coordinates[1]);
    formData.append('LangContentCode', form.language!);

    const files: any = {
      thumbnail: this.form.controls.thumbnail.value,
      images: this.form.controls.images.value,
      video: this.form.controls.video.value,
      audio: this.form.controls.audio.value,
    };

    if (files.thumbnail) {
      formData.append('Thumbnail', files.thumbnail);
    }
    if (files.video) {
      formData.append('Video', files.video);
    }
    if (files.audio) {
      formData.append('Audio', files.audio);
    }
    if (Array.isArray(files.images) && files.images.length > 0) {
      generateMediaFieldFormDataEntries(
        formData,
        files.images,
        'Images'
      );
    }

    if (this._isCreate) {
      const newId = await this.poisvc.createPoi(formData);
      await this.router.navigate(['..', newId], { relativeTo: this.route });
      this.poiId = newId;
      await this.initialize();
    } else {
      await this.poisvc.updatePoi(this.poiId!, formData);
    }
  }

  getImportanceLabel(value: number | null) {
    if (value === null) {
      return;
    }

    const lang = this.importances.find(l => l.value === value);
    return lang?.label;
  }

  getImportanceIcon(value: number | null) {
    if (value === null) {
      return;
    }

    return importanceIcons[value];
  }

  async setPoint(feature?: turf.Feature<turf.Point>, cage = true) {
    let point;
    if (!feature) {
      point = await this.map.selectPoint(cage);
    } else {
      point = feature;
    }

    if (!point) {
      return;
    }

    this.map.addSoloMarker(point);
    this.form.controls.coordinates.setValue(point.geometry.coordinates.toString());
  }

  _isCreate = false;
  protected readonly FileTypes = FileTypes;
}
