import * as geojson from "geojson";
import * as maplibre from "maplibre-gl";


export function LngLatLikeToCoordinates(
  lngLatLike: maplibre.LngLatLike
    | geojson.Feature<geojson.Point>
    | geojson.Point
    | number
  , lng?: number
): [number, number] {
  if (Array.isArray(lngLatLike)) {
    return lngLatLike as number[] as [number, number];
  } else {
    if (typeof lngLatLike === 'object') {
      const objLatLike = lngLatLike as any;
      if (objLatLike.hasOwnProperty('lat')) {
        return [
          objLatLike.lng ?? objLatLike.lon,
          objLatLike.lat
        ] as [number, number];
      } else {
        const feature = objLatLike as geojson.Feature<geojson.Point>;
        const point = objLatLike as geojson.Point;
        if (feature.geometry) {
          return NumberArrayAsCoordinates(feature.geometry.coordinates);
        } else {
          return NumberArrayAsCoordinates(point.coordinates);
        }
      }
    } else {
      return [lngLatLike, lng!];
    }
  }
}

export function NumberArrayAsCoordinates(numbers: number[]): [number, number] {
  return numbers as [number, number];
}
