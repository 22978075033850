import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SystemCityDto} from "../../../dtos/local/SystemCity.dto";
import {SystemPathDto} from "../../../dtos/local/SystemPath.dto";
import TileInterface from "../../../dtos/local/Tile.interface";
import {PathsService} from "../../../services/api/paths.service";

@Component({
  selector: 'app-city-group-paths-list',
  templateUrl: './city-group-paths-list.component.html',
  styleUrls: ['./city-group-paths-list.component.scss']
})
export class CityGroupPathsListComponent implements OnInit {

  @Output() tileClicked = new EventEmitter<TileInterface>();
  @Input({required: true}) city!: SystemCityDto;

  paths: SystemPathDto[] = [];
  pathTiles: TileInterface[] = [];

  constructor(
    private pathsvc: PathsService
  ) {
  }

  async ngOnInit() {
    this.paths = await this.pathsvc.getPathsByCity(this.city.cityCode);
    this.pathTiles = this.paths.map(path => {
      return {
        tileId: path.id,
        tileImage: (path.images ?? [])[0] ?? '',
        tileName: path.name
      } as TileInterface;
    });
  }

}
