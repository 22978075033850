import {HttpErrorResponse} from "@angular/common/http";
import { Component } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslocoService} from "@ngneat/transloco";
import {firstValueFrom} from "rxjs";
import {CreateUserPayloadDto} from "../../dtos/remote/service-payloads/CreateUserPayload.dto";
import {UsersService} from "../../services/api/users.service";

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent {

  constructor(
    private ref: MatDialogRef<CreateUserComponent>,
    private svc: UsersService,
    private txsvc: TranslocoService,
    private snack: MatSnackBar
  ) {
  }

  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    surname: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', [Validators.required])
  });

  cancel() {
    this.ref.close();
  }

  async ok() {
    if (this.form.invalid) {
      return;
    }

    const payload: CreateUserPayloadDto = this.form.value as any;
    try {
      await this.svc.createUser(payload);
      this.ref.close();
    } catch (e) {
      console.log("create user error", e);
      if (e instanceof HttpErrorResponse) {
        if (e.status === 409) {
          if (e.error?.Detail?.toLowerCase().includes("with email")) {
            this.snack.open(
              await firstValueFrom(this.txsvc.selectTranslate('errors.createUser.emailAlreadyInUse')),
              'OK',
              { duration: 3500 }
            );
            return;
          }
        }
      }

      this.snack.open(
        await firstValueFrom(this.txsvc.selectTranslate('errors.generic.unexpected')),
        'OK',
        { duration: 3500 }
      );
    }
  }
}
