<div class="gallery-wrapper" *ngIf="imageUrls.length > 0" [style.height]="height" #wrapper>
  <div class="controls">
    <div class="button-left" (click)="previous()" *ngIf="imageUrls.length > 1">
      <mat-icon>
        chevron_left
      </mat-icon>
    </div>
    <div class="expand-wrapper" *ngIf="allowModal">
      <div class="expand" (click)="modal()">
        <mat-icon>
          aspect_ratio
        </mat-icon>
      </div>
    </div>
    <div class="button-right" (click)="next()" *ngIf="imageUrls.length > 1">
      <mat-icon>
        chevron_right
      </mat-icon>
    </div>
  </div>
  <div class="images">
    <div *ngFor="let imageUrl of images; let index=index" class="image-wrapper">
      <img
           [ngSrc]="imageUrl"
           [alt]="imageUrl"
           priority="true"
           fill="true"
           draggable="false"
      >
    </div>
  </div>
</div>
