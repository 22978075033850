import {inject} from "@angular/core";
import {Route} from "@angular/router";
import {environment} from "../../environments/environment";
import {Stage} from "../../environments/stage.enum";
import {UserMetadataService} from "../services/user-metadata.service";
import {
  faqRoute,
  groupPathsRoute,
  groupUsersRoute,
  systemGroupsRoute,
  systemRolesRoute,
  systemPermissionsRoute,
  systemUsersRoute,
  userSettingsRoute, systemPoisRoute, systemPathTypesRoute, systemCitiesRoute, systemAvatarsRoute, testingRoute
} from "./routes";

export interface SectionRouteConfig {

  /**
   * The route that is available in a section.
   */
  route: Route;

  /**
   * The icon that is displayed before the name of the route in the menu.
   */
  icon?: string;

  /**
   * The name of the route in the menu. Defaults to route.data.routeName
   */
  name?: string;

  disabled?: boolean;
}

export interface NavigationSectionConfig {
  /**
   * The title of the menu section.
   */
  title: string;

  /**
   * List of routes available in the section.
   */
  routes: SectionRouteConfig[];

  /**
   * Whether the section consumes all remaining vertical space.
   */
  expandsToBottom?: boolean;

  /**
   * List of routes available at the bottom of the expanded section.
   */
  bottomRoutes?: SectionRouteConfig[];

  /**
   * Whether the title should be hidden.
   * Defaults to false.
   */
  hideTitle?: boolean;

  /**
   * Whether the separator should be hidden.
   * Defaults to false.
   */
  hideSeparator?: boolean;

  /**
   * The guard function that will be used to determine if the section is rendered to the user or not.
   */
  guard?: (() => boolean);
}

export interface NavigationConfig {
  sections: NavigationSectionConfig[];
}

const navigationConfig: NavigationConfig = {
  sections: [
    // {
    //   title: 'navigation.section.groupSettings',
    //   routes: [
    //     {icon: 'route', route: groupPathsRoute},
    //     {icon: 'group_add', route: groupUsersRoute},
    //   ]
    // },
    {
      title: 'navigation.section.systemSettings',
      guard: () => {
        const umd = inject(UserMetadataService);
        // todo: replace with superadmin check
        return umd.authenticated.value;
      },
      hideSeparator: true,
      routes: [
        {icon: 'route', route: groupPathsRoute},
        {icon: 'location_city', route: systemCitiesRoute},
        {icon: 'category', route: systemPathTypesRoute},
        {icon: 'face', route: systemAvatarsRoute},
        {icon: 'push_pin', route: systemPoisRoute},
        {icon: 'groups', route: systemGroupsRoute},
        {icon: 'manage_accounts', route: systemUsersRoute},
        {icon: 'school', route: systemRolesRoute},
        {icon: 'checklist', route: systemPermissionsRoute},
      ],
    },
    {
      title: 'navigation.section.commonRoutes',
      expandsToBottom: true,
      hideTitle: true,
      routes: [],
      bottomRoutes: [
        {icon: 'settings', route: userSettingsRoute, disabled: true},
        {icon: 'support', route: faqRoute, disabled: true},
      ]
    }
  ]
}

if (environment.stage !== Stage.Production) {
  navigationConfig.sections[0].routes.push(
    {icon: 'science', route: testingRoute},
  );
}

export { navigationConfig };
