import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";
import {MediaListItem} from "../media-list/MediaListItem.interface";
import {FileTypes} from "./FileTypes.enum";

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss']
})
export class FilePickerComponent implements OnInit, OnChanges {

  @Input() multi = false;
  @Input() accept: FileTypes = FileTypes.Images;
  @Input() required = false;
  // @Input({ required: true }) dtoContent!: string[] | string | null;
  @Input() dtoContent?: string[] | string | null;

  /**
   * @deprecated
   */
  get selection(): File[] {
    return this.control.value;
  }

  /**
   * Selection on the client.
   */
  control = new FormControl<File[]>([], { nonNullable: true });

  isEmpty = false;

  imageUrls: MediaListItem[] = [];

  currentlyShowing: 'dto' | 'selection' = 'dto';

  constructor(private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const value = this.dtoContent;
    this.isEmpty = !value || (Array.isArray(value) && value.length === 0);
    this.currentlyShowing = this.isEmpty ? 'selection' : 'dto';

    if (!this.isEmpty) {
      this.updateDisplayProperties();
    }

    this.updateRequiredConstraint();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateRequiredConstraint();
  }

  editSelection(input: HTMLInputElement) {
    if (this.multi) {
      this.dialog.open(FilePickerComponent);
    } else {
      input.click();
    }
  }

  // <editor-fold desc="Event handlers">
  handleDrop($event: DragEvent, wrapper: HTMLDivElement) {
    console.log('handleDrop', $event);
    $event.preventDefault();
    this.unsetFileHoverStyle(wrapper);

    const files = ($event.dataTransfer?.items
      ? Array.from($event.dataTransfer.items)
        .filter((item) => item.kind === 'file')
        .map((item) => item.getAsFile())
      : Array.from($event.dataTransfer?.files ?? [])) || [];

    this.selectFilesFromList(
      files.filter(file => file) as File[]
    );
  }

  handleDragover($event: DragEvent, wrapper: HTMLDivElement) {
    $event.preventDefault();
  }

  handleDragEnter($event: DragEvent, wrapper: HTMLDivElement) {
    this.setFileHoverStyle(wrapper);
  }

  handleDragLeave($event: DragEvent, wrapper: HTMLDivElement) {
    this.unsetFileHoverStyle(wrapper);
  }

  handleClick($event: MouseEvent, wrapper: HTMLDivElement, input: HTMLInputElement) {
    input.click();
  }

  handleFileSelected($event: Event, input: HTMLInputElement) {
    this.selectFilesFromList(Array.from(input.files ?? []));
  }

  private setFileHoverStyle(wrapper: HTMLDivElement) {
    wrapper.classList.toggle('dragover', true);
  }

  private unsetFileHoverStyle(wrapper: HTMLDivElement) {
    wrapper.classList.toggle('dragover', false);
  }
  // </editor-fold>

  private selectFilesFromList(files: File[]) {
    if (files.length === 0) {
      return;
    }

    if (this.multi) {
      this.control.setValue(files);
    } else {
      this.control.setValue([files[0]]);
    }

    this.updateDisplayProperties();
  }

  private updateDisplayProperties() {
    switch (this.accept) {
      case FileTypes.Images:
        this.updateImageUrls();
        break;
      case FileTypes.Videos:
        this.updateVideoUrls();
        break;
      case FileTypes.Audio:
        this.updateAudioUrls();
        break;
    }
  }

  private updateImageUrls() {
    if (this.currentlyShowing === 'dto') {
      let urls = [];
      if (this.multi) {
        urls = this.dtoContent as string[];
      } else {
        urls = [this.dtoContent as string];
      }

      this.imageUrls = urls.map(url => ({
        mediaType: 'image',
        thumbnailUrl: url,
        mediaName: url,
        ngSrc: true
      }));
    } else {
      this.imageUrls = this.control.value.map(f => ({
        mediaType: 'image',
        thumbnailUrl: URL.createObjectURL(f),
        mediaName: f.name,
        ngSrc: false
      }));
      console.log(this.imageUrls)
    }
  }

  private updateVideoUrls() {

  }

  private updateAudioUrls() {

  }

  private updateRequiredConstraint() {
    if (this.required) {
      if (this.control.hasValidator(Validators.required)) {
        this.control.removeValidators(Validators.required);
      }
    } else {
      if (!this.control.hasValidator(Validators.required)) {
        this.control.addValidators(Validators.required);
      }
    }
  }

  protected readonly FileTypes = FileTypes;

}
