import { Component } from '@angular/core';
import {UserMetadataService} from "../../services/user-metadata.service";

@Component({
  selector: 'app-profile-tag',
  templateUrl: './profile-tag.component.html',
  styleUrls: ['./profile-tag.component.scss']
})
export class ProfileTagComponent {

  constructor(
    public umd: UserMetadataService
  ) {
  }

  logout() {
    this.umd.logout();
  }

}
