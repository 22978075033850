import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import TileInterface from "../../dtos/local/Tile.interface";

@Component({
  selector: 'app-path-tile',
  templateUrl: './path-tile.component.html',
  styleUrls: ['./path-tile.component.scss']
})
export class PathTileComponent implements OnInit {

  @Input() disabled = false;

  @Input() tile!: TileInterface;

  @Output() clicked = new EventEmitter<TileInterface>();

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.disabled) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
    } else {
      this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
    }
  }

  handleClickEvent($event: MouseEvent) {
    if (!this.disabled) {
      this.clicked.emit(this.tile);
    }
  }
}
