<form [formGroup]="form" (submit)="submit()">
  <mat-label>{{ 'generic.fields.email' | transloco }}</mat-label>
  <mat-form-field appearance="outline">
    <mat-icon matIconPrefix>mail_outlined</mat-icon>
    <input formControlName="email" matInput placeholder="{{'generic.actions.login.emailPlaceholder' | transloco}}">
    <mat-error style="display: block;" *ngIf="form.controls.email.hasError('required')">
      {{ 'errors.validation.requiredField' | transloco }}
    </mat-error>
    <mat-error style="display: block;" *ngIf="form.controls.email.hasError('email')">
      {{ 'errors.validation.validEmailRequired' | transloco }}
    </mat-error>
  </mat-form-field>
  <mat-label>{{ 'generic.fields.password' | transloco }}</mat-label>
  <mat-form-field appearance="outline">
    <mat-icon matIconPrefix>key</mat-icon>
    <input formControlName="password" matInput placeholder="{{'generic.actions.login.passwordPlaceholder' | transloco}}" type="password">
  </mat-form-field>
  <button type="submit" hidden></button>
</form>
<div style="text-align: center; margin: 30px 0;">
  <button class="login-button" mat-fab extended [disabled]="form.invalid || form.disabled" (click)="submit()">
    <mat-icon>login</mat-icon>
    {{ 'generic.actions.login.action' | transloco }}
  </button>
</div>
<div class="error-message" *ngIf="errorMessage" style="text-align: center; color: red;">
  {{errorMessage | transloco}}
</div>
<div style="text-align: center; margin: 30px 0;">
  <app-spinner [class.active]="awaitingResponse"></app-spinner>
</div>
