import {HttpErrorResponse} from "@angular/common/http";
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {firstValueFrom} from "rxjs";
import {GroupRelationDto} from "../../dtos/local/GroupRelationDto";
import SystemUserDto from "../../dtos/local/SystemUser.dto";
import {CreateUserPayloadDto} from "../../dtos/remote/service-payloads/CreateUserPayload.dto";
import {UserMetadataService} from "../../services/user-metadata.service";
import {UsersService} from "../../services/api/users.service";
import {UserPickerComponent} from "../../user-picker/user-picker.component";
import {CreateUserComponent} from "../create-user/create-user.component";
import {InputDialogComponent, InputDialogData} from "../dialogs/input-dialog/input-dialog.component";
import {LIGHT_BLUE_SHADING, LIGHT_RED_SHADING} from '../shaded-placeholder-icon/shaded-placeholder-icon.component';

@Component({
  selector: 'app-system-user-list',
  templateUrl: './system-user-list.component.html',
  styleUrls: ['./system-user-list.component.scss']
})
export class SystemUserListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<SystemUserDto>([]);

  displayedColumns = [
    'id',
    'name',
    'surname',
    'email',
    'status'
  ];

  canEditUsers = false;

  constructor(
    private umd: UserMetadataService,
    private svc: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private txsvc: TranslocoService
  ) {
  }

  async ngOnInit() {
    this.dataSource.data = await this.svc.getUsers();

    if (this.umd.hasPermission('CAN_UPDATE_GROUP')) {
      this.canEditUsers = true;
      this.displayedColumns.push('actions');
    }
  }

  ngAfterViewInit() {
    this.dataSource.filterPredicate = (data, filter) => {
      const fieldsToCheck = [data.name, data.surname, data.email, data.id];
      return fieldsToCheck.some(field => field.toString().toLowerCase().trim().includes(filter));
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async deleteUser(user: SystemUserDto) {
    await this.svc.deleteUser(user.id);
    this.dataSource.data = await this.svc.getUsers();
  }

  async editUserPassword(user: SystemUserDto) {
    const data: InputDialogData = {
      message: "generic.actions.editUserPassword.message",
    };

    const ref = this.dialog.open(InputDialogComponent, {
      data: data
    });

    return new Promise((res, rej) => {
      ref.afterClosed().subscribe(async (data) => {
        if (!data) {
          return;
        }

        const payload: CreateUserPayloadDto = {
          email: user.email,
          name: user.name,
          surname: user.surname,
          password: data,
        };

        try {
          await this.svc.updateUser(user.id, payload);
          res(null);
          this.snack.open(
            await firstValueFrom(this.txsvc.selectTranslate('messages.genericActionOk')),
            "OK",
            { duration: 1000 }
          )
        } catch (e) {
          rej(e);
          this.snack.open(
            await firstValueFrom(this.txsvc.selectTranslate('errors.generic.unexpected')),
            "OK",
            { duration: 1000 }
          )
        }
      });
    });
  }

  async createUser() {
    const ref = this.dialog.open(CreateUserComponent, {
      width: `30%`
    });

    ref.afterClosed().subscribe(async (success: boolean) => {
      if (!success) {
        return;
      }

      this.dataSource.data = await this.svc.getUsers();
    });
  }

  // protected readonly LIGHT_BLUE_SHADING = LIGHT_BLUE_SHADING;
  // protected readonly LIGHT_RED_SHADING = LIGHT_RED_SHADING;
}
