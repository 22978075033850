import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {ProgressBarMode} from "@angular/material/progress-bar";
import {LngLat} from "maplibre-gl";
import {MapboxGeocodeFeature} from "../../dtos/remote/mapbox/MapboxGeocodeFeature.interface";

@Component({
  selector: 'app-wide-forward-geocode-button',
  templateUrl: './wide-forward-geocode-button.component.html',
  styleUrls: ['./wide-forward-geocode-button.component.scss']
})
export class WideForwardGeocodeButtonComponent {

  @Output() geocodingOptionSelected = new EventEmitter<MapboxGeocodeFeature>();
  @Input() coordinateBias?: LngLat;

  @Input() searchDebounceMs = 1000;
  @Input() progressUpdateFrequency = 100;

  @Input() useProgressBar = true;

  query = new FormControl();

  progressBar: { mode: ProgressBarMode, value: number } = {
    mode: 'determinate',
    value: 0
  };

  constructor() {
    if (this.useProgressBar) {
      setInterval(() => {
        const value = this.progressBar.value;
        const step = this.searchDebounceMs / this.progressUpdateFrequency;
        this.progressBar.value = Math.max(0, value - step);
      }, this.progressUpdateFrequency);
    }
  }

  handleTimerStarted() {
    if (this.useProgressBar) {
      this.progressBar.value = 100;
    }
  }
}
