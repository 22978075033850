<!-- TODO: i18n -->
<app-system-page-layout>
  <ng-container header-subtitle>
    {{'pages.systemGroups.subtitle' | transloco}}
  </ng-container>
  <ng-container header-controls>
<!--    <button class="tab-button" [class.selected]="selectedIndex === 0" (click)="changeFilter(0)" mat-button>-->
<!--      {{'generic.entity.group.allGroups' | transloco}}-->
<!--    </button>-->
<!--    <button class="tab-button" [class.selected]="selectedIndex === 1" (click)="changeFilter(1)" mat-button>-->
<!--      {{'generic.entity.group.myGroups' | transloco}}-->
<!--    </button>-->
<!--    <button class="tab-button" [class.selected]="selectedIndex === 2" (click)="changeFilter(2)" mat-button>-->
<!--      {{'generic.entity.group.hostGroups' | transloco}}-->
<!--    </button>-->
    <button id="createGroup" (click)="createGroup()" mat-raised-button color="primary">
      <mat-icon>add</mat-icon>
      {{'generic.entity.group.newGroup' | transloco}}
    </button>
  </ng-container>
  <ng-container content>
    <div class="list-wrapper">
      <app-wide-icon-button *ngFor="let group of systemGroups" (click)="viewGroupDetail(group)">
        <ng-container ngProjectAs="icon">
          <app-shaded-placeholder-icon class="title-icon" [shadeSteps]="LIGHT_BLUE_SHADING"></app-shaded-placeholder-icon>
        </ng-container>
        <ng-container ngProjectAs="content">
          <h2>{{group.name}}</h2>
        </ng-container>
      </app-wide-icon-button>
    </div>
    <router-outlet></router-outlet>
  </ng-container>
</app-system-page-layout>
