import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import RoleDto from "../../dtos/local/Role.dto";
import {SystemRoleDto} from "../../dtos/local/SystemRole.dto";

@Injectable({
  providedIn: 'root'
})
export class RolesService {


  private readonly baseUrl = `${environment.api.host}/role`;

  constructor(
    private http: HttpClient
  ) { }

  public async getRoles(): Promise<SystemRoleDto[]> {
    return firstValueFrom(
      this.http.get<SystemRoleDto[]>(
        `${this.baseUrl}`
      )
    );
  }

  public async getRole(id: number): Promise<SystemRoleDto> {
    return firstValueFrom(
      this.http.get<SystemRoleDto>(
        `${this.baseUrl}/${id}`
      )
    );
  }

  public saveRole(id: number, payload: any) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/update/${id}`,
        payload
      )
    );
  }

  async deleteRole(id: number) {
    return firstValueFrom(
      this.http.delete(
        `${this.baseUrl}/${id}`
      )
    );
  }

}
