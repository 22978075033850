


<h1 mat-dialog-title>{{ 'generic.actions.selectUser' | transloco }}</h1>
<div mat-dialog-content>
  <mat-selection-list #users>
    <mat-list-option *ngFor="let user of availableUsers" [value]="user.id">
      {{user.name}} {{user.surname}} ({{user.email}})
    </mat-list-option>
  </mat-selection-list>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()">
    {{ 'generic.actions.cancel' | transloco }}
  </button>
  <button mat-raised-button (click)="ok()" [disabled]="users.selectedOptions.selected.length === 0">
    {{ 'messages.ok' | transloco }}
  </button>
</div>
