import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {take} from "rxjs";
import {SystemCitiesListComponent} from "../../components/entity-lists/system-cities-list/system-cities-list.component";
import {
  SystemPathTypesListComponent
} from "../../components/entity-lists/system-path-types-list/system-path-types-list.component";
import {PathMetadataFormComponent} from "../../components/path-metadata-form/path-metadata-form.component";
import {StepperComponent} from "../../components/stepper/stepper.component";
import {groupPathDetailRoute} from "../../config/routes";
import {IntensityTypeEnum} from "../../dtos/local/IntensityType.enum";
import TileInterface from "../../dtos/local/Tile.interface";
import {CitiesService} from "../../services/api/cities.service";
import {parameterizePathString} from "../../utility/router.utility";

@Component({
  selector: 'app-create-group-path-page',
  templateUrl: './create-group-path-page.component.html',
  styleUrls: ['./create-group-path-page.component.scss']
})
export class CreateGroupPathPageComponent implements OnInit, OnDestroy {

  @ViewChild('stepper') stepper!: StepperComponent;
  @ViewChild('cities') cities!: SystemCitiesListComponent;
  @ViewChild('pathTypes') pathTypes!: SystemPathTypesListComponent;
  @ViewChild('metadata') pathMetadata!: PathMetadataFormComponent;

  steps = [
    // TODO: i18n
    {label: 'Città', disabled: false, editable: true, completed: false, invalid: false, returnable: true},
    {label: 'Tipo', disabled: true, editable: false, completed: false, invalid: false, returnable: true},
    {label: 'Intensità', disabled: true, editable: false, completed: false, invalid: false, returnable: true},
    {label: 'Metadati', disabled: true, editable: false, completed: false, invalid: false, returnable: true},
  ];

  intensities: TileInterface[] = [
    { tileId: IntensityTypeEnum.Easy, tileImage: '', tileImageWrapperClass: 'solidGray', tileName: 'domains.intensity.easy' },
    { tileId: IntensityTypeEnum.Medium, tileImage: '', tileImageWrapperClass: 'solidGray', tileName: 'domains.intensity.medium' },
    { tileId: IntensityTypeEnum.Hard, tileImage: '', tileImageWrapperClass: 'solidGray', tileName: 'domains.intensity.hard' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private citysvc: CitiesService,
    private transloco: TranslocoService
  ) {
  }

  ngOnInit(): void {
  }

  async ngOnDestroy() {
    await this.citysvc.setWorkingCity(-1);
  }

  async selectCity($event: TileInterface) {
    await this.citysvc.setWorkingCity($event.tileId);
    this._cityId = $event.tileId;
    this.stepper.completeStep(0);
  }

  selectType($event: TileInterface) {
    this.pathMetadata.form.controls.pathType.setValue($event.tileId);
    this.stepper.completeStep(1);
  }

  selectIntensity($event: TileInterface) {
    this.pathMetadata.form.controls.intensity.setValue($event.tileId);
    this.stepper.completeStep(2);
  }

  parseCityParam($event: TileInterface[]) {
    if (this._loaded) {
      return;
    }
    this._loaded = true;

    this.route.queryParamMap.pipe(take(1)).subscribe(async params => {
      await this.setCity(params);
      await this.setPathType(params);
    });
  }

  async create() {
    const newPathId = await this.pathMetadata.create();
    const pathUrl = parameterizePathString(
      groupPathDetailRoute.path!,
      { pathId: newPathId, cityId: this._cityId! }
    );
    await this.router.navigate([pathUrl]);
  }

  private async setCity(params: ParamMap) {
    const param = params.get('cityId');
    if (!param) {
      return;
    }

    const id = Number(param);
    if (isNaN(id)) {
      return;
    }

    const cityTile = this.cities.tiles.find(t => t.tileId === id);
    if (!cityTile) {
      return;
    }

    await this.selectCity(cityTile);
  }

  private async setPathType(params: ParamMap) {
    const param = params.get('pathTypeId');
    if (!param) {
      return;
    }

    const id = Number(param);
    if (isNaN(id)) {
      return;
    }

    const typeTile = this.pathTypes.pathTypeTiles.find(t => t.tileId === id);
    if (!typeTile) {
      return;
    }

    await this.selectType(typeTile);
  }

  private _cityId?: number;
  private _loaded = false;

}
