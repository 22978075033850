<app-system-page-layout [backButton]="true" [titleParams]="titleParams">
  <ng-container header-title-controls>
    <button mat-raised-button class="accent_success" (click)="create()">
      <mat-icon>
        add
      </mat-icon>
      {{ 'generic.actions.createPathHere' | transloco }}
    </button>
  </ng-container>
  <ng-container content *ngIf="cityDto">
    <!--    <app-city-group-paths-list [city]="cityDto" (tileClicked)="selectPath($event)"></app-city-group-paths-list>-->

    <!-- path types -->
<!--    <p *ngIf="!selectedPathType">path types</p>-->
    <app-tile-list [tiles]="pathTypes"
                   (tileClicked)="pathTypeSelected($event)"
                   *ngIf="!selectedPathType"
    ></app-tile-list>

    <!-- path groups (all intensities) -->
<!--    <p *ngIf="selectedPathType && pathGroups">path groups (all intensities)</p>-->
    <app-tile-list [tiles]="pathGroups"
                   (tileClicked)="selectPathGroup($event)"
                   *ngIf="(selectedPathType && pathGroups) && !selectedPathGroup"
    ></app-tile-list>

    <!-- paths (specific intensity) -->
<!--    <p *ngIf="selectedPathType && pathIntensities">paths (specific intensity)</p>-->
    <app-tile-list [tiles]="pathIntensities"
                   (tileClicked)="selectPath($event)"
                   *ngIf="selectedPathType && selectedPathGroup && pathIntensities"
    ></app-tile-list>

  </ng-container>
</app-system-page-layout>
