import {EventEmitter} from "@angular/core";
import * as turf from '@turf/turf';
import * as geojson from "geojson";
import * as maplibre from "maplibre-gl";
import {filter, take} from "rxjs";
import {ItineroMap} from "../ItineroMap";
import * as geoutils from '../lib/GeoUtils';
import {MapModeInterface} from "../lib/MapMode.interface";
import {MapModeEventTypeEnum} from "../lib/MapModeEventType.enum";

export interface PointSelectEvent {
  type: MapModeEventTypeEnum;
  data?: geojson.Feature<geojson.Point> | null;
}

export class PointSelectMode implements MapModeInterface {

  bus = new EventEmitter<PointSelectEvent>();

  constructor(
    private map: ItineroMap
  ) {
  }

  activate(params?: any) {
    this.map.api.getCanvas().style.cursor = 'crosshair';

    if (params?.caged === true) {
      this._caged = true;
    }

    // bind to map events
    this.installEvents();

    // at click, emit value
    this.bus.emit({ type: MapModeEventTypeEnum.Activated });
  }

  deactivate(params?: any) {
    this.uninstallEvents();
    this.map.api.getCanvas().style.cursor = '';
    this.bus.emit({ type: MapModeEventTypeEnum.Deactivated });
  }

  private installEvents() {
    this.map.api.once('click', this._clickListener);

    if (!this._caged) {
      window.addEventListener('keydown', this._keyListener);
    }
  }

  private uninstallEvents() {
    this.map.api.off('click', this._clickListener);

    if (!this._caged) {
      window.removeEventListener('keydown', this._keyListener);
    }

    this._caged = false;
  }

  private click(e: maplibre.MapMouseEvent & Object) {
    this.bus.emit({
      type: MapModeEventTypeEnum.Data,
      data: turf.point(geoutils.LngLatLikeToCoordinates(e.lngLat)) as geojson.Feature<geojson.Point>
    });
  }

  private keyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.deactivate();
    }
  }

  private _clickListener = this.click.bind(this);
  private _keyListener = this.keyDown.bind(this);

  private _caged = false;

}
