import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {take} from "rxjs";
import {environment} from "../../../environments/environment";
import {ChoiceDialogComponent} from "../../components/dialogs/choice-dialog/choice-dialog.component";
import {ConfirmDialogComponent} from "../../components/dialogs/confirm-dialog/confirm-dialog.component";
import {createGroupPathRoute, groupPathDetailRoute} from "../../config/routes";
import {
  IntensityTypeColorMap,
  IntensityTypeEnum,
  IntensityTypeKeyArray,
  IntensityTypeMap, IntensityTypeStandardColorMap, IntensityTypeValueArray
} from "../../dtos/local/IntensityType.enum";
import {SystemCityDto} from "../../dtos/local/SystemCity.dto";
import {SystemPathDto} from "../../dtos/local/SystemPath.dto";
import TileInterface from "../../dtos/local/Tile.interface";
import {AvatarService} from "../../services/api/avatar.service";
import {CitiesService} from "../../services/api/cities.service";
import {PathsService} from "../../services/api/paths.service";
import {groupBy} from "../../utility/object.utility";
import {parameterizePath} from "../../utility/router.utility";

@Component({
  selector: 'app-path-types-in-city-page',
  templateUrl: './path-types-in-city-page.component.html',
  styleUrls: ['./path-types-in-city-page.component.scss']
})
export class PathTypesInCityPageComponent implements OnInit, OnDestroy {

  cityDto!: SystemCityDto;
  titleParams = {
    cityName: '',
  };

  pathTypes: TileInterface[] = [];
  pathGroups?: TileInterface[];
  pathIntensities?: TileInterface[];

  pathTypeMap?: { [pathTypeId: number]: SystemPathDto[] };
  selectedPathType?: TileInterface;
  selectedPathGroup?: TileInterface;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private citysvc: CitiesService,
    private pathsvc: PathsService,
    private avatarsvc: AvatarService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      const cityId = Number(params.get('cityId'));
      if (isNaN(cityId) || cityId === undefined || cityId === null) {
        console.warn("No cityId param was specified.");
        return;
      }

      await this.citysvc.setWorkingCity(cityId);
      const cityDto = await this.citysvc.getCity(cityId);
      this.cityDto = cityDto;
      this.titleParams.cityName = cityDto.name;

      const pathMap = await this.pathsvc.getPathTypesByCity(cityDto.cityCode);
      this.pathTypeMap = pathMap;

      const avatars = await this.avatarsvc.getAvatars();
      this.pathTypes = await Promise.all(Object.keys(pathMap).map(async pathTypeId => {
        const dto = await this.pathsvc.getPathTypeById(Number(pathTypeId));
        const avatar = avatars.find(a => a.avatarId === dto.avatarId);
        return {
          tileId: dto.id,
          tileName: dto.name,
          tileImage: avatar ? environment.resources.imagePath(avatar.url) : '',
        };
      }));

      console.log(pathMap);
    });
  }

  async ngOnDestroy() {
    await this.citysvc.setWorkingCity(-1);
  }

  pathTypeSelected($event: TileInterface) {
    if (!this.pathTypeMap) {
      return;
    }

    this.selectedPathType = $event;

    const pathsOfType = this.pathTypeMap[$event.tileId];
    const pathsByGroup = groupBy(pathsOfType, p => p.pathGroupId);
    this.pathGroups = Object.values(pathsByGroup).map(paths => {
      const path = paths[0];
      const intensities = paths.map(p => p.intensity);
      return {
        tileId: path.pathGroupId,
        tileName: path.name,
        tileImage: environment.resources.imagePath(path.images?.[0] ?? ''),
        innerTags: intensities.map(ik => ({
          text: IntensityTypeMap[ik],
          class: IntensityTypeColorMap[ik]
        }))
      };
    });
  }

  selectPathGroup($event: TileInterface) {
    if (!this.pathTypeMap) {
      return;
    }

    if (!this.selectedPathType) {
      return;
    }

    this.selectedPathGroup = $event;

    const pathsOfType = this.pathTypeMap[this.selectedPathType.tileId];
    const groups = groupBy(pathsOfType, p => p.pathGroupId);
    const selectedGroup = groups[$event.tileId];

    const existingPath = selectedGroup[0];

    this.pathIntensities = IntensityTypeValueArray.map(ik => {
      const intensity = ik as IntensityTypeEnum;
      const pathGroupHasCurrentIntensity = selectedGroup.some(p => p.intensity === ik);
      const path = selectedGroup.find(p => p.intensity === ik);
      return {
        tileId: path?.id ?? -1,
        tileData: {intensity: intensity},
        tileName: IntensityTypeMap[intensity],
        tileImage: environment.resources.imagePath(existingPath.images?.[0] ?? ''),
        tileImageClass: pathGroupHasCurrentIntensity ? '' : 'grayscale',
        labelTags: [
          {
            class: IntensityTypeColorMap[intensity]
          }
        ]
      };
    });
  }

  async selectPath($event: TileInterface) {
    if (!this.pathTypeMap) {
      return;
    }

    if (!this.selectedPathType) {
      return;
    }

    if (!this.selectedPathGroup) {
      return;
    }

    if ($event.tileId === -1) {
      console.warn("Selected missing path.");
      this.dialog.open(ConfirmDialogComponent, {data: {message: 'messages.paths.createDifficulty.confirm'}}).afterClosed().subscribe(async (confirm) => {
        if (!confirm) {
          console.log("User aborted operation.");
          return;
        }

        const pathsOfType = this.pathTypeMap![this.selectedPathType!.tileId];
        const groupPaths = pathsOfType.filter(p => p.pathGroupId === this.selectedPathGroup!.tileId);

        const targetIntensity = $event.tileData.intensity;

        if (groupPaths.length > 1) {
          // let user pick which path to copy from
          const options: TileInterface[] = groupPaths.map(p => ({
            tileId: p.intensity,
            tileName: IntensityTypeMap[p.intensity],
            tileImageClass: IntensityTypeStandardColorMap[p.intensity],
            tileImage: ''
          }));

          this.dialog.open(ChoiceDialogComponent, {
            data: {
              message: 'messages.paths.createDifficulty.pickOrigin',
              options: options,
              multi: false
            }
          }).afterClosed().subscribe(async (choice) => {
            if (choice === undefined || choice === null) {
              console.log("User did not make a choice.");
              return;
            }
            const sourcePath = groupPaths.find(p => p.intensity === choice)!;
            await this.createNewIntensity(sourcePath.id, targetIntensity);
          });
        } else {
          // only one path to copy from
          const sourcePath = groupPaths[0]!;
          await this.createNewIntensity(sourcePath.id, targetIntensity);
        }
      });
      return;
    }

    await this.router.navigate([$event.tileId], {relativeTo: this.route});
  }

  private async createNewIntensity(sourcePathId: number, targetIntensity: number) {
    // console.log("create new intensity", sourcePathId, targetIntensity);
    const newPathId = await this.pathsvc.duplicatePath(sourcePathId, targetIntensity);
    await this.router.navigate(
      parameterizePath(
        groupPathDetailRoute.path!,
        {
          cityId: this.cityDto.id,
          pathId: newPathId,
        }
      )
    );
  }

  async create() {
    const queryParams: any = {
      cityId: this.cityDto.id
    };

    if (this.selectedPathType) {
      queryParams.pathTypeId = this.selectedPathType.tileId;
    }

    await this.router.navigate(
      [createGroupPathRoute.path],
      {
        queryParams: queryParams
      }
    );
  }
}
