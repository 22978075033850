<section class="stepper-wrapper">
  <section class="stepper">
    <ng-container *ngFor="let step of steps; index as index; last as last">
      <div class="step"
           mat-ripple
           (click)="selectStep(index)"
           [matRippleDisabled]="stepRippleIsDisabled(index)"
           [class.disabled]="step.disabled"
           [class.completed]="step.editable && step.completed"
           [class.invalid]="step.editable && step.invalid"
           [class.selected]="stepIndex === index"
      >
        {{step.label | transloco}}
        <mat-icon>check_circle</mat-icon>
      </div>
      <div class="step-connection" *ngIf="!last && !(steps[index + 1] ?? {}).disabled"></div>
    </ng-container>
  </section>
</section>
