import {inject} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {tap} from "rxjs";
import {UserMetadataService} from "../services/user-metadata.service";

export const IsAuthenticatedGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const umd = inject(UserMetadataService);
  return umd.authenticated.pipe(
    tap((isAuthed) => {
      return !isAuthed ? router.navigate(
        ['/login'],
          {
            state: {
              reason: 'umd.notAuthenticated'
            }
          }
      ) : true;
    })
  );
};
