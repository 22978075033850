<div class="profile-tag">
<!--  <div class="profile-pic"></div>-->
  <div class="user-details" *ngIf="umd.userdata && umd.userdata.User">
    <div class="name">{{umd.userdata.User.Name}} {{umd.userdata.User.Surname}}</div>
    <div class="email">{{umd.userdata.User.Email}}</div>
  </div>
  <button mat-icon-button class="logout-button" (click)="logout()">
    <mat-icon>login</mat-icon>
  </button>
</div>
