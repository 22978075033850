<!--TODO: i18n-->
<mat-card style="padding: 30px;">
  <ng-container>
    <mat-form-field>
      <mat-label>{{ 'generic.actions.filter' | transloco }}</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.fields.name' | transloco }} </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.fields.surname' | transloco }} </th>
        <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.fields.email' | transloco }} </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.fields.status' | transloco }} </th>
        <td mat-cell *matCellDef="let element">
          {{ !element.isDisabled ? ('generic.enabled' | transloco) : ('generic.disabled' | transloco) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions" *ngIf="canEditUsers">
        <th mat-header-cell *matHeaderCellDef> {{ 'generic.fields.actions' | transloco }} </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="deleteUser(element)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button (click)="editUserPassword(element)">
            <mat-icon>lock_reset</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- <editor-fold desc="old columns"> -->
      <!--  &lt;!&ndash; groupSymbol Column &ndash;&gt;-->
      <!--  <ng-container matColumnDef="groupSymbol">-->
      <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Icon </th>-->
      <!--    <td mat-cell *matCellDef="let element">-->
      <!--      <app-shaded-placeholder-icon [shadeSteps]="LIGHT_RED_SHADING"></app-shaded-placeholder-icon>-->
      <!--    </td>-->
      <!--  </ng-container>-->

      <!--  &lt;!&ndash; userSymbol Column &ndash;&gt;-->
      <!--  <ng-container matColumnDef="userSymbol">-->
      <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header> User Icon </th>-->
      <!--    <td mat-cell *matCellDef="let element">-->
      <!--      <app-shaded-placeholder-icon [shadeSteps]="LIGHT_BLUE_SHADING"></app-shaded-placeholder-icon>-->
      <!--    </td>-->
      <!--  </ng-container>-->

      <!--  &lt;!&ndash; nickName Column &ndash;&gt;-->
      <!--  <ng-container matColumnDef="nickName">-->
      <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nickname </th>-->
      <!--    <td mat-cell *matCellDef="let element"> {{element.user.nickname}} </td>-->
      <!--  </ng-container>-->

      <!--  &lt;!&ndash; group Column &ndash;&gt;-->
      <!--  <ng-container matColumnDef="group">-->
      <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header> Group </th>-->
      <!--    <td mat-cell *matCellDef="let element"> {{element.group.name}} </td>-->
      <!--  </ng-container>-->

      <!--  &lt;!&ndash; role Column &ndash;&gt;-->
      <!--  <ng-container matColumnDef="role">-->
      <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>-->
      <!--    <td mat-cell *matCellDef="let element"> {{element.user.role.name}} </td>-->
      <!--  </ng-container>-->
      <!-- </editor-fold> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"></mat-paginator>
  </ng-container>
  <div style="width: 100%; text-align: right; margin-top: 10px;">
    <button mat-raised-button class="accent_success" (click)="createUser()">
      <mat-icon>add</mat-icon>
      {{ 'generic.actions.createNewUser' | transloco }}
    </button>
  </div>
</mat-card>
