<div class="form-wrapper">
  <h1> {{ 'generic.actions.addUser' | transloco }}</h1>
  <form class="form" [formGroup]="form">
    <ng-container>
      <h3><h1> {{ 'generic.fields.name' | transloco }}</h1></h3>
      <ng-container *ngIf="form.controls.name.touched">
        <mat-error *ngIf="form.controls.name.errors?.['required']"> {{ 'errors.validation.requiredField' | transloco }}</mat-error>
      </ng-container>
      <mat-form-field appearance="outline" floatLabel="always">
        <input type="text" matInput [formControl]="form.controls.name" placeholder="{{ 'generic.fields.name' | transloco }}">
      </mat-form-field>
      <br>
    </ng-container>
    <ng-container>
      <h3>{{ 'generic.fields.surname' | transloco }}</h3>
      <ng-container *ngIf="form.controls.surname.touched">
        <mat-error *ngIf="form.controls.surname.errors?.['required']">{{ 'errors.validation.requiredField' | transloco }}</mat-error>
      </ng-container>
      <mat-form-field appearance="outline" floatLabel="always">
        <input type="text" matInput [formControl]="form.controls.surname" placeholder="{{ 'generic.fields.surname' | transloco }}">
      </mat-form-field>
      <br>
    </ng-container>
    <ng-container>
      <h3>{{ 'generic.fields.email' | transloco }}</h3>
      <ng-container *ngIf="form.controls.email.touched">
        <mat-error *ngIf="form.controls.email.errors?.['required']">{{ 'errors.validation.requiredField' | transloco }}</mat-error>
      </ng-container>
      <mat-form-field appearance="outline" floatLabel="always">
        <input type="text" matInput [formControl]="form.controls.email" placeholder="{{ 'generic.fields.email' | transloco }}">
      </mat-form-field>
      <br>
    </ng-container>
    <ng-container>
      <h3>{{ 'generic.fields.password' | transloco }}</h3>
      <ng-container *ngIf="form.controls.password.touched">
        <mat-error *ngIf="form.controls.password.errors?.['required']">{{ 'errors.validation.requiredField' | transloco }}</mat-error>
      </ng-container>
      <mat-form-field class="name" appearance="outline" floatLabel="always">
        <input type="text" matInput [formControl]="form.controls.password" placeholder="{{ 'generic.fields.insertPassword' | transloco }}">
      </mat-form-field>
      <br>
    </ng-container>
  </form>
  <div style="justify-content: end;" mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">
      {{ 'navigation.back' | transloco }}
    </button>
    <button mat-raised-button class="accent_success" (click)="ok()" [disabled]="form.invalid">
      <mat-icon>{{ 'actions.saveModifications' | transloco }}</mat-icon>
      {{ 'generic.actions.createNewUser' | transloco }}
    </button>
  </div>
</div>
