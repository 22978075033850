<div class="dialog-wrapper">
  <div class="header">
    <div matRipple mat-dialog-close class="close-icon">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="title">
    <h1>{{this.data.message | transloco : this.data.messageParameters ?? {} }}</h1>
  </div>
  <div class="input" style="width: 70%">
    <mat-form-field appearance="outline" floatLabel="always" style="width: 100%;">
      <input matInput [formControl]="input" [type]="this.data.inputType ?? 'text'" placeholder="{{'generic.actions.editUserPassword.newPasswordPlaceholder' | transloco}}">
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-raised-button class="accent_error" (click)="deny()">
      {{(this.data.denyText || 'generic.actions.cancel') | transloco}}
    </button>
    <button mat-raised-button class="accent_success" (click)="confirm()">
      {{(this.data.confirmText || 'generic.actions.confirm') | transloco}}
    </button>
  </div>
</div>


