<div class="form-wrapper">
  <form class="form" [formGroup]="form">
    <h3>{{'generic.fields.name' | transloco}}</h3>
    <ng-container *ngIf="form.controls.name.touched">
      <mat-error *ngIf="form.controls.name.errors?.['required']">{{ 'errors.validation.requiredField' | transloco }}</mat-error>
      <mat-error *ngIf="form.controls.name.errors?.['maxlength']">{{ 'errors.validation.maxLength' | transloco : { maxLength: MAX_NAME_LENGTH } }}</mat-error>
<!--      <mat-error *ngIf="form.controls.name.errors?.['minlength']">Devi inserire almeno X caratteri</mat-error>-->
    </ng-container>
    <mat-form-field class="name" appearance="outline" floatLabel="always">
      <input type="text" matInput [formControl]="form.controls.name" placeholder="{{'generic.fields.name' | transloco}}">
    </mat-form-field>
    <br>
    <h3>{{'generic.fields.description' | transloco}}</h3>
    <ng-container *ngIf="form.controls.description.touched">
      <mat-error *ngIf="form.controls.description.errors?.['required']">{{ 'errors.validation.requiredField' | transloco }}</mat-error>
      <mat-error *ngIf="form.controls.description.errors?.['maxlength']">{{ 'errors.validation.maxLength' | transloco : { maxLength: MAX_DESCRIPTION_LENGTH } }}</mat-error>
    </ng-container>
    <mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
      <textarea class="description" matInput [formControl]="form.controls.description" placeholder="{{'generic.fields.description' | transloco}}"></textarea>
      <mat-hint>Max. {{MAX_DESCRIPTION_LENGTH}} caratteri</mat-hint>
    </mat-form-field>
  </form>
</div>
