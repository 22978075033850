
export const IntensityTypeKeyArray = [
  'Easy',
  'Medium',
  'Hard'
];

export const IntensityTypeValueArray = [
  0,
  1,
  2
];

export enum IntensityTypeEnum {
  Easy,
  Medium,
  Hard,
}

export const IntensityTypeMap = {
  [IntensityTypeEnum.Easy]: 'domains.intensity.easy',
  [IntensityTypeEnum.Medium]: 'domains.intensity.medium',
  [IntensityTypeEnum.Hard]: 'domains.intensity.hard',
}

export const IntensityTypeColorMap = {
  [IntensityTypeEnum.Easy]: 'intensity-easy',
  [IntensityTypeEnum.Medium]: 'intensity-medium',
  [IntensityTypeEnum.Hard]: 'intensity-hard',
}

export const IntensityTypeStandardColorMap = {
  [IntensityTypeEnum.Easy]: 'accent_success',
  [IntensityTypeEnum.Medium]: 'accent_warning',
  [IntensityTypeEnum.Hard]: 'accent_error',
}
