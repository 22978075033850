<div class="gallery-wrapper" *ngIf="medias.length > 0" #wrapper
     [style.height]="height"
     [class.modal]="isModal"
     [class.mediaTypeVideo]="currentMediaIsVideo"
     [class.mediaTypeImage]="currentMediaIsImage"
     [class.mediaTypeAudio]="currentMediaIsAudio"
>
  <div class="controls">
    <div class="expand-wrapper" *ngIf="allowModal && currentMediaIsImage">
      <div class="expand" (click)="modal()">
        <mat-icon>
          aspect_ratio
        </mat-icon>
      </div>
    </div>
    <div class="paginator-wrapper" *ngIf="medias.length > 1">
      <div class="pill">
        <div class="button-left" (click)="previous()" *ngIf="medias.length > 1">
          <mat-icon matRipple>
            chevron_left
          </mat-icon>
        </div>
        <div class="counter">
          <p>{{currentIndex + 1}}/{{medias.length}}</p>
        </div>
        <div class="button-right" (click)="next()" *ngIf="medias.length > 1">
          <mat-icon matRipple>
            chevron_right
          </mat-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="carousel">
    <div *ngFor="let media of medias; let index=index" class="item-wrapper">
      <ng-container *ngIf="media.mediaType === 'image'">
        <img
          [src]="media.thumbnailUrl! | mediaUrl : media.resolveThumbnailUrl"
          [alt]="media.mediaName"
          draggable="false"
        >
      </ng-container>
      <ng-container *ngIf="media.mediaType === 'video'">
        <video controls style="width: 100%; height: 100%">
          <source [src]="media.mediaUrl | mediaUrl : media.resolveMediaUrl"/>
        </video>
      </ng-container>
      <ng-container *ngIf="media.mediaType === 'audio'">
        <audio [src]="media.mediaUrl | mediaUrl : media.resolveMediaUrl" controls></audio>
      </ng-container>
    </div>
  </div>
</div>
