export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> {
  return list.reduce((result, item) => {
    let key = getKey(item);
    let group = result[key];
    if(!group) {
      result[key] = group = [];
    }
    group.push(item);
    return result;
  }, {} as Record<K, T[]>);
}
