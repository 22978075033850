import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {SystemPermissionDto} from "../../dtos/local/SystemPermission.dto";
import {SystemRoleDto} from "../../dtos/local/SystemRole.dto";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private readonly baseUrl = `${environment.api.host}/permissions`;

  constructor(
    private http: HttpClient
  ) { }


  public async getPermissions(): Promise<SystemPermissionDto[]> {
    return firstValueFrom(
      this.http.get<SystemPermissionDto[]>(
        `${this.baseUrl}`
      )
    );
  }

}
