<mat-form-field appearance="outline" floatLabel="always">
  <input matInput #input
         placeholder="{{placeholder | transloco}}"
         [matAutocomplete]="auto"
         [formControl]="_control"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText" (optionSelected)="handleOptionSelected($event)">
    <mat-option *ngFor="let option of _options" [value]="option">
      {{ option.place_name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
