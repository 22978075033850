import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import * as turf from "@turf/turf";
import {take} from "rxjs";
import {systemPoiDetailRoute} from "../../../config/routes";
import {WaypointDto, WaypointDtoForm} from "../../../dtos/local/Waypoint.dto";
import {MapboxGeocodeFeature} from "../../../dtos/remote/mapbox/MapboxGeocodeFeature.interface";
import {PoiService} from "../../../services/api/poi.service";
import {ItineroMap} from "../../../utility/maps/ItineroMap";
import {parameterizePath} from "../../../utility/router.utility";
import {FileTypes} from "../../file-picker/FileTypes.enum";
import {SystemPathDto} from "../../../dtos/local/SystemPath.dto";

@Component({
  selector: 'app-path-waypoint-detail-form',
  templateUrl: './path-waypoint-detail-form.component.html',
  styleUrls: ['./path-waypoint-detail-form.component.scss']
})
export class PathWaypointDetailFormComponent implements OnInit {

  @Output() back = new EventEmitter();
  @Output() changed = new EventEmitter();

  @Input({required: true}) map!: ItineroMap;
  @Input({required: true}) path!: SystemPathDto;
  @Input({required: true}) dto?: WaypointDto;
  @Input({required: true}) form!: FormGroup<WaypointDtoForm>;

  cityId?: number;

  constructor(
    private poisvc: PoiService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      const param = params.get('cityId');
      const cityId = Number(param);
      if (isNaN(cityId) || cityId === undefined || cityId === null) {
        console.warn("No cityId param was specified.");
        return;
      }

      this.cityId = cityId;
    });

    const circle = (radius: number | null) => {
      if (radius === null) {
        return;
      }

      if (this.form.controls.coordinates.value) {
        if (this.form.controls.coordinates.value.length > 0) {
          const coordinates = this.form.controls.coordinates.value;
          this.map.clearCircles();
          this.map.addCircle(turf.point(coordinates, { radius: radius }));
        }
      }
    };

    circle(this.form.controls.areaRadiusMeters.value);
    this.form.controls.areaRadiusMeters.valueChanges.subscribe((radius) => {
      circle(radius);
    });

    this.updateMarkerAndPosition();
    this.form.controls.coordinates.valueChanges.subscribe(center => {
      this.updateMarkerAndPosition();
    });

    this.form.markAllAsTouched();
  }

  async setPoint() {
    const point = await this.map.selectPoint();

    if (!point) {
      return;
    }

    // this.map.addSoloMarker(point);
    // this.map.addSoloShadowMarker(point);
    this.form.controls.coordinates.setValue(point.geometry.coordinates);
    // this.changed.emit(this.dto);
  }

  // exit() {
  //   this.map.clearShadowMarkers();
  //   this.map.clearCircles();
  //   this.back.emit();
  // }

  async createPoiFromWaypoint() {
    if (!this.dto) {
      return;
    }

    this.dto.relatedPoiId = await this.poisvc.createPoiFromWaypoint(this.path.id, this.dto.id);
    this.cdr.detectChanges();
  }

  async viewRelatedWaypoint() {
    if (!this.dto || this.cityId === undefined) {
      return;
    }

    await this.router.navigate(
      parameterizePath(
        systemPoiDetailRoute.path!,
        {
          cityId: this.cityId,
          poiId: this.dto.relatedPoiId
        }
      )
    );
  }

  updatePositionViaGeocoding($event: MapboxGeocodeFeature) {
    this.form.controls.coordinates.setValue($event.center); // setValue(turf.point($event.center));
    this.form.controls.coordinates.markAsDirty();
  }

  updateMarkerAndPosition() {
    const center = this.form.controls.coordinates.value;
    if (!center) {
      this.map.clearShadowMarkers();
      return;
    }

    const point = turf.point(center);
    this.map.addSoloShadowMarker(point);
    const activationRadius = turf.circle(
      center,
      this.form.controls.areaRadiusMeters.value ?? 0,
      { units: 'meters' }
    );

    const bbox = turf.bbox(
      turf.featureCollection<any>([
          point,
          activationRadius
        ]
      )
    );
    this.map.api.fitBounds(bbox as any, { padding: 100, maxZoom: 15 });
  }

  protected readonly FileTypes = FileTypes;

}
