<app-map-page-layout [onlyMap]="onlyMap" [backButton]="true" [titleParams]="titleParams">
  <ng-container header-right>
    <button mat-raised-button id="delete" class="accent_error" style="margin-right: 20px;" *ngIf="this.poiId !== -1" (click)="form.delete()">
      <mat-icon>delete</mat-icon>
      {{"generic.actions.deleteEntity" | transloco}}
    </button>
    <button mat-raised-button id="save" class="accent_success" style="min-width: 25%;"
            [disabled]="form.form.invalid || form.form.pristine" (click)="save(form)"
    >
      <mat-icon>save</mat-icon>
      {{"generic.actions.saveModifications" | transloco}}
    </button>
  </ng-container>
  <ng-container left>
    <app-system-poi-form #form [map]="map" [poiId]="poiId"></app-system-poi-form>
  </ng-container>
  <ng-container right>
    <div id="map">
      <div id="controls" *ngIf="onlyMap">
        <app-wide-forward-geocode-button
          (geocodingOptionSelected)="handleInitialGeocodingSelection($event)"
          [coordinateBias]="map.api.getCenter()"
        >
        </app-wide-forward-geocode-button>
      </div>
    </div>
  </ng-container>
</app-map-page-layout>
