<app-system-page-layout *ngIf="group" [overrideTitle]="isCreate ? (createTitle | transloco) : undefined" [titleParams]="{ name: group.name }" [backButton]="true">
  <ng-container content>
    <mat-card class="content-wrapper">
      <ng-container id="editor">
        <h2>{{'pages.systemGroups.editorHeader' | transloco}}</h2>
        <app-group-editor #details [group]="group"></app-group-editor>
      </ng-container>
      <ng-container *ngIf="!isCreate">
        <app-divider></app-divider>
        <ng-container id="members">
          <h2>{{'pages.systemGroups.groupMembers' | transloco}}</h2>
          <app-group-user-list #members [group]="group"></app-group-user-list>
        </ng-container>
      </ng-container>
      <ng-container id="actions">
        <div class="buttons">
          <button mat-raised-button id="delete" class="accent_error" (click)="details.delete()" *ngIf="!isCreate" [disabled]="true">
            <mat-icon>delete</mat-icon>
            {{"generic.entity.group.actions.deleteGroup" | transloco}}
          </button>
          <button mat-raised-button id="save" class="accent_success"
                  [disabled]="details.form.invalid || details.form.pristine" (click)="details.save()"
          >
            <mat-icon>save</mat-icon>
            {{"generic.actions.saveModifications" | transloco}}
          </button>
        </div>
      </ng-container>
    </mat-card>
  </ng-container>
</app-system-page-layout>
