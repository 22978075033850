import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {isNumber} from "@ngneat/transloco";
import * as turf from '@turf/turf';
import {take} from "rxjs";
import {systemCitiesRoute, systemCityDetailRoute} from "../../../config/routes";
import {SystemCityDto} from "../../../dtos/local/SystemCity.dto";
import {MapboxGeocodeFeature} from "../../../dtos/remote/mapbox/MapboxGeocodeFeature.interface";
import {CitiesService} from "../../../services/api/cities.service";
import {UserMetadataService} from "../../../services/user-metadata.service";
import {generateMediaFieldFormDataEntries} from "../../../utility/formdata.utility";
import {ItineroMap} from "../../../utility/maps/ItineroMap";
import {parameterizePathString} from "../../../utility/router.utility";
import {FileTypes} from "../../file-picker/FileTypes.enum";
import {FormComponentInterface} from "../FormComponent.interface";
import {importanceDomain, importanceIcons} from "../../../config/domains/importance";

@Component({
  selector: 'app-system-city-form',
  templateUrl: './system-city-form.component.html',
  styleUrls: ['./system-city-form.component.scss']
})
export class SystemCityFormComponent implements OnChanges {

  @Input({required: true}) dto?: SystemCityDto;
  @Input({required: true}) map!: ItineroMap;

  form = new FormGroup({
    language: new FormControl<string>('', [Validators.required]),
    name: new FormControl<string>('', [Validators.required]),
    description: new FormControl<string>('', [Validators.required]),
    thumbnail: new FormControl<null | string | File>(null, [Validators.required]),
    code: new FormControl<string>('', [Validators.required]),
    center: new FormControl<turf.Feature<turf.Point> | null>(null, [Validators.required]),
    importance: new FormControl<number | null>(null, [Validators.required]),
  });

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public svc: CitiesService,
    private umd: UserMetadataService
  ) {
    (window as any).form = this.form;

    this.form.controls.center.valueChanges.subscribe(point => {
      if (!point) {
        return;
      }

      this.map.addSoloMarker(point);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dto']) {
      const language = this.umd.dataLanguage!;
      if (this.dto) {
        this.form.setValue({
          language: language,
          name: this.dto.name,
          description: this.dto.description,
          thumbnail: this.dto.thumbnailUrl,
          code: this.dto.cityCode,
          center: this.dto.coordinates ? turf.point(this.dto.coordinates) : null,
          importance: this.dto.importance
        });
        this.form.controls.language.enable();

        this.form.controls.language.valueChanges.subscribe(async (value) => {
          const lc = value as string;
          await this.loadDto(this.dto!.id!, lc);
        });
      } else {
        this.form.reset();
        this.form.controls.language.setValue(language);
        this.form.controls.language.disable();
      }
    }
  }

  async delete() {
    if (!this.dto) {
      return;
    }

    try {
      await this.svc.deleteCity(this.dto.id);
      await this.router.navigate([systemCitiesRoute.path]);
    } catch (err) {
      console.log('error during city delete', err);
    }
  }

  async save() {
    const formData = this.getFormDataPayload();
    if (!this.dto) {
      const id = await this.svc.createCity(formData);
      const pathUrl = parameterizePathString(
        systemCityDetailRoute.path!,
        {cityId: id}
      );
      await this.router.navigate([pathUrl]);
    } else {
      await this.svc.updateCity(this.dto.id, formData);
    }
  }

  updatePositionViaGeocoding($event: MapboxGeocodeFeature) {
    this.form.controls.center.setValue(turf.point($event.center));
    this.form.controls.center.markAsDirty();
  }

  private getFormDataPayload() {
    const form = this.form.getRawValue();
    const formData = new FormData();

    formData.append('Name', form.name!);
    formData.append('CityCode', form.code!);
    formData.append('Description', form.description!);
    formData.append('Importance', form.importance!.toString());
    formData.append('Coordinates[0]', form.center!.geometry.coordinates[0].toString());
    formData.append('Coordinates[1]', form.center!.geometry.coordinates[1].toString());
    generateMediaFieldFormDataEntries(
      formData,
      this.form.controls.thumbnail.value!,
      'Thumbnail'
    );
    return formData;
  }

  async loadDto(id: number, lang: string) {
    const dto = await this.svc.getCity(id, lang);
    this.form.setValue({
      language: lang,
      name: dto.name,
      description: dto.description,
      thumbnail: dto.thumbnailUrl,
      code: dto.cityCode,
      center: dto.coordinates ? turf.point(dto.coordinates) : null,
      importance: dto.importance
    }, {emitEvent: false});
    this.dto = dto;
  }

  getImportanceLabel(value: number | null) {
    if (value === null) {
      return;
    }

    const lang = this.importances.find(l => l.value === value);
    return lang?.label;
  }

  getImportanceIcon(value: number | null) {
    if (value === null) {
      return;
    }

    return importanceIcons[value];
  }

  protected readonly FileTypes = FileTypes;

  protected readonly importances = importanceDomain;
}
