import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Feature, Point} from "@turf/turf";
import {ItineroMap} from "../../utility/maps/ItineroMap";

@Component({
  selector: 'app-coordinate-picker',
  templateUrl: './coordinate-picker.component.html',
  styleUrls: ['./coordinate-picker.component.scss']
})
export class CoordinatePickerComponent {

  @Input({required: true}) control!: FormControl<Feature<Point> | null>;
  @Input({required: true}) map!: ItineroMap;

  async setPoint() {
    const point = await this.map.selectPoint();

    if (!point) {
      return;
    }

    this.control.setValue(point);
    this.control.markAsDirty();
  }

}
