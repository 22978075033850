import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {TranslocoService} from "@ngneat/transloco";
import {firstValueFrom} from "rxjs";
import GroupDto from "../../dtos/local/Group.dto";
import {GroupRelationDto} from "../../dtos/local/GroupRelationDto";
import {SystemRoleDto} from "../../dtos/local/SystemRole.dto";
import {CreateUserPayloadDto} from "../../dtos/remote/service-payloads/CreateUserPayload.dto";
import {GroupsService} from "../../services/api/groups.service";
import {RolesService} from "../../services/api/roles.service";
import {UsersService} from "../../services/api/users.service";
import {UserMetadataService} from "../../services/user-metadata.service";
import {UserPickerComponent} from "../../user-picker/user-picker.component";
import {InputDialogComponent, InputDialogData} from "../dialogs/input-dialog/input-dialog.component";
import {LIGHT_BLUE_SHADING, LIGHT_GREEN_SHADING} from "../shaded-placeholder-icon/shaded-placeholder-icon.component";

@Component({
  selector: 'app-group-user-list',
  templateUrl: './group-user-list.component.html',
  styleUrls: ['./group-user-list.component.scss']
})
export class GroupUserListComponent implements OnInit {

  @Input() group?: GroupDto;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<GroupRelationDto>();

  displayedColumns = [
    'firstName',
    'lastName',
    'role'
  ];

  canEditRoles = false;
  rolesDomain: SystemRoleDto[] = [];
  relations: GroupRelationDto[] = [];

  constructor(
    private umd: UserMetadataService,
    private rolesvc: RolesService,
    private groupsvc: GroupsService,
    private usersvc: UsersService,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private txsvc: TranslocoService
  ) {
  }

  async ngOnInit() {
    this.initTableBehavior();

    if (this.umd.hasPermission('CAN_UPDATE_GROUP')) {
      this.canEditRoles = true;
      this.rolesDomain = await this.rolesvc.getRoles();
      this.displayedColumns.push('actions');
    }

    this.relations = this.group?.relations ?? [];
    await this.loadTable();
  }

  async removeUserFromGroup(user: GroupRelationDto) {
    await this.groupsvc.removeUserFromGroup(user);
    this.relations = (await this.groupsvc.getGroup(this.group!.id)).relations;
    await this.loadTable();
  }

  async editUserPassword(user: GroupRelationDto) {
    const data: InputDialogData = {
      message: "generic.actions.editUserPassword.message",
    };

    const ref = this.dialog.open(InputDialogComponent, {
      data: data
    });

    return new Promise((res, rej) => {
      ref.afterClosed().subscribe(async (data) => {
        if (!data) {
          return;
        }

        const baseUser = await this.usersvc.getUser(user.userId);
        const payload: CreateUserPayloadDto = {
          email: baseUser.email,
          name: baseUser.name,
          surname: baseUser.surname,
          password: data,
        };

        try {
          await this.usersvc.updateUser(user.userId, payload);
          res(null);
          this.snack.open(
            await firstValueFrom(this.txsvc.selectTranslate('messages.genericActionOk')),
            "OK",
            { duration: 1000 }
          )
        } catch (e) {
          rej(e);
          this.snack.open(
            await firstValueFrom(this.txsvc.selectTranslate('errors.generic.unexpected')),
            "OK",
            { duration: 1000 }
          )
        }
      });
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async addUser() {
    if (!this.group) {
      return;
    }

    const ref = this.dialog.open(UserPickerComponent, {
      data: {
        excludeUserIds: this.group?.relations.map(r => r.userId) ?? []
      }
    });

    ref.afterClosed().subscribe(async (selection?: number[]) => {
      if (!selection) {
        return;
      }

      for (let userId of selection) {
        await this.groupsvc.addUserToGroup(this.group!.id, userId, 2);
      }

      this.relations = (await this.groupsvc.getGroup(this.group!.id)).relations;
      await this.loadTable();
    });
  }

  private async loadTable() {
    this.dataSource.data = this.relations.map(row => {
      const control = new FormControl(row.roleId);
      control.valueChanges.subscribe(async () => {
        console.log(`updating role: ${row}, ${control.value}`);
        // todo: fix this by calling appropriate service
        await this.groupsvc.removeUserFromGroup(row);
        await this.groupsvc.addUserToGroup(row.groupId, row.userId, control.value!);
      });

      (row as any).control = control;
      return row;
    });
  }

  private initTableBehavior() {
    this.dataSource.filterPredicate = (data, filter) => {
      const fieldsToCheck = [data.name, data.surname, data.roleName];
      return fieldsToCheck.some(field => field.toLowerCase().trim().includes(filter));
    };
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  protected readonly LIGHT_BLUE_SHADING = LIGHT_BLUE_SHADING;
}
