import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {isNumber} from "@ngneat/transloco";
import {environment} from "../../../../environments/environment";
import TileInterface from "../../../dtos/local/Tile.interface";
import {PoiService} from "../../../services/api/poi.service";

@Component({
  selector: 'app-system-city-poi-list',
  templateUrl: './system-city-poi-list.component.html',
  styleUrls: ['./system-city-poi-list.component.scss']
})
export class SystemCityPoiListComponent implements OnChanges {

  @Input() cityId?: number;
  @Output() tileClicked = new EventEmitter<TileInterface>();

  tiles: TileInterface[] = [];
  loaded = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public svc: PoiService
  ) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.loaded = false;
    if (changes.hasOwnProperty('cityId')) {
      const cityId = changes['cityId'].currentValue;
      if (!isNumber(cityId)) {
        return;
      }

      const data = await this.svc.getPoisByCity(this.cityId!);
      this.tiles = data.map(record => {
        const tile: TileInterface = {
          tileId: record.id,
          tileImage: environment.resources.imagePath(record.thumbnail),
          tileName: record.name
        };

        return tile;
      });

      this.loaded = true;
    }
  }

}
