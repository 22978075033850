<app-system-page-layout [backButton]="true" [titleParams]="titleParams">
  <ng-container header-title-controls>
    <button mat-raised-button class="accent_success" (click)="create()">
      <mat-icon>
        add
      </mat-icon>
      {{'generic.actions.createPathHere' | transloco}}
    </button>
  </ng-container>
  <ng-container content *ngIf="cityDto">
    <app-city-group-paths-list [city]="cityDto" (tileClicked)="selectPath($event)"></app-city-group-paths-list>
  </ng-container>
</app-system-page-layout>
