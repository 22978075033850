<div class="wrapper" [class.onlyMap]="onlyMap">
  <div class="header">
    <div class="header-left">
      <div>
        <app-page-title-header [params]="titleParams"></app-page-title-header>
      </div>
      <div>
        <app-back-button *ngIf="backButton" [ClickOverride]="BackButtonOverride"></app-back-button>
      </div>
    </div>
    <div class="header-right">
      <ng-content select="[header-right]"></ng-content>
    </div>
  </div>
  <div class="content" #container>
    <ng-container *ngIf="!onlyMap">
      <div #left class="left">
        <ng-content select="[left]"></ng-content>
      </div>
      <div class="resizer-bar" #bar (mousedown)="startResize($event, container, bar, left, right)" (mouseup)="endResize($event)"></div>
    </ng-container>
    <div #right class="right">
      <ng-content select="[right]"></ng-content>
    </div>
  </div>
</div>
