import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import TileInterface from "../../dtos/local/Tile.interface";

@Component({
  selector: 'app-system-pois-page',
  templateUrl: './system-pois-page.component.html',
  styleUrls: ['./system-pois-page.component.scss']
})
export class SystemPoisPageComponent {

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  async selectCity($event: TileInterface) {
    await this.router.navigate(
      [$event.tileId],
      {relativeTo: this.route}
    );
  }

}
