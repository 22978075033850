import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import TileInterface from "../../../dtos/local/Tile.interface";

export interface ChoiceDialogData {
  message: string;
  options: TileInterface[];
}

@Component({
  selector: 'app-choice-dialog',
  templateUrl: './choice-dialog.component.html',
  styleUrls: ['./choice-dialog.component.scss']
})
export class ChoiceDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChoiceDialogData,
    private ref: MatDialogRef<ChoiceDialogComponent>
  ) {
    this.ref.updateSize(window.innerWidth * .35 + 'px', window.innerHeight * .35 + 'px');
  }

  select(option: TileInterface) {
    this.ref.close(option.tileId);
  }

}
