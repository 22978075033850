<!-- TODO: i18n -->
<app-system-page-layout [backButton]="true">
  <ng-container header-title-controls>
    <button mat-raised-button id="save" class="accent_success" style="min-width: 25%;"
            [disabled]="metadata.form.invalid || metadata.form.pristine" (click)="create()"
    >
      <mat-icon>save</mat-icon>
      Crea
    </button>
  </ng-container>
  <ng-container header-controls>
    <app-stepper #stepper [steps]="steps"></app-stepper>
  </ng-container>
  <ng-container content>
      <section id="city" [class.show]="stepper.stepIndex === 0">
        <app-system-cities-list #cities (tileClicked)="selectCity($event)" (loaded)="parseCityParam($event)"></app-system-cities-list>
      </section>
      <section id="pathType" [class.show]="stepper.stepIndex === 1">
        <app-system-path-types-list #pathTypes (tileClicked)="selectType($event)"></app-system-path-types-list>
      </section>
      <section id="pathIntensity" [class.show]="stepper.stepIndex === 2">
        <app-tile-list [tiles]="intensities" (tileClicked)="selectIntensity($event)"></app-tile-list>
      </section>
      <section id="metadataDetails" [class.show]="stepper.stepIndex === 3">
        <mat-card style="padding: 30px;">
          <app-path-metadata-form #metadata
              [showPublicationFlag]="false"
              [showAudio]="false"
              [showImages]="false"
          ></app-path-metadata-form>
        </mat-card>
      </section>
  </ng-container>
</app-system-page-layout>
