import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {firstValueFrom, take} from "rxjs";
import {SystemPoiFormComponent} from "../../components/forms/system-poi-form/system-poi-form.component";
import {LIGHT_BLUE_SHADING} from "../../components/shaded-placeholder-icon/shaded-placeholder-icon.component";
import {MapboxGeocodeFeature} from "../../dtos/remote/mapbox/MapboxGeocodeFeature.interface";
import {CitiesService} from "../../services/api/cities.service";
import {PoiService} from "../../services/api/poi.service";
import {UserMetadataService} from "../../services/user-metadata.service";
import {ItineroMap} from "../../utility/maps/ItineroMap";
import * as geoutils from "../../utility/maps/lib/GeoUtils";
import * as turf from '@turf/turf';

@Component({
  selector: 'app-system-poi-detail-page',
  templateUrl: './system-poi-detail-page.component.html',
  styleUrls: ['./system-poi-detail-page.component.scss']
})
export class SystemPoiDetailPageComponent implements OnInit, OnDestroy {

  @ViewChild('form') form?: SystemPoiFormComponent;

  map!: ItineroMap;

  poiId?: number;
  cityId?: number;

  onlyMap = false;
  titleParams = {
    cityName: '',
    poiName: ''
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snack: MatSnackBar,
    private poisvc: PoiService,
    private citysvc: CitiesService,
    private umd: UserMetadataService,
    private tx: TranslocoService
  ) {
  }

  async ngOnInit() {
    this.map = new ItineroMap('map');
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      await this.parseParameters(params);
      await this.citysvc.setWorkingCity(this.cityId!);

      if (!this._isCreate) {
        await this.loadPoiData(params);
      } else {
        await this.loadCityData(params);
        await this.newPoiFirstCenter();
      }
    });
  }

  async ngOnDestroy() {
    await this.citysvc.setWorkingCity(-1);
  }

  public async newPoiFirstCenter() {
    await this.form?.setPoint(undefined, true);
    if (this.form?.form.controls.coordinates.value) {
      this.form.form.controls.language.setValue(this.umd.dataLanguage!);
      this.onlyMap = false;
    }
  }

  public async handleInitialGeocodingSelection($event: MapboxGeocodeFeature) {
    await this.form?.setPoint(turf.point($event.center), true);
    this.map.deactivateCurrentMode();

    if (this.form?.form.controls.coordinates.value) {
      this.form.form.controls.language.setValue(this.umd.dataLanguage!);
      this.form.form.controls.name.setValue($event.text);
      this.onlyMap = false;
    }
  }

  private async loadCityData(params: ParamMap) {
    const primaryEntityData = await this.citysvc.getCity(this.cityId!);
    this.titleParams.cityName = primaryEntityData.name;

    const center = geoutils.NumberArrayAsCoordinates(primaryEntityData.coordinates);

    this.map.api.jumpTo({
      center: center,
      zoom: 11
    });
  }

  private async loadPoiData(params: ParamMap) {
    const primaryEntityData = await this.poisvc.getPoi(this.poiId!);
    this.titleParams.poiName = primaryEntityData.name;
    this.titleParams.cityName = primaryEntityData.cityName;

    const center = geoutils.NumberArrayAsCoordinates(primaryEntityData.coordinates);

    this.map.addMarker(center);
    this.map.api.jumpTo({
      center: center,
      zoom: 19
    });
  }

  private async parseParameters(params: ParamMap) {
    await this.parsePOIParameter(params);
    await this.parseCityParameter(params);
  }

  private async parseCityParameter(params: ParamMap) {
    const param = params.get('cityId');
    const cityId = Number(param);
    if (isNaN(cityId) || cityId === undefined || cityId === null) {
      console.warn("No cityId param was specified.");
      return;
    }

    this.cityId = cityId;
  }

  private async parsePOIParameter(params: ParamMap) {
    const param = params.get('poiId');
    if (param === 'new') {
      console.log('Entering create POI mode');
      this.poiId = -1;
      this.onlyMap = true;
      this._isCreate = true;

      this.titleParams.poiName = await firstValueFrom(this.tx.selectTranslate('generic.entity.poi.new'));
      return;
    }

    const poiId = Number(param);
    if (isNaN(poiId) || poiId === undefined || poiId === null) {
      console.warn("No poiId param was specified.");
      return;
    }

    this.poiId = poiId;
  }

  private _isCreate = false;

  async save(form: SystemPoiFormComponent) {
    this.titleParams.poiName = form.form.controls.name.value!;
    await form.save();
  }
}
