import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {createGroupPathRoute} from "../../config/routes";
import TileInterface from "../../dtos/local/Tile.interface";

@Component({
  selector: 'app-group-paths-page',
  templateUrl: './group-paths-page.component.html',
  styleUrls: ['./group-paths-page.component.scss']
})
export class GroupPathsPageComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  async selectCity($event: TileInterface) {
    await this.router.navigate([$event.tileId], { relativeTo: this.route });
  }

  async create() {
    await this.router.navigate([createGroupPathRoute.path]);
  }

}
