<app-system-page-layout>
  <ng-container header-title-controls>
    <button mat-raised-button class="accent_success" (click)="create()">
      <mat-icon>
        add
      </mat-icon>
      {{'generic.actions.createPathType' | transloco}}
    </button>
  </ng-container>
  <ng-container content>
    <app-system-path-types-list (tileClicked)="edit($event)"></app-system-path-types-list>
  </ng-container>
</app-system-page-layout>
