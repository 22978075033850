<ng-container *ngIf="reorderable">
  <div class="list-wrapper" cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="list-item" matRipple cdkDrag
         *ngFor="let item of list; let index = index"
         [class.invalid]="item.invalid"
         (click)="handleItemClicked(item, index)"
         (cdkDragDropped)="itemDropped(item, $event)"
    >
      <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item, index: index }"></ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!reorderable">
  <div class="list-wrapper">
    <div class="list-item" matRipple *ngFor="let item of list; let index = index" [class.invalid]="item.invalid" (click)="handleItemClicked(item, index)">
      <ng-container *ngTemplateOutlet="itemContent; context: { $implicit: item, index: index }"></ng-container>
    </div>
  </div>
</ng-container>


<ng-template #itemContent let-item let-index="index">
  <div class="image" *ngIf="imageTemplate">
    <ng-container *ngTemplateOutlet="imageTemplate; context: { $implicit: item, index: index }"></ng-container>
  </div>
  <div class="label">{{item.label}}</div>
  <div *ngIf="canRemove && item.removable" class="remove" (click)="handleRemoveItem(item, index)">
    <mat-icon>close</mat-icon>
  </div>
</ng-template>

