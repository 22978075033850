import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {take} from "rxjs";
import {FormComponentInterface} from "../../components/forms/FormComponent.interface";
import {SystemAvatarDto} from "../../dtos/local/SystemAvatar.dto";
import {AvatarService} from "../../services/api/avatar.service";
import {UserMetadataService} from "../../services/user-metadata.service";

@Component({
  selector: 'app-system-avatar-detail-page',
  templateUrl: './system-avatar-detail-page.component.html',
  styleUrls: ['./system-avatar-detail-page.component.scss']
})
export class SystemAvatarDetailPageComponent implements OnInit {

  @ViewChild('form') formRef?: FormComponentInterface;

  entityId?: number;
  entity?: SystemAvatarDto;
  create = false;

  constructor(
    private umd: UserMetadataService,
    private route: ActivatedRoute,
    private tx: TranslocoService,
    private avatarsvc: AvatarService
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      const id = params.get(this.paramName);
      if (id === null) {
        console.warn(`No ${this.paramName} param was specified.`);
        return;
      }

      if (id !== 'new') {
        this.entityId = Number(id);
        this.entity = await this.avatarsvc.getAvatarById(this.entityId);
        this.create = false;
      } else {
        this.entityId = undefined;
        this.entity = undefined;
        this.create = true;
      }
    });
  }

  private readonly paramName = 'avatarId';

}
