<div class="form-wrapper">
  <form class="form" [formGroup]="form">

<!--    <ng-container id="language" *ngIf="showLanguage">-->
<!--      <section class="center">-->
<!--        <h3>{{'generic.fields.language' | transloco}}</h3>-->
<!--        <app-data-language-picker [control]="form.controls.language"></app-data-language-picker>-->
<!--      </section>-->
<!--      <app-divider></app-divider>-->
<!--    </ng-container>-->
    <ng-container id="published" *ngIf="showPublicationFlag">
      <section class="center">
        <h3>{{'generic.fields.publishedHeader' | transloco}}</h3>
        <mat-checkbox style="margin-left: -11px;" class="accent_success" [formControl]="form.controls.isPublished">
          {{'generic.fields.published' | transloco}}
        </mat-checkbox>
      </section>
      <app-divider></app-divider>
    </ng-container>
<!--    <ng-container id="intensity">-->
<!--      <section class="center">-->
<!--        <h3>{{'generic.entity.path.intensity' | transloco}}</h3>-->
<!--        <mat-form-field appearance="outline" floatLabel="always">-->
<!--          <mat-select [formControl]="form.controls.intensity">-->
<!--            <mat-select-trigger *ngIf="form.controls.language.value !== null">-->
<!--              <div style="display: flex; align-content: center;">-->
<!--                <img [src]="getIntensityIcon(form.controls.intensity.value)" width="24px" height="24px" alt="flag"-->
<!--                     style="margin-right: 8px;">-->
<!--                <span>{{getIntensityLabel(form.controls.intensity.value) | transloco}}</span>-->
<!--              </div>-->
<!--            </mat-select-trigger>-->
<!--            <mat-option *ngFor="let intensity of intensities" [value]="intensity.value">-->
<!--              <div style="display: flex; align-content: center;">-->
<!--                <img [src]="getIntensityIcon(intensity.value)" width="24px" height="24px" alt="flag"-->
<!--                     style="margin-right: 8px;">-->
<!--                <span>{{intensity.label | transloco}}</span>-->
<!--              </div>-->
<!--            </mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      </section>-->
<!--    </ng-container>-->
<!--    <app-divider></app-divider>-->

    <ng-container id="name">
      <h3>{{'generic.fields.name' | transloco}}</h3>
      <mat-form-field appearance="outline" floatLabel="always">
        <input type="text" matInput [formControl]="form.controls.name" placeholder="{{'generic.fields.name' | transloco}}">
      </mat-form-field>
    </ng-container>
    <ng-container id="description">
      <h3>{{'generic.fields.description' | transloco}}</h3>
      <mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
        <textarea matInput [formControl]="form.controls.description" placeholder="{{'generic.fields.description' | transloco}}"></textarea>
      </mat-form-field>
    </ng-container>

<!--    <ng-container id="thumbnail">-->
<!--      <h3>Thumbnail</h3>-->
<!--      <app-media-field-editor-->
<!--        [type]="FileTypes.Images"-->
<!--        [formControl]="form.controls.thumbnail"-->
<!--      ></app-media-field-editor>-->
<!--    </ng-container>-->
<!--    <ng-container id="duration">-->
<!--      <p>duration</p>-->
<!--    </ng-container>-->

<!--    <ng-container id="avatar">-->
<!--      <h2>Seleziona Avatar</h2>-->
<!--      <app-system-avatar-list></app-system-avatar-list>-->
<!--    </ng-container>-->

    <ng-container id="audio" *ngIf="showAudio">
      <h3>Audio</h3>
      <app-media-field-editor
        [type]="FileTypes.Audio"
        [formControl]="form.controls.audio"
      ></app-media-field-editor>
    </ng-container>

    <ng-container id="images" *ngIf="showImages">
      <h3>{{'generic.fields.images' | transloco}}</h3>
      <app-media-field-editor
        [type]="FileTypes.Images"
        [formControl]="form.controls.images"
        [multi]="true"
      ></app-media-field-editor>
    </ng-container>
  </form>
</div>
