<app-system-page-layout [backButton]="true" [titleParams]="{ roleName: form.controls.name.value }">
  <ng-container content>
    <ng-container *ngIf="initialized">
      <form [formGroup]="form">
        <ng-container id="roleDetails">
          <h2>
            {{'generic.entity.role.name' | transloco}}
          </h2>
          <mat-form-field class="name" appearance="outline" floatLabel="always">
            <input type="text" matInput [formControl]="form.controls.name"
                   [placeholder]="'generic.entity.role.name' | transloco"
            >
          </mat-form-field>
        </ng-container>
        <div class="buttons">
<!--          <button mat-raised-button id="delete" class="accent_error" (click)="delete()">-->
<!--            <mat-icon>delete</mat-icon>-->
<!--            {{"generic.entity.role.actions.delete" | transloco}}-->
<!--          </button>-->
          <button mat-raised-button id="save" class="accent_success"
                  [disabled]="form.invalid || form.pristine" (click)="save()"
          >
            <mat-icon>save</mat-icon>
            {{"generic.actions.saveModifications" | transloco}}
          </button>
        </div>
        <app-divider></app-divider>
        <ng-container id="permissions">
          <div class="permission-list-wrapper">
            <section *ngFor="let category of categories">
              <span>
                <mat-checkbox class="accent_success parent_checkbox"
                              [checked]="category.status ? category.status : false"
                              [indeterminate]="category.status === undefined"
                              (change)="categoryStateChanged($event, category)"
                >
                  {{category.name | transloco}}
                </mat-checkbox>
              </span>
              <ul>
                <li *ngFor="let permissionState of category.states">
                  <mat-checkbox class="accent_success child_checkbox"
                    [checked]="permissionState.checked"
                    (change)="permissionStateChanged($event, permissionState, category)"
                  >
                    {{permissionState.permission.name}}
                  </mat-checkbox>
                </li>
              </ul>
            </section>
          </div>
        </ng-container>
      </form>
    </ng-container>
    <router-outlet></router-outlet>
  </ng-container>
</app-system-page-layout>
