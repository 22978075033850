<div class="sidenav-wrapper" #wrapper>
  <div style="display: flex; justify-content: center; padding: 10px 0;">
    <app-logo style="max-width: 75%"></app-logo>
  </div>
  <app-group-selector></app-group-selector>
  <app-menu-section *ngFor="let section of availableSections"
                    [title]="section.hideTitle ? undefined : section.title | transloco : translationParameterBag"
                    [expand]="section.expandsToBottom ?? false"
                    [separator]="!section.hideSeparator"
  >
    <ul>
      <li *ngFor="let route of section.routes">
        <ng-container *ngTemplateOutlet="routeTemplate; context: { $implicit: route }"></ng-container>
      </li>
    </ul>
    <ul *ngIf="section.bottomRoutes && section.bottomRoutes.length > 0" style="margin-top: auto;">
      <li *ngFor="let route of section.bottomRoutes">
        <ng-container *ngTemplateOutlet="routeTemplate; context: { $implicit: route }"></ng-container>
      </li>
    </ul>
  </app-menu-section>
  <app-menu-section [separator]="false">
    <app-profile-tag></app-profile-tag>
  </app-menu-section>
</div>

<ng-template #routeTemplate let-route>
  <ng-container *ngIf="!route.disabled">
    <button mat-flat-button (click)="navigateToRoute(route, $event)"
            [attr.data-activatedRoutePath]="route.route.path"
            [attr.data-activatedRouteId]="route.route.data!['id']"
            class="nav-button"
    >
      <mat-icon matIconPrefix>{{route.icon}}</mat-icon>
      <span>{{getRouteName(route) | transloco : translationParameterBag}}</span>
      <mat-icon matIconSuffix>chevron_right</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="route.disabled">
    <button mat-flat-button class="nav-button disabled">
      <mat-icon matIconPrefix>{{route.icon}}</mat-icon>
      <span>{{getRouteName(route) | transloco : translationParameterBag}}</span>
      <mat-icon matIconSuffix>lock</mat-icon>
    </button>
  </ng-container>
</ng-template>
