import {Component, Input} from '@angular/core';

export interface IStepperStep {
  label: string;
  disabled: boolean;
  editable: boolean;
  completed: boolean;
  invalid: boolean;
  returnable: boolean;
}

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {

  @Input({required: true}) steps: IStepperStep[] = [];

  stepIndex = 0;

  get complete() {
    return this.steps.every(step => step.completed && !step.invalid);
  }

  get incomplete() {
    return this.steps.some(step => !step.completed || step.invalid);
  }

  public completeStep(index: number) {
    this.steps[index].completed = true;
    this.steps[index].invalid = false;
    this.stepIndex++;
    this.enableNextStep(index);
  }

  public enableNextStep(index: number) {
    if (index + 1 < this.steps.length) {
      this.steps[index + 1].disabled = false;
      this.steps[index + 1].editable = true;
    }
  }

  public selectStep(index: number) {
    const step = this.steps[index];
    if (!step) {
      return;
    }

    if (step.disabled) {
      return;
    }

    if (index === this.stepIndex) {
      return;
    }

    // prevent returnal to step if "returnable" is false
    if (this.steps[index].completed && !this.steps[index].returnable) {
      return;
    }

    this.stepIndex = index;
  }

  public stepRippleIsDisabled(index: number) {
    const step = this.steps[index];
    return step.disabled
      // selected step is current step
      || this.stepIndex === index
      // step is not disabled, and it is completed but not returnable
      || (!step.disabled && (step.completed && !step.returnable))
      ;
  }

}
