<div class="wrapper" matRipple>
  <div class="icon">
    <ng-content select="icon"></ng-content>
  </div>
  <div class="content">
    <ng-content select="content"></ng-content>
  </div>
  <div class="chevron">
    <mat-icon>chevron_right</mat-icon>
  </div>
</div>
