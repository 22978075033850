import { Component } from '@angular/core';
import {MatMenu} from "@angular/material/menu";
import {UserGroupDto} from "../../dtos/local/UserGroup.dto";
import {UserMetadataService} from "../../services/user-metadata.service";

@Component({
  selector: 'app-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss']
})
export class GroupSelectorComponent {

  constructor(
    public umd: UserMetadataService
  ) {
  }


  injectCustomStyle(menu: MatMenu) {
    const panel = document.querySelector(`#${menu.panelId}`);
    if (!panel) {
      return;
    }

    const bboxEl = panel.closest('.cdk-overlay-connected-position-bounding-box') as HTMLElement;
    if (!bboxEl) {
      return;
    }

    setTimeout(() => {
      panel.parentElement!.style.width = bboxEl.style.width;
      panel.parentElement!.style.paddingLeft = bboxEl.style.paddingLeft;
      panel.parentElement!.classList.add('group-selector-parent');
    }, 10);
  }

  async handleItemSelection(group: UserGroupDto) {
    await this.umd.setGroup(group);
    // await this.umd.setGroup(group);
  }

}
