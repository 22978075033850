import { Component } from '@angular/core';
import {LIGHT_BLUE_SHADING} from "../shaded-placeholder-icon/shaded-placeholder-icon.component";

@Component({
  selector: 'app-icon-accordion',
  templateUrl: './icon-accordion.component.html',
  styleUrls: ['./icon-accordion.component.scss']
})
export class IconAccordionComponent {

    protected readonly LIGHT_BLUE_SHADING = LIGHT_BLUE_SHADING;
}
