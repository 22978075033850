<div class="form-wrapper">
  <form class="form" [formGroup]="form">


    <ng-container id="language">
      <section class="center">
        <h3>{{"generic.fields.contentLanguage" | transloco}}</h3>
        <app-data-language-picker [control]="form.controls.language"></app-data-language-picker>
      </section>
    </ng-container>
    <app-divider></app-divider>
    <ng-container id="published" *ngIf="!_isCreate">
      <section class="center">
        <h3>{{"generic.fields.publishedHeader" | transloco}}</h3>
        <mat-checkbox style="margin-left: -11px;" class="accent_success" [formControl]="form.controls.isPublished">
          {{"generic.fields.published" | transloco}}
        </mat-checkbox>
      </section>
      <app-divider></app-divider>
    </ng-container>
    <ng-container id="importance">
      <section class="center">
        <h3>{{'generic.entity.poi.importance' | transloco}}</h3>
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-select [formControl]="form.controls.importance">
            <mat-select-trigger *ngIf="form.controls.language.value !== null">
              <div style="display: flex; align-content: center;">
                <img [src]="getImportanceIcon(form.controls.importance.value)" width="24px" height="24px" alt="flag"
                     style="margin-right: 8px;">
                <span>{{getImportanceLabel(form.controls.importance.value) | transloco}}</span>
              </div>
            </mat-select-trigger>
            <mat-option *ngFor="let importance of importances" [value]="importance.value">
              <div style="display: flex; align-content: center;">
                <img [src]="getImportanceIcon(importance.value)" width="24px" height="24px" alt="flag"
                     style="margin-right: 8px;">
                <span>{{importance.label | transloco}}</span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>
    </ng-container>
    <app-divider></app-divider>
    <ng-container id="coordinates">
      <section class="center">
        <h3>{{'generic.entity.poi.coordinates' | transloco}}</h3>
        <mat-form-field appearance="outline" floatLabel="always" style="margin-right: 20px;">
          <input type="text" matInput [formControl]="form.controls.coordinates" placeholder="{{'generic.fields.coordinatePlaceholder' | transloco}}">
        </mat-form-field>
        <button mat-raised-button class="accent_success" (click)="setPoint(undefined, false)">
          <mat-icon>edit</mat-icon>
          {{'generic.actions.setCenter' | transloco}}
        </button>
      </section>
    </ng-container>
    <app-divider></app-divider>
    <br>

    <ng-container id="name">
      <h3>{{'generic.entity.poi.name' | transloco}}</h3>
      <mat-form-field appearance="outline" floatLabel="always">
        <input type="text" matInput [formControl]="form.controls.name" placeholder="{{'generic.fields.name' | transloco}}">
      </mat-form-field>
    </ng-container>
    <ng-container id="description">
      <h3>{{'generic.entity.poi.description' | transloco}}</h3>
      <mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
        <textarea matInput [formControl]="form.controls.description" placeholder="{{'generic.fields.description' | transloco}}"></textarea>
      </mat-form-field>
    </ng-container>
    <ng-container id="thumbnail">
      <h3>{{'generic.fields.thumbnail' | transloco}}</h3>
      <app-media-field-editor
        [type]="FileTypes.Images"
        [formControl]="form.controls.thumbnail"
      ></app-media-field-editor>
    </ng-container>
    <ng-container id="video">
      <h3>{{'generic.fields.video' | transloco}}</h3>
      <app-media-field-editor
        [type]="FileTypes.Videos"
        [formControl]="form.controls.video"
      ></app-media-field-editor>
    </ng-container>
    <ng-container id="audio">
      <h3>{{'generic.fields.audio' | transloco}}</h3>
      <app-media-field-editor
        [type]="FileTypes.Audio"
        [formControl]="form.controls.audio"
      ></app-media-field-editor>
    </ng-container>
    <ng-container id="images">
      <h3>{{'generic.fields.images' | transloco}}</h3>
      <app-media-field-editor
        [type]="FileTypes.Images"
        [formControl]="form.controls.images"
        [multi]="true"
      ></app-media-field-editor>
    </ng-container>

  </form>
</div>
