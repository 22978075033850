<!--TODO: i18n-->
<mat-form-field>
  <mat-label>{{'generic.actions.searchUser' | transloco}}</mat-label>
  <input matInput (keyup)="applyFilter($event)" #input>
</mat-form-field>
<div style="width: 100%;  text-align: right; margin-bottom: 20px;">
  <button mat-raised-button class="accent_info" (click)="addUser()">
    <mat-icon>add</mat-icon>
    {{ 'generic.actions.addUser' | transloco }}
  </button>
</div>
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <!-- firstName Column -->
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.fields.firstName' | transloco }} </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>

  <!-- lastName Column -->
  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.fields.lastName' | transloco }} </th>
    <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
  </ng-container>

  <!-- Role Column -->
  <ng-container matColumnDef="role" *ngIf="!canEditRoles">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.fields.role' | transloco }} </th>
    <td mat-cell *matCellDef="let element"> {{element.roleName}} </td>
  </ng-container>

  <!-- Role Column -->
  <ng-container matColumnDef="role" *ngIf="canEditRoles && this.rolesDomain">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.fields.role' | transloco }} </th>
    <td mat-cell *matCellDef="let element">
      <mat-select [formControl]="element.control">
        <mat-option *ngFor="let role of rolesDomain" [value]="role.id">
          {{role.localizedName}}
        </mat-option>
      </mat-select>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions" *ngIf="canEditRoles">
    <th mat-header-cell *matHeaderCellDef> {{ 'generic.fields.actions' | transloco }} </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="removeUserFromGroup(element)">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button (click)="editUserPassword(element)">
        <mat-icon>lock_reset</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true"></mat-paginator>
