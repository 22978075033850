<app-system-page-layout>
  <ng-container content>
    <div class="list-wrapper">
      <app-wide-icon-button *ngFor="let role of systemRoles" (click)="viewRoleDetail(role)">
        <ng-container ngProjectAs="icon">
          <app-shaded-placeholder-icon class="title-icon" [shadeSteps]="LIGHT_BLUE_SHADING"></app-shaded-placeholder-icon>
        </ng-container>
        <ng-container ngProjectAs="content">
          <h2>{{role.localizedName}}</h2>
        </ng-container>
      </app-wide-icon-button>
    </div>
    <router-outlet></router-outlet>
  </ng-container>
</app-system-page-layout>
