import {HttpErrorResponse} from "@angular/common/http";
import {Component, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import LoginRequestDto from "../../dtos/remote/LoginRequest.dto";
import {UserMetadataService} from "../../services/user-metadata.service";

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  @Output() submitted = new EventEmitter<LoginRequestDto>();

  form = new FormGroup({
    email: new FormControl<string|null>(null, [Validators.required, Validators.email]),
    password: new FormControl<string|null>(null, [Validators.required])
  });

  awaitingResponse = false;

  errorMessage?: string;

  constructor(
    private umd: UserMetadataService,
    private router: Router
  ) {
  }

  async submit() {
    if (!this.form.valid) {
      return;
    }

    try {
      this.form.disable();
      const payload: LoginRequestDto = {
        email: this.form.controls.email.value!,
        password: this.form.controls.password.value!,
      };

      this.awaitingResponse = true;
      await this.sendRequest(payload);
      this.submitted.emit(payload);
      if (this.umd.authenticated.value) {
        console.log('login successful - navigating to homepage');
        await this.router.navigate([environment.homePath]);
      } else {
        console.log('graceful login failure');
        this.errorMessage = "errors.login.unexpected";
      }
    } catch (e: any) {
      console.log(`fatal login failure:`, e);
      if (e instanceof HttpErrorResponse) {
        const res = e as HttpErrorResponse;
        switch (res.status) {
          case 403:
            this.errorMessage = "errors.login.invalidCredentials";
            break;
          default:
            this.errorMessage = "errors.login.unexpected";
            break;
        }
      } else {
        this.errorMessage = "errors.login.unexpected";
      }
    } finally {
      this.awaitingResponse = false;
      this.form.enable();
    }
  }

  private async sendRequest(payload: LoginRequestDto) {
    await this.umd.login(payload);
  }

}
