<div class="form-wrapper">
  <form class="form" [formGroup]="form">
    <ng-container id="language">
      <section class="center">
        <h3>{{ "generic.fields.contentLanguage" | transloco }}</h3>
        <app-data-language-picker [control]="form.controls.language"></app-data-language-picker>
      </section>
    </ng-container>

    <h3>{{ 'generic.importance' | transloco }}</h3>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-select [formControl]="form.controls.importance">
        <mat-select-trigger *ngIf="form.controls.language.value !== null">
          <div style="display: flex; align-content: center;">
            <img [src]="getImportanceIcon(form.controls.importance.value)" width="24px" height="24px" alt="flag"
                 style="margin-right: 8px;">
            <span>{{getImportanceLabel(form.controls.importance.value) | transloco}}</span>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let importance of importances" [value]="importance.value">
          <div style="display: flex; align-content: center;">
            <img [src]="getImportanceIcon(importance.value)" width="24px" height="24px" alt="flag"
                 style="margin-right: 8px;">
            <span>{{importance.label | transloco}}</span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h3>{{ 'generic.entity.city.name' | transloco }}</h3>
    <!--    <mat-form-field appearance="outline" floatLabel="always">-->
    <!--      <input type="text" matInput [formControl]="form.controls.name" placeholder="Nome">-->
    <!--    </mat-form-field>-->
    <app-geocode-textbox [formControl]="form.controls.name"
                         [placeholder]="'generic.fields.name' | transloco"
                         (geocodingOptionSelected)="updatePositionViaGeocoding($event)"
    ></app-geocode-textbox>
    <br>

    <h3>{{ 'generic.entity.city.description' | transloco }}</h3>
    <mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
      <textarea matInput [formControl]="form.controls.description" placeholder="Descrizione"></textarea>
    </mat-form-field>

    <h3>{{ 'generic.entity.city.cityCode' | transloco }}</h3>
    <mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
      <input type="text" matInput [formControl]="form.controls.code" placeholder="Codice città" maxlength="3">
    </mat-form-field>

    <h3>{{ 'generic.entity.city.thumbnail' | transloco }}</h3>
    <app-media-field-editor [type]="FileTypes.Images" [formControl]="form.controls.thumbnail"
                            [multi]="false"></app-media-field-editor>
    <br>

    <h3>{{ 'generic.entity.city.center' | transloco }}</h3>
    <app-coordinate-picker [control]="form.controls.center" [map]="map"></app-coordinate-picker>
  </form>
</div>
