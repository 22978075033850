import {
  AfterViewInit,
  Component,
  ElementRef, Inject,
  Input,
  OnChanges,
  OnInit, Optional,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {BehaviorSubject, filter, take} from "rxjs";
import * as url from "url";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('wrapper') wrapper?: ElementRef<HTMLDivElement>;
  @Input() imageUrls: string[] = [];
  @Input() allowModal: boolean = true;
  @Input() height?: string = '300px';

  currentIndex = 0;

  constructor(
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private dialogData: { imageUrls: string[], currentIndex?: number, allowModal?: boolean, height?: string }
  ) {
    if (this.dialogData) {
      this.imageUrls = this.dialogData.imageUrls;
      this.currentIndex = this.dialogData.currentIndex ?? 0;
      this.allowModal = this.dialogData.allowModal ?? true;
      this.height = this.dialogData.height ?? '100%';
    }
  }

  ngOnInit(): void {
    this.updateImageUrls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageUrls']) {
      this.updateImageUrls();
    }
  }

  ngAfterViewInit(): void {
    this._ready.next(true);
  }

  previous() {
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this.imageUrls.length - 1;
    }

    this.updateOffsets();
    console.log(this.currentIndex);
  }

  next() {
    this.currentIndex++;
    if (this.currentIndex === this.imageUrls.length) {
      this.currentIndex = 0;
    }

    this.updateOffsets();
    console.log(this.currentIndex);
  }

  modal() {
    this.dialog.open(ImageGalleryComponent, {
      data: {
        imageUrls: this.imageUrls,
        currentIndex: this.currentIndex,
        allowModal: false,
      },
      width: '80%',
      height: '80%',
      disableClose: false
    });
  }

  private updateImageUrls() {
    this.images = this.imageUrls.map(url => environment.resources.imagePath(url));
    this.currentIndex = 0;

    const update = () => {
      this._imageRefs = Array.from(this.wrapper!.nativeElement.querySelectorAll('.image-wrapper'));
      this.updateOffsets();
    };

    // if wrapper is not yet referenced, wait for it to be and then retry later
    if (!this.wrapper) {
      this._ready.pipe(filter(
        (v) => v),
        take(1)
      ).subscribe(() => {
        update();
      });

      return;
    }

    update();
  }

  private updateOffsets() {
    // if wrapper is not yet referenced, wait for it to be and then retry later
    this._ready.pipe(filter(
        (v) => v),
      take(1)
    ).subscribe(() => {
      const containerWidth = this.wrapper!.nativeElement.clientWidth;
      this._imageRefs.forEach((img, index) => {
        img.style.left = `${containerWidth * (index - this.currentIndex)}px`;
      });
    });
  }

  protected images: string[] = [];
  private _imageRefs: HTMLDivElement[] = [];
  private _ready = new BehaviorSubject(false);

}
