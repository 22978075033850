import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {take} from "rxjs";
import {createGroupPathRoute} from "../../config/routes";
import {SystemCityDto} from "../../dtos/local/SystemCity.dto";
import TileInterface from "../../dtos/local/Tile.interface";
import {CitiesService} from "../../services/api/cities.service";

@Component({
  selector: 'app-city-group-paths-page',
  templateUrl: './city-group-paths-page.component.html',
  styleUrls: ['./city-group-paths-page.component.scss']
})
export class CityGroupPathsPageComponent implements OnInit, OnDestroy {

  cityDto!: SystemCityDto;
  titleParams = {
    cityName: '',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private citysvc: CitiesService
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      const cityId = Number(params.get('cityId'));
      if (isNaN(cityId) || cityId === undefined || cityId === null) {
        console.warn("No cityId param was specified.");
        return;
      }

      await this.citysvc.setWorkingCity(cityId);
      const cityDto = await this.citysvc.getCity(cityId);
      this.cityDto = cityDto;
      this.titleParams.cityName = cityDto.name;
    });
  }

  async ngOnDestroy() {
    await this.citysvc.setWorkingCity(-1);
  }

  async create() {
    await this.router.navigate(
      [createGroupPathRoute.path],
      {
        queryParams: {
          cityId: this.cityDto.id
        }
      }
    );
  }

  async selectPath($event: TileInterface) {
    await this.router.navigate([$event.tileId], { relativeTo: this.route });
  }

}
