import {Component, Input} from '@angular/core';
import {LayoutService} from "../../../services/layout.service";

@Component({
  selector: 'app-map-page-layout',
  templateUrl: './map-page-layout.component.html',
  styleUrls: ['./map-page-layout.component.scss']
})
export class MapPageLayoutComponent {

  @Input() BackButtonOverride?: Function;

  @Input() titleParams: any;
  @Input() backButton = true;

  @Input() onlyMap = false;

  constructor(
    private layoutsvc: LayoutService
  ) {
    this.layoutsvc.hideHomeLayoutLeftPane();
  }

  startResize($event: MouseEvent, container: HTMLDivElement, bar: HTMLDivElement, left: HTMLDivElement, right: HTMLDivElement) {
    $event.preventDefault();
    document.body.style.cursor = 'col-resize';
    this._container = container;
    this._bar = bar;
    this._left = left;
    this._right = right;
    this._resizing = true;
    window.addEventListener('mousemove', this._mouseMoveListener);
    window.addEventListener('mouseup', this._mouseUpListener);
    this._resizing = true;

    this.setLeftPanelWidth(left.getBoundingClientRect().width);
  }

  endResize($event: MouseEvent) {
    document.body.style.removeProperty('cursor');
    this._container = undefined;
    this._bar = undefined;
    this._left = undefined;
    this._right = undefined;
    if (this._mouseMoveListener) {
      window.removeEventListener('mousemove', this._mouseMoveListener);
    }
    if (this._mouseUpListener) {
      window.removeEventListener('mouseup', this._mouseUpListener);
    }
  }

  private handleMouseMoveEvent($event: MouseEvent) {
    if (!this._resizing) {
      return;
    }

    this.setLeftPanelWidth($event.clientX);
  }

  private handleMouseUpEvent($event: MouseEvent) {
    this.endResize($event);
  }

  private setLeftPanelWidth(widthPx: number) {
    const availableWidth = this._container!.getBoundingClientRect().width;
    const barWidth = this._bar!.getBoundingClientRect().width;
    this._left!.style.width = widthPx + 'px';
    this._bar!.style.left = (widthPx - barWidth / 1.5) + 'px';
    this._right!.style.width = (availableWidth - widthPx) + 'px';
  }

  private _resizing = false;
  private _container?: HTMLDivElement;
  private _bar?: HTMLDivElement;
  private _left?: HTMLDivElement;
  private _right?: HTMLDivElement;

  private _mouseMoveListener = this.handleMouseMoveEvent.bind(this);
  private _mouseUpListener = this.handleMouseUpEvent.bind(this);
}
