<mat-form-field appearance="outline" floatLabel="always" [class]="mffClass">
  <mat-select placeholder="{{'generic.fields.language' | transloco}}" [formControl]="control">
    <mat-select-trigger *ngIf="control.value !== null">
      <div style="display: flex; align-content: center;">
        <img [src]="getLanguageIcon(control.value)" width="24px" height="24px" alt="flag"
             style="margin-right: 8px;">
        <span>{{getLanguageLabel(control.value)}}</span>
      </div>
    </mat-select-trigger>
    <mat-option *ngFor="let language of languages" [value]="language.code">
      <div style="display: flex; align-content: center;">
        <img [src]="getLanguageIcon(language.code)" width="24px" height="24px" alt="flag"
             style="margin-right: 8px;">
        <span>{{language.name}}</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
