import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {take} from "rxjs";
import GroupDto from "../../dtos/local/Group.dto";
import {GroupsService} from "../../services/api/groups.service";
import {RolesService} from "../../services/api/roles.service";
import {UserMetadataService} from "../../services/user-metadata.service";
import {UserPickerComponent} from "../../user-picker/user-picker.component";

@Component({
  selector: 'app-group-detail-page',
  templateUrl: './group-detail-page.component.html',
  styleUrls: ['./group-detail-page.component.scss']
})
export class GroupDetailPageComponent implements OnInit {

  group!: GroupDto;
  isCreate = false;
  createTitle = 'routes.createSystemGroup';

  constructor(
    private umd: UserMetadataService,
    private route: ActivatedRoute,
    private svc: GroupsService,
    private rolesvc: RolesService,
    private tx: TranslocoService
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      const groupIdParam = params.get('groupId');
      if (!groupIdParam) {
        console.warn("No groupId param was specified.");
        return;
      }

      if (groupIdParam === 'new') {
        this.isCreate = true;
        this.group = {
          id: -1,
          name: '',
          description: '',
          relations: [],
          disabled: false
        };
      } else {
        this.group = await this.svc.getGroup(Number(groupIdParam));
      }
    });
  }

}
