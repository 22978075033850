import {LabelValueDto} from "../../dtos/local/LabelValue.dto";

export const importanceDomain: LabelValueDto<number>[] = [
  {
    label: "domains.importance.primary",
    value: 0
  },
  {
    label: "domains.importance.secondary",
    value: 1
  },
  {
    label: "domains.importance.tertiary",
    value: 2
  },
  {
    label: "domains.importance.other",
    value: 3
  },
];

export const importanceIcons: { [value: number]: string } = {
  0: 'assets/icons/importance/primary.png',
  1: 'assets/icons/importance/secondary.png',
  2: 'assets/icons/importance/tertiary.png',
  3: 'assets/icons/importance/other.png',
};
