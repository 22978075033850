import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {LngLat} from "maplibre-gl";
import {MapboxGeocodeFeature} from "../../dtos/remote/mapbox/MapboxGeocodeFeature.interface";
import {MapboxAPIService} from "../../services/mapbox-api.service";
import * as turf from '@turf/turf';

@Component({
  selector: 'app-geocode-textbox',
  templateUrl: './geocode-textbox.component.html',
  styleUrls: ['./geocode-textbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeocodeTextboxComponent),
      multi: true
    }
  ],
  host: {
    // '(change)': '_onChange($event)',
    // '(change)': '_onChange($event.target.value)'
    '(blur)': '_onTouched()'
  }
})
export class GeocodeTextboxComponent implements ControlValueAccessor {

  @Output() searchTimerStarted = new EventEmitter();
  @Output() searchTimerElapsed = new EventEmitter();
  @Output() geocodingOptionSelected = new EventEmitter<MapboxGeocodeFeature>();

  @Input() placeholder?: string = 'messages.forwardGeocodePlaceholder';
  @Input() minimumGeocodeLength = 3;
  @Input() searchDebounceMs = 1000;
  @Input() coordinateBias?: LngLat;

  get position(): turf.Point | null {
    return this._selectedFeature ? this._selectedFeature.geometry as turf.Point : null;
  }

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private mapboxApi: MapboxAPIService
  ) {
    this._control.valueChanges.subscribe(value => {
      this._onChange(value);
      if (value && value.length >= this.minimumGeocodeLength) {
        this.restartDebounceTimer();
      }
    });
  }

  getOptionText: ((value: string | MapboxGeocodeFeature) => string) | null = (v) => {
    if (v === null) {
      return '';
    }

    if (typeof v === 'string') {
      return v;
    } else {
      return v.text;
    }
  };

  writeValue(obj: any): void {
    console.log("wrote value:", obj);
    this._control.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  handleOptionSelected($event: MatAutocompleteSelectedEvent) {
    const feature = $event.option.value as MapboxGeocodeFeature;
    this._selectedFeature = feature;
    this._onChange(feature.text);
    this.geocodingOptionSelected.emit(feature);
  }

  private restartDebounceTimer() {
    if (this._debounceTimer) {
      clearTimeout(this._debounceTimer);
      this._timerElapsed = false;
    }

    this.searchTimerStarted.emit();
    this._debounceTimer = setTimeout(async () => {
      this._timerElapsed = true;
      this.searchTimerElapsed.emit();
      await this.doGeocode();
    }, this.searchDebounceMs);
  }

  private async doGeocode() {
    const data = await this.mapboxApi.forwardGeocoding(this._control.value!, this.coordinateBias);
    this._options = data.features as MapboxGeocodeFeature[];
  }

  _onChange!: (_: any) => void;
  _onTouched!: any;
  _options: MapboxGeocodeFeature[] = [];
  _control = new FormControl('');
  private _debounceTimer?: any;
  private _timerElapsed = false;
  private _selectedFeature?: MapboxGeocodeFeature;

  // @Output() valueChanged = new EventEmitter<string| MapboxGeocodeFeature | null>();
  // @Output() geocodingOptionSelected = new EventEmitter<MapboxGeocodeFeature>();
  //
  // @Input() coordinateBias?: LngLat;
  //
  // @Input() placeholder = 'messages.forwardGeocodePlaceholder';
  // @Input() searchDebounceMs = 1000;
  //
  // control = new FormControl<string | MapboxGeocodeFeature>('');
  // filteredOptions: Observable<MapboxGeocodeFeature[]>;
  // getOptionText: ((value: string | MapboxGeocodeFeature) => string) | null = (v) => {
  //   if (typeof v === 'string') {
  //     return v;
  //   } else {
  //     return v.place_name;
  //   }
  // };
  //
  // constructor(
  //   private mapboxApi: MapboxAPIService
  // ) {
  //   (window as any).geocodingControl = this.control;
  //
  //   this.filteredOptions = this.control.valueChanges.pipe(
  //     startWith(''),
  //     tap((value) => {
  //       this.valueChanged.emit(value)
  //     }),
  //     debounceTime(this.searchDebounceMs),
  //     switchMap(query => {
  //       if (!query) {
  //         return [];
  //       }
  //
  //       if (typeof query !== 'string') {
  //         return this.filteredOptions;
  //       }
  //
  //       if (!query || (query && query.length < 3)) {
  //         return [];
  //       }
  //
  //       return this.mapboxApi.forwardGeocoding(query, this.coordinateBias)
  //         .then(response => response.features as MapboxGeocodeFeature[]);
  //     }),
  //   );
  // }
  //
  // handleOptionSelected($event: MatAutocompleteSelectedEvent) {
  //   const feature = $event.option.value as MapboxGeocodeFeature;
  //   this.geocodingOptionSelected.emit(feature);
  // }
}

