<ng-container id="waypoints">
  <div class="list-wrapper">
    <div class="list-item" *ngFor="let waypoint of waypoints; let index = index">
      <app-wide-icon-button (click)="handleSelect(waypoint, index)">
        <ng-container ngProjectAs="icon">
          <mat-icon>location_on</mat-icon>
        </ng-container>
        <ng-container ngProjectAs="content">
          <h2 style="margin: 0; display: inline-block;">{{waypoint.name}}</h2>
          <button style="float:right;" mat-raised-button class="accent_error" (click)="removeWaypoint(waypoint, index)">
            <mat-icon style="margin: 0;">delete</mat-icon>
          </button>
        </ng-container>
      </app-wide-icon-button>
    </div>
  </div>

  <button mat-raised-button class="accent_info add-waypoint" (click)="addWaypoint()">
    <mat-icon>add</mat-icon>
    {{ 'generic.actions.addNewWaypoint' | transloco }}
  </button>
</ng-container>
