import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BehaviorSubject, filter, take} from "rxjs";
import {MediaGalleryItem} from "./media-gallery-item.interface";
import {MediaGalleryDialogDataInterface} from "./MediaGalleryDialogData.interface";

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss']
})
export class MediaGalleryComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('wrapper') wrapper?: ElementRef<HTMLDivElement>;

  @Input({ required: true }) medias: MediaGalleryItem[] = [];
  @Input() allowModal: boolean = true;
  @Input() height?: string = '300px';

  currentIndex = 0;
  isModal = false;

  get currentMedia(): MediaGalleryItem {
    return this.medias[this.currentIndex as number];
  }

  get currentMediaIsImage(): boolean {
    return this.currentMedia.mediaType === 'image';
  }

  get currentMediaIsVideo(): boolean {
    return this.currentMedia.mediaType === 'video';
  }

  get currentMediaIsAudio(): boolean {
    return this.currentMedia.mediaType === 'audio';
  }

  constructor(
    private dialog: MatDialog,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    private dialogData: MediaGalleryDialogDataInterface,
    @Optional() private dialogRef: MatDialogRef<MediaGalleryComponent>
  ) {
    if (this.dialogData) {
      this.isModal = true;
      this.medias = this.dialogData.medias;
      this.currentIndex = this.dialogData.currentIndex ?? 0;
      this.allowModal = this.dialogData.allowModal ?? true;
      this.height = this.dialogData.height ?? '100%';
    }
  }

  ngOnInit(): void {
    this.updateRefs();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('media-gallery changes', changes);
    if (changes['medias']) {
      this.currentIndex = 0;
      this.update();
    }
  }

  ngAfterViewInit(): void {
    if (this.isModal) {
      const componentRef = this.dialogRef.componentRef!.location.nativeElement as HTMLElement;
      componentRef.parentElement!.style.overflow = 'hidden';
      componentRef.parentElement!.style.background = 'transparent';
    }
    this._ready.next(true);
  }

  previous() {
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this.medias.length - 1;
    }

    this.updateOffsets();
  }

  next() {
    this.currentIndex++;
    if (this.currentIndex === this.medias.length) {
      this.currentIndex = 0;
    }

    this.updateOffsets();
  }

  modal() {
    this.dialog.open(MediaGalleryComponent, {
      data: {
        medias: this.medias,
        currentIndex: this.currentIndex,
        allowModal: false,
      } as MediaGalleryDialogDataInterface,
      width: '80%',
      height: '85%',
      disableClose: false,
    });
  }

  update() {
    if (!this.wrapper) {
      return;
    }

    console.log("update", Array.from(this.wrapper!.nativeElement.querySelectorAll('.item-wrapper')));
    setTimeout(() => {
      this.updateRefs();
      this.updateOffsets();
    }, 100);
  }

  private updateRefs() {
    const update = () => {
      this._itemRefs = Array.from(this.wrapper!.nativeElement.querySelectorAll('.item-wrapper'));
      this.updateOffsets();
    };

    // if wrapper is not yet referenced, wait for it to be and then retry later
    if (!this.wrapper) {
      this._ready.pipe(filter(
          (v) => v),
        take(1)
      ).subscribe(() => {
        update();
      });

      return;
    }

    update();
  }

  private updateOffsets() {
    // if wrapper is not yet referenced, wait for it to be and then retry later
    this._ready.pipe(filter(
        (v) => v),
      take(1)
    ).subscribe(() => {
      const containerWidth = this.wrapper!.nativeElement.clientWidth;
      this._itemRefs.forEach((ref, index) => {
        ref.style.left = `${containerWidth * (index - this.currentIndex)}px`;
      });
    });
  }

  private _itemRefs: HTMLDivElement[] = [];
  private _ready = new BehaviorSubject(false);

}
