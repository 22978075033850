import {groupPathsRoute} from "../app/config/routes";
import mock from "./mock";
import {Stage} from "./stage.enum";

export const environment = {
  stage: Stage.Development,
  homePath: groupPathsRoute.path,
  mock: {
    enabled: false,
    login: true,
    ...mock
  },
  api: {
    host: '/api'
  },
  map: {
    maptilerKey: 'f1xuPnVBBEQFCd26EBiw',
  },
  mapbox: {
    host: 'https://api.mapbox.com',
    key: 'pk.eyJ1IjoiZ2lhbmx1Y2F0aW5uaXJlbGxvOTUiLCJhIjoiY2xwZnFodGpsMXJicTJrczk4NndoZm1ydyJ9.gggJf2_n-7iJR7iT48CDCg',
  },
  resources: {
    imagePath: (id: string) => id
  },
  companyName: 'Movie Logic srl'
};
