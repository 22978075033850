import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {finalize, Observable, tap} from 'rxjs';
import {requestLoaderSettings} from "../config/http-loader-interceptor";
import {LoaderService} from "../services/loader.service";

class DelayedAction {

  // static id = 0;
  // id = 0;

  private promise: Promise<any>;
  private shouldRun = true;

  constructor(private action: Function, delay: number) {
    // this.id = DelayedAction.id++;
    // console.log(this.id, 'created');
    this.promise = new Promise((res, rej) => {
      setTimeout(() => {
        if (this.shouldRun) {
          // console.log(this.id, "ran after", delay, this.shouldRun);
          res(this.action());
        } else {
          res(false);
        }
      }, delay);
    });
  }

  interrupt() {
    // console.log(this.id, "interrupted");
    this.shouldRun = false;
  }

}

@Injectable()
export class RequestLoaderInterceptor implements HttpInterceptor {

  constructor(
    private loadersvc: LoaderService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const loaderSettingsEntry = requestLoaderSettings
      .find(e => {
        if (typeof e.url === 'object') {
          return request.url.match(e.url);
        } else {
          return e.url.trim().toLowerCase() === request.url.trim().toLowerCase();
        }
      })
    ;

    // do not enable the loader if the request url is excluded in the configuration.
    // otherwise, load any parameters to pass to the loader service.
    // ideally, these parameters contain styles for the spinner (opacity? logo?)
    let enableParams: any;
    if (loaderSettingsEntry) {
      if (loaderSettingsEntry.exclude) {
        return next.handle(request);
      } else {
        enableParams = loaderSettingsEntry.enableParams;
      }
    }

    // when intercepting the first request, enable the loader after X ms.
    // if the request completes before that time, this action will be prevented (DelayedAction.interrupt() call).
    // together, these two constraints cause the loader to only be visible for requests that take X ms or more.
    if (this._totalRequests === 0) {
      this._delayed = new DelayedAction(
        () => this.loadersvc.enable(enableParams),
        this.MINIMUM_REQUEST_DURATION_MS_FOR_LOADER
      );
    }

    this._totalRequests++;
    return next
      .handle(request)
      .pipe(
        finalize(() => {
          this._totalRequests--;
          if (this._totalRequests === 0) {
            this.loadersvc.disable();
            this._delayed?.interrupt();
          }
        })
      )
    ;
  }

  private _delayed?: DelayedAction;
  private _totalRequests = 0;
  private readonly MINIMUM_REQUEST_DURATION_MS_FOR_LOADER = 25;

}
