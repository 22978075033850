import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import SystemUserDto from "../../dtos/local/SystemUser.dto";
import {CreateUserPayloadDto} from "../../dtos/remote/service-payloads/CreateUserPayload.dto";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private readonly baseUrl = `${environment.api.host}/user`;
  private readonly bauthUrl = `${environment.api.host}/auth`;

  constructor(
    private http: HttpClient
  ) {
  }


  public async getUsers(): Promise<SystemUserDto[]> {
    return firstValueFrom(
      this.http.get<SystemUserDto[]>(this.baseUrl)
    );
  }

  public async getUser(id: number): Promise<SystemUserDto> {
    return firstValueFrom(
      this.http.get<SystemUserDto>(this.baseUrl + `/${id}`)
    );
  }

  public async createUser(newUserDto: CreateUserPayloadDto) {
    return firstValueFrom(
      this.http.post(
        this.bauthUrl + '/register',
        newUserDto
      )
    );
  }

  public async deleteUser(id: number) {
    return firstValueFrom(
      this.http.delete(
        `${this.baseUrl}/${id}`
      )
    );
  }

  public async updateUser(id: number, payload: CreateUserPayloadDto) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/updateuserbyid/${id}`,
        payload
      )
    );
  }

}
