<div class="wrapper">
  <div class="icon">
    <mat-icon>search</mat-icon>
  </div>
  <div class="content">
    <app-geocode-textbox
      [formControl]="query"
      [searchDebounceMs]="searchDebounceMs"
      (searchTimerStarted)="handleTimerStarted()"
      (geocodingOptionSelected)="geocodingOptionSelected.emit($event)"
    ></app-geocode-textbox>
  </div>
  <mat-progress-bar *ngIf="useProgressBar" style="position: absolute; left: 0; bottom: 0;"
                    [mode]="progressBar.mode"
                    [value]="progressBar.value"
  ></mat-progress-bar>
</div>
