import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {intensityDomain, intensityIcons} from "../../config/domains/intensity";
import {languageIcons} from "../../config/languages";
import {CreatePathPayload} from "../../dtos/local/CreatePathPayload.interface";
import {LabelValueDto} from "../../dtos/local/LabelValue.dto";
import {SystemLanguageDto} from "../../dtos/local/SystemLanguage.dto";
import {SystemPathDto} from "../../dtos/local/SystemPath.dto";
import {UpdatePathPayload} from "../../dtos/local/UpdatePathPayload.interface";
import {LanguageService} from "../../services/api/language.service";
import {PathsService} from "../../services/api/paths.service";
import {GroupPathDetailPageService} from "../../services/group-path-detail-page.service";
import {FilePickerComponent} from "../file-picker/file-picker.component";
import {FileTypes} from "../file-picker/FileTypes.enum";
import {FormComponentInterface} from "../forms/FormComponent.interface";

@Component({
  selector: 'app-path-metadata-form',
  templateUrl: './path-metadata-form.component.html',
  styleUrls: ['./path-metadata-form.component.scss']
})
export class PathMetadataFormComponent implements OnInit, FormComponentInterface {

  @Input() dto?: SystemPathDto;
  // @Input() showLanguage = true;
  @Input() language?: FormControl<string>;
  @Input() showPublicationFlag = true;
  @Input() showAudio = true;
  @Input() showImages = true;

  form = new FormGroup({
    pathType: new FormControl<number | null>(null, [Validators.required]),
    // language: new FormControl<string | null>('', [Validators.required]),
    isPublished: new FormControl<boolean>(false, [Validators.required]),
    intensity: new FormControl<number | null>(null, [Validators.required]),
    name: new FormControl<string | null>(null, [Validators.required]),
    description: new FormControl<string | null>(null, [Validators.required]),
    // thumbnail: new FormControl<string | null>(null, [Validators.required]),
    audio: new FormControl<string | File | null>(null),
    images: new FormControl<string[] | null>(null, [Validators.required]),
  });

  public readonly intensities: LabelValueDto<number>[] = intensityDomain;
  public languages: SystemLanguageDto[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public languagesvc: LanguageService,
    private pathsvc: PathsService,
    private gpds: GroupPathDetailPageService,
    private txsvc: TranslocoService
  ) {
    (window as any).metadata = this;
    this.gpds.detailsForm = this.form;
    console.log("constructed and registered. exposed as 'metadata'");
  }

  async ngOnInit() {
    this.languages = await this.languagesvc.getLanguages();
    this.initializeForm();
    this.updateVariableFields();
  }

  initializeForm() {
    if (!this.dto) {
      return;
    }

    this.loadFromDto(this.dto);
    if (this.language) {
      this.language.valueChanges.subscribe(async val => {
        console.log("path metadata language changed");
        const dto = await this.pathsvc.getPathById(this.dto!.id, val);
        this.loadFromDto(dto);
      });
    }
  }

  loadFromDto(dto: SystemPathDto) {
    this.form.setValue({
      description: dto.description,
      intensity: dto.intensity,
      isPublished: dto.published,
      name: dto.name,
      pathType: dto.pathType.id,
      audio: dto.audio,
      images: dto.images ?? [],
    });
  }

  reset(): void {
  }

  getSavePayload(): UpdatePathPayload {
    const currentLanguageCode = this.txsvc.getActiveLang();
    const form = this.form.value;
    return {
      audio: form.audio as any,
      images: form.images as any[],
      description: form.description!,
      durationMinutes: 0, // form.durationMinutes,
      intensity: form.intensity!,
      langContentCode: this.language?.value ?? currentLanguageCode,
      lengthMeters: 0, // form.lengthMeters,
      name: form.name!,
      pathType: form.pathType!,
      published: form.isPublished!,
      version: 0
    };
  }

  async save() {
    const payload: UpdatePathPayload = this.getSavePayload();
    return await this.pathsvc.updatePath(this.dto!.id, payload);
  }

  async create() {
    const currentLanguageCode = this.txsvc.getActiveLang();
    const form = this.form.getRawValue();
    const payload: CreatePathPayload = {
      pathGroupId: -1,
      description: form.description!,
      durationMinutes: 0,
      intensity: form.intensity!,
      langContentCode: currentLanguageCode,
      lengthMeters: 0,
      name: form.name!,
      pathType: form.pathType!,
      published: false,
      version: 0
    };
    return await this.pathsvc.createPath(payload);
  }

  private updateVariableFields() {
    // if (!this.showLanguage) {
    //   this.form.controls.language.disable();
    // } else {
    //   this.form.controls.language.enable();
    // }

    if (!this.showPublicationFlag) {
      this.form.controls.isPublished.disable();
    } else {
      this.form.controls.isPublished.enable();
    }

    if (!this.showAudio) {
      this.form.controls.audio.disable();
    } else {
      this.form.controls.audio.enable();
    }

    if (!this.showImages) {
      this.form.controls.images.disable();
    } else {
      this.form.controls.images.enable();
    }
  }

  protected readonly FileTypes = FileTypes;
}
