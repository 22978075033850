<div class="wrapper" #wrapper>
  <div class="left">
    <div class="content">
      <app-navigation-sidebar></app-navigation-sidebar>
    </div>
  </div>
  <div class="right" [class.w-50]="false">
    <router-outlet></router-outlet>
  </div>
</div>
