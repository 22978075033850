import {ActivatedRoute, Route, Router, Routes} from "@angular/router";
import {firstValueFrom} from "rxjs";
import routes from "../config/routes";
import {AnyKeyValueObject} from "./AnyKeyValueObject.interface";
import {mapKeysToString} from "./string.utility";

export const parameterizePathString = function(path: string, params: AnyKeyValueObject): string {
  return parameterizePath(path, params).join('/');
}

export const parameterizePath = function(path: string, params: AnyKeyValueObject): string[] {
  const parameterized = mapKeysToString(path, params);
  return parameterized.split('/');
}

export const getRouteByAbsolutePath = function(path: string) {
  const config = routes;

  const topLevelMatch = config.find(r => r.path === path);
  if (topLevelMatch) {
    return topLevelMatch;
  }

  // some routes have multiple segments as their path (group/paths, system/groups...)
  // others can have as few as one segment (:id, child of system/groups)
  // generate kv map where k = absolute path and v = index access order
  // system/groups/:id 1,2,0 (1 is from Home route - system groups is a child of it)
  const pathMap = generatePathMap(routes);
  // console.log('aom', pathMap);

  // now find the key that best matches the path
  const bestMatch = findBestMatch(path, pathMap);
  // console.log('best match', bestMatch);

  if (bestMatch) {
    return getRouteByAccessPath(pathMap[bestMatch.key]);
  }

  return undefined;
}

export const getRouteIdByAbsolutePath = function(path: string): string {
  const route = getRouteByAbsolutePath(path);
  return route ? route!.data!['id'] : '';
}

export const generatePathMap = function(tree: Routes) {
  const pathMap: { [path: string]: string } = {};

  function traverse(node: Route, parentIndex: number, parent?: Route, key?: string) {
    let fullPath: string;
    let indexPath: string;
    if (!parent) {
      fullPath = node.path!;
      indexPath = parentIndex.toString();
      pathMap[fullPath] = indexPath;
      if (node.children) {
        node.children.forEach((child, index) => {
          traverse(child, index, node, fullPath);
        });
      }
    } else {
      fullPath = [key!, node.path!].filter(x => x).join('/');
      indexPath = [pathMap[key!], parentIndex].join(',');
      pathMap[fullPath] = indexPath;
      if (node.children) {
        node.children.forEach((child, index) => {
          traverse(child, index, node, fullPath);
        });
      }
    }
  }

  tree.forEach((route, index: number) => {
    traverse(route, index);
  });

  return pathMap;
}

export const findBestMatch = function(path: string, map?: any) {
  if (!map) {
    map = generatePathMap(routes);
  }

  const keys = Object.keys(map);
  let bestMatch = null;

  for (const key of keys) {
    const keySegments = key.split('/');
    const pathSegments = path.split('/');

    if (keySegments.length !== pathSegments.length) {
      continue; // Skip if the number of segments doesn't match
    }

    let isMatch = true;
    const params: any = {};

    for (let i = 0; i < keySegments.length; i++) {
      const keySegment = keySegments[i];
      const pathSegment = pathSegments[i];

      if (keySegment.startsWith(':')) {
        const paramName = keySegment.slice(1);
        params[paramName] = pathSegment;
      } else if (keySegment !== pathSegment) {
        isMatch = false;
        break;
      }
    }

    if (isMatch) {
      bestMatch = {
        key,
        params
      };
      break;
    }
  }

  return bestMatch;
}

export const getRouteByAccessPath = function(path: string): Route | undefined {
  const indexPath = path.split(',').map(Number);
  let bin = routes;
  let value: Route | undefined;
  indexPath.forEach(index => {
    if (bin) {
      value = bin[index];
      bin = value.children!;
    }
  });

  return value;
}

export const getRouteAncestors = function(route: Route, stopAt?: Route, includeLast?: boolean): Route[] {
  const list: Route[] = [route];
  const parent = route.data!['parent'];
  if (parent) {
    if (stopAt && stopAt.data!['id'] === parent.data!['id']) {
      if (includeLast) {
        list.push(parent);
      }
      return list;
    }

    list.push(...getRouteAncestors(parent, stopAt));
  }
  return list;
}

export const getAncestryPathFromTo = function(from: Route, to: Route): string {
  const trace = getRouteAncestors(from, to).reverse();
  return trace.map(r => r.path).join('/');
}
