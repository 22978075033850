<!-- TODO: i18n -->
<button mat-raised-button class="accent_success" (click)="setPoint()">
  <mat-icon>edit</mat-icon>
  {{'generic.actions.setCenter' | transloco}}
</button>

<mat-form-field appearance="outline" floatLabel="always" style="width: 100%; margin-top: 20px;">
  <input type="text" [formControl]="control" [value]="control.value?.geometry?.coordinates?.toString()" hidden>
  <input type="text" matInput [value]="control.value?.geometry?.coordinates?.toString()" disabled placeholder="Coordinate">
</mat-form-field>
