import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-menu-section',
  templateUrl: './menu-section.component.html',
  styleUrls: ['./menu-section.component.scss'],
})
export class MenuSectionComponent {

  @Input() title?: string;
  @Input() separator: boolean = true;
  @Input() separatorIsPartial: boolean = false;
  @Input() expand: boolean = false;

  @HostBinding('class.expand')
  get isExpanded(): boolean {
    return this.expand;
  }

}
