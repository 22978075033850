import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSelectionList} from "@angular/material/list";
import SystemUserDto from "../dtos/local/SystemUser.dto";
import {UsersService} from "../services/api/users.service";

export interface UserPickerData {
  excludeUserIds: number[];
}

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss']
})
export class UserPickerComponent implements OnInit {

  @ViewChild('users') userList!: MatSelectionList;
  availableUsers: SystemUserDto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: UserPickerData,
    private dialog: MatDialogRef<UserPickerComponent>,
    private usersvc: UsersService
  ) {
  }

  async ngOnInit() {
    const allUsers = await this.usersvc.getUsers();
    this.availableUsers = allUsers.filter(u => !this.data.excludeUserIds.includes(u.id));
  }

  cancel() {
    this.dialog.close();
  }

  ok() {
    const selection = this.userList.selectedOptions.selected;
    this.dialog.close(selection.map(opt => opt.value));
  }

}
