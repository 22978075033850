<div class="list-wrapper" cdkDropList (cdkDropListDropped)="drop($event)">
  <div class="list-item" matRipple cdkDrag
       *ngFor="let item of list; let index = index"
       (cdkDragDropped)="itemDropped(item, $event)"
  >
    <div class="image">
      <img *ngIf="item.mediaType === 'image'" [src]="item.thumbnailUrl | mediaUrl : item.resolveThumbnailUrl"
           width="50px" height="40px" alt="thumb"/>
      <mat-icon *ngIf="item.mediaType === 'video'">videocam</mat-icon>
      <mat-icon *ngIf="item.mediaType === 'audio'">volume_up</mat-icon>
    </div>
    <div class="title">{{item.mediaName}}</div>
    <div class="remove" (click)="handleRemoveItem(item, index)">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</div>
