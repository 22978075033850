<div class="wrapper" [class.fullwidth]="fullwidth">
  <section id="page-header">
    <div id="title">
      <div id="title-text">
        <app-page-title-header [params]="titleParams" [overrideTitle]="overrideTitle"></app-page-title-header>
      </div>
      <div id="title-controls">
        <ng-content select="[header-title-controls]"></ng-content>
      </div>
    </div>
    <div id="controls">
      <app-back-button *ngIf="backButton"></app-back-button>
      <p><ng-content select="[header-subtitle]"></ng-content></p>
      <ng-content select="[header-controls]"></ng-content>
    </div>
  </section>
  <app-divider></app-divider>
  <section id="content">
    <ng-content select="[content]"></ng-content>
  </section>
</div>
