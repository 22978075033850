<mat-card mat-ripple [matRippleDisabled]="disabled" (click)="handleClickEvent($event)">
  <div class="tile-image-wrapper" [class]="tile.tileImageWrapperClass">
    <img *ngIf="tile.tileImage" [class]="tile.tileImageClass" [ngSrc]="tile.tileImage" alt="thumbnail" priority fill draggable="false"/>
    <img *ngIf="!tile.tileImage" [class]="tile.tileImageClass" [ngSrc]="'https://placehold.co/600x400'" priority fill draggable="false" />
    <div class="innerTags" *ngIf="tile.innerTags && tile.innerTags.length > 0">
      <div class="innerTag">
        <div [className]="tag.class" *ngFor="let tag of tile.innerTags">
          {{tag.text | transloco}}
        </div>
      </div>
    </div>
  </div>
  <div class="tile-button-wrapper">
    <div class="tile-button-content">
      <div class="tile-button-text">
        <span>{{tile.tileName | transloco}}</span>
        <div class="labelTags" *ngIf="tile.labelTags && tile.labelTags.length > 0">
          <div class="labelTag">
            <div [className]="tag.class" *ngFor="let tag of tile.labelTags">
              {{tag.text | transloco}}
            </div>
          </div>
        </div>
      </div>
      <div class="tile-button-chevron">
        <mat-icon matIconSuffix>chevron_right</mat-icon>
      </div>
    </div>
  </div>
</mat-card>
