import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LIGHT_BLUE_SHADING} from "../../components/shaded-placeholder-icon/shaded-placeholder-icon.component";
import GroupDto from "../../dtos/local/Group.dto";
import {GroupsService} from "../../services/api/groups.service";

@Component({
  selector: 'app-system-groups-page',
  templateUrl: './system-groups-page.component.html',
  styleUrls: ['./system-groups-page.component.scss']
})
export class SystemGroupsPageComponent implements OnInit {

  systemGroups: GroupDto[] = [];
  selectedIndex: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svc: GroupsService,
  ) { }

  async ngOnInit() {
    this.systemGroups = await this.svc.getGroups() as GroupDto[];
  }

  async viewGroupDetail(group: GroupDto) {
    await this.router.navigate([group.id], {relativeTo: this.route});
    // const route = routes.systemGroupDetailRoute;
    // const commands = parameterizePath(route.path as string, { id: group.id });
    // await this.router.navigate(commands);
  }

  changeFilter(number: number) {
    this.selectedIndex = number;
  }

  async createGroup() {
    await this.router.navigate(
      ['new'],
      {
        relativeTo: this.route
      }
    );
  }

  protected readonly LIGHT_BLUE_SHADING = LIGHT_BLUE_SHADING;

}
