import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {ReorderableListItem} from "./ReorderableListItem.interface";
import {ReorderableListItemClickEvent} from "./ReorderableListItemClickEvent.interface";
import {ReorderableListItemMovedEvent} from "./ReorderableListItemMovedEvent.interface";
import {ReorderableListItemRemovedEvent} from "./ReorderableListItemRemovedEvent.interface";

@Component({
  selector: 'app-reorderable-item-list',
  templateUrl: './reorderable-item-list.component.html',
  styleUrls: ['./reorderable-item-list.component.scss']
})
export class ReorderableItemListComponent<T> {

  @ContentChild('image') imageTemplate?: TemplateRef<any>;

  @Input({ required: true }) list: ReorderableListItem<T>[] = [];
  @Input() reorderable = true;
  @Input() canRemove = true;

  @Output() itemClicked = new EventEmitter<ReorderableListItemClickEvent<T>>();
  @Output() itemRemoved = new EventEmitter<ReorderableListItemRemovedEvent<T>>();
  @Output() itemMoving = new EventEmitter<ReorderableListItemMovedEvent<T>>();
  @Output() itemMoved = new EventEmitter<ReorderableListItemMovedEvent<T>>();

  drop(event: CdkDragDrop<string[]>) {
    this.itemMoving.emit({
      item: this.list[event.previousIndex],
      oldIndex: event.previousIndex,
      newIndex: event.currentIndex
    });
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
    this.itemMoved.emit({
      item: this.list[event.previousIndex],
      oldIndex: event.previousIndex,
      newIndex: event.currentIndex
    });
  }

  itemDropped(item: ReorderableListItem<T>, $event: CdkDragDrop<any>) {
    // remove any leftover ripples
    $event.item.element.nativeElement.querySelectorAll('.mat-ripple-element')
      .forEach(ripple => ripple.remove())
    ;
  }

  handleRemoveItem(item: ReorderableListItem<T>, index: number) {
    this.list.splice(index, 1);
    this.itemRemoved.emit({ item, index });
  }

  handleItemClicked(item: ReorderableListItem<T>, index: number) {
    this.itemClicked.emit({ item, index });
  }

}
