import {AnyKeyValueObject} from "./AnyKeyValueObject.interface";

export const mapKeysToString = function(string: string, object: AnyKeyValueObject) {
  const regex = /:(\w+)/g;
  const matches = Array.from(string.matchAll(regex));


  for (const match of matches) {
    const fullMatch = match[0]; // The full matched token
    const key = match[1]; // The captured key

    const replacement = object[key] ?? '';
    string = string.replace(fullMatch, replacement);
  }

  return string;
}
