import {Component, EventEmitter, Input, Output} from '@angular/core';
import TileInterface from "../../dtos/local/Tile.interface";

@Component({
  selector: 'app-tile-list',
  templateUrl: './tile-list.component.html',
  styleUrls: ['./tile-list.component.scss']
})
export class TileListComponent {

  @Input() tiles: TileInterface[] = [];

  @Output() tileClicked = new EventEmitter<TileInterface>();

}
