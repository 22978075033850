import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SystemPathDto} from "../../../dtos/local/SystemPath.dto";
import {WaypointDto} from "../../../dtos/local/Waypoint.dto";
import {WaypointPayload} from "../../../dtos/local/WaypointPayload.interface";

export interface WaypointSelectionEvent {
  index: number;
  dto: Partial<WaypointDto>;
}

@Component({
  selector: 'app-path-waypoints-list-form',
  templateUrl: './path-waypoints-list-form.component.html',
  styleUrls: ['./path-waypoints-list-form.component.scss']
})
export class PathWaypointsListFormComponent implements OnInit {

  @Input() dto!: SystemPathDto;
  @Output() waypointSelected = new EventEmitter<WaypointSelectionEvent>();
  @Output() changed = new EventEmitter<Partial<WaypointDto>[]>();

  waypoints: Partial<WaypointDto>[] = [];

  async ngOnInit() {
    this.waypoints = this.dto.pois;
    (window as any).waypoints = this;
  }

  addWaypoint() {
    this.waypoints.push(this.newWaypoint());
    this.changed.emit(this.waypoints);
  }

  removeWaypoint(waypoint: Partial<WaypointDto>, index: number) {
    this.waypoints.splice(index, 1);
    this.changed.emit(this.waypoints);
  }

  newWaypoint(): Partial<WaypointDto> {
    return {
      // pathId: 0,
      // coordinates: [],
      name: "",
      description: "",
      areaRadiusMeters: 0,
      // video: ""
      // audio: "",
      // images: [],
    };
  }

  handleSelect(waypoint: Partial<WaypointDto>, index: number) {
    this.waypointSelected.emit({
      index: index,
      dto: waypoint
    });
  }

}
