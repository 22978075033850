import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public static readonly SESSION_STORAGE_INTERFACE = localStorage;

  constructor() { }

  read(key: string): string | null {
    return StorageService.SESSION_STORAGE_INTERFACE.getItem(key);
  }

  write(key: string, value: string) {
    return StorageService.SESSION_STORAGE_INTERFACE.setItem(key, value);
  }

  delete(key: string) {
    StorageService.SESSION_STORAGE_INTERFACE.removeItem(key);
  }

  clear() {
    StorageService.SESSION_STORAGE_INTERFACE.clear();
  }

  readJSON<T>(key: string): T {
    const data = this.read(key);
    return data ? JSON.parse(data) : null;
  }

  writeJSON(key: string, value: any) {
    this.write(key, JSON.stringify(value));
    return value;
  }

  readOrWriteJSON<T>(key: string, defaultValue: T): T {
    const current = this.readJSON<T>(key);
    if (current) {
      return current;
    }

    return this.writeJSON(key, defaultValue);
  }

}
