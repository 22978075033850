import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import TileInterface from "../../../dtos/local/Tile.interface";
import {AvatarService} from "../../../services/api/avatar.service";

@Component({
  selector: 'app-system-avatar-list',
  templateUrl: './system-avatar-list.component.html',
  styleUrls: ['./system-avatar-list.component.scss']
})
export class SystemAvatarListComponent  implements OnInit {

  @Output() tileClicked = new EventEmitter<TileInterface>();
  @Output() loaded = new EventEmitter<TileInterface[]>();

  tiles: TileInterface[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public svc: AvatarService
  ) {
  }

  async ngOnInit() {
    const avatars = await this.svc.getAvatars();
    this.tiles = avatars.map(avatar => {
      const tile: TileInterface = {
        tileId: avatar.avatarId,
        // todo: use mediaUrl pipe
        tileImage: environment.resources.imagePath(avatar.url),
        tileName: avatar.name,
      };

      return tile;
    });

    this.loaded.emit(this.tiles);
  }

}
