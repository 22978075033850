<app-map-page-layout [backButton]="true" [titleParams]="titleParams" [BackButtonOverride]="backOverride" [onlyMap]="onlyMap">
  <ng-container header-right>
    <div class="controls-wrapper">
      <div *ngIf="!stateValidity" class="save-warning-message">
        <mat-icon class="icon">warning</mat-icon>
        <span>{{'errors.pathValidation.someFieldsMissing' | transloco}}</span>
      </div>
      <button mat-raised-button id="delete" class="accent_error" style="margin-right: 20px;" (click)="deletePath()">
        <mat-icon>delete</mat-icon>
        {{"generic.actions.deleteEntity" | transloco}}
      </button>
      <button mat-raised-button id="save" class="accent_success" style="min-width: 25%;" (click)="savePath()" [disabled]="!canSave">
        <mat-icon>save</mat-icon>
        {{"generic.actions.saveModifications" | transloco}}
      </button>
    </div>
  </ng-container>
  <ng-container left>
    <ng-container id="path-data-section" *ngIf="path">
      <app-data-language-picker [control]="mainLanguageControl" mffClass="w-100"></app-data-language-picker>
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <span [class.invalidTab]="!waypointsValidity">
              <span *ngIf="!waypointsValidity" class="tabWarningIcon"><mat-icon>warning</mat-icon></span>
              <span *ngIf="!waypoints.selection">{{'generic.entity.path.waypoints.plural' | transloco}}</span>
              <span *ngIf="waypoints.selection">{{'generic.entity.path.waypoints.detail' | transloco}}</span>
            </span>
          </ng-template>
          <ng-container id="waypoints-list-wrapper" *ngIf="!waypoints.selection">
            <div class="waypoints-list-wrapper">
              <app-reorderable-item-list [list]="waypoints.list"
                                         (itemRemoved)="handleWaypointRemoved($event)"
                                         (itemMoved)="handleWaypointMoved($event)"
                                         (itemClicked)="handleWaypointClicked($event)"
              >
                <ng-template #image let-item let-index="index">
<!--                  <mat-icon>location_on</mat-icon>-->
                  <img style="padding: 10px 10px 10px 15px; margin-bottom: -5px;" src="../../../assets/icons/map-pin.png"/>
                </ng-template>
              </app-reorderable-item-list>
              <button mat-raised-button class="accent_info add-waypoint" (click)="createWaypoint()" style="width: 100%">
                <mat-icon>add</mat-icon>
                <span style="font-weight: normal;">
                  {{ 'generic.actions.addNewWaypoint' | transloco }}
                </span>
              </button>
            </div>
          </ng-container>
          <ng-container id="waypoint-data-section" *ngIf="waypoints.selection">
            <app-path-waypoint-detail-form
              [map]="map"
              [path]="path"
              [form]="waypoints.selection"
              [dto]="waypoints.selectedDto"
              (back)="exitWaypoint($event)"
              (changed)="waypointsChanged($event)"
            ></app-path-waypoint-detail-form>
          </ng-container>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span [class.invalidTab]="!metadataValidity">
              <span *ngIf="!metadataValidity" class="tabWarningIcon"><mat-icon>warning</mat-icon></span>
              <span>{{'generic.entity.path.details' | transloco}}</span>
            </span>
          </ng-template>
          <app-path-metadata-form #metadata [dto]="path" [language]="mainLanguageControl"></app-path-metadata-form>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
  </ng-container>
  <ng-container right>
    <div id="map">
      <div id="controls" *ngIf="onlyMap">
        <app-wide-forward-geocode-button
          (geocodingOptionSelected)="solveCreateWaypointPromiseFromGeocodingSelection($event)"
          [coordinateBias]="map.api.getCenter()"
        >
        </app-wide-forward-geocode-button>
      </div>
    </div>
  </ng-container>
</app-map-page-layout>
