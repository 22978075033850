import { Component } from '@angular/core';

@Component({
  selector: 'app-create-system-user-page',
  templateUrl: './create-system-user-page.component.html',
  styleUrls: ['./create-system-user-page.component.scss']
})
export class CreateSystemUserPageComponent {

}
