import {Component, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Route, Router} from "@angular/router";
import {filter} from "rxjs";
import {navigationConfig, NavigationSectionConfig, SectionRouteConfig} from "../../config/sidenav";
import {getRouteAncestors, getRouteByAbsolutePath, getRouteIdByAbsolutePath} from "../../utility/router.utility";

@Component({
  selector: 'app-navigation-sidebar',
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss']
})
export class NavigationSidebarComponent {

  protected readonly navigationConfig = navigationConfig;
  @ViewChild('wrapper') private sidenavWrapper!: ElementRef;

  availableSections!: NavigationSectionConfig[];

  // TODO: set translation parameters (like group name). see en.json/navigation.section.groupSettings (ONLY 1D HASHMAP SUPPORTED)
  translationParameterBag: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.filterSections();
    this.listenToRouteChanges();
  }

  getRouteName(route: SectionRouteConfig): string {
    return route.name ?? (route.route.data as any).routeName;
  }

  async navigateToRoute(route: SectionRouteConfig, $event: MouseEvent) {
    await this.router.navigate([route.route.path]);
  }

  private filterSections() {
    this.availableSections = this.navigationConfig.sections.filter(section => {
      return section.guard ? section.guard() : true;
    });
  }

  private listenToRouteChanges() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(evt => {
      setTimeout(() => {
        const event: NavigationEnd = evt as NavigationEnd;
        const url = event.urlAfterRedirects.substring(1);
        const route = getRouteByAbsolutePath(url);
        // console.log('route changed to', url, route);
        if (!route) {
          console.warn('Could not find a route with the given url.');
          return;
        }

        this.highlightRoute(route);
      }, 10);
    });
  }

  private highlightRoute(route: Route) {
    const routeAncestry: string[] = getRouteAncestors(route).map(r => r.data!['id']);
    const bin: HTMLElement = this.sidenavWrapper.nativeElement;
    // find the first button that includes any id from routeAncestry (parents of route or route itself).
    const relatedButton = Array.from(bin.querySelectorAll('*[data-activatedRouteId]'))
      .find(node => routeAncestry.includes((node as HTMLElement).dataset['activatedrouteid']!))
    ;

    if (!relatedButton) {
      console.warn('Could not find navigation button to highlight.');
      return;
    }

    document.querySelectorAll('.nav-button')
      .forEach(btn => btn.classList.remove('selected'))
    ;
    relatedButton.classList.toggle('selected', true);
  }

}
