import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LIGHT_BLUE_SHADING} from "../../components/shaded-placeholder-icon/shaded-placeholder-icon.component";
import {SystemPermissionDto} from "../../dtos/local/SystemPermission.dto";
import {PermissionsService} from "../../services/api/permissions.service";

@Component({
  selector: 'app-system-permissions-page',
  templateUrl: './system-permissions-page.component.html',
  styleUrls: ['./system-permissions-page.component.scss']
})
export class SystemPermissionsPageComponent implements OnInit {

  systemPermissions: SystemPermissionDto[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svc: PermissionsService,
  ) { }

  async ngOnInit() {
    this.systemPermissions = await this.svc.getPermissions() as SystemPermissionDto[];
  }

  protected readonly LIGHT_BLUE_SHADING = LIGHT_BLUE_SHADING;

}
