import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {systemPathTypeDetailRoute, systemPathTypesRoute} from "../../../config/routes";
import {SavePathTypePayload} from "../../../dtos/local/SavePathTypePayload";
import {SystemPathTypeDto} from "../../../dtos/local/SystemPathType.dto";
import {PathsService} from "../../../services/api/paths.service";
import {parameterizePath} from "../../../utility/router.utility";
import {FormComponentInterface} from "../FormComponent.interface";

@Component({
  selector: 'app-system-path-type-form',
  templateUrl: './system-path-type-form.component.html',
  styleUrls: ['./system-path-type-form.component.scss']
})
export class SystemPathTypeFormComponent implements OnChanges, FormComponentInterface {

  @Input() dto?: SystemPathTypeDto;

  loaded = false;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    // description: new FormControl<string>('', [Validators.required]),
    avatarId: new FormControl<number | null>(null, [Validators.required])
  });

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public svc: PathsService
  ) {
    (window as any).form = this.form;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['dto']) {
      return;
    }

    const dtoChange = changes['dto'];
    if (!dtoChange.currentValue) {
      return;
    }

    this.form.setValue({
      name: this.dto!.name,
      avatarId: this.dto!.avatarId
    });
  }

  reset(): void {
  }

  async save() {
    const payload = this.form.value as SavePathTypePayload;
    if (this.dto) {
      await this.svc.updatePathType(this.dto.id, payload);
    } else {
      const newId = await this.svc.createPathType(payload);
      await this.router.navigate(
        parameterizePath(
          systemPathTypeDetailRoute.path!,
          { pathTypeId: newId }
        )
      );
    }
    location.reload();
  }

  async delete() {
    try {
      await this.svc.deletePathType(this.dto!.id);
      await this.router.navigate([systemPathTypesRoute.path]);
    } catch (err) {
      console.log('error during delete', err);
    }
  }

}
