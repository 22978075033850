<ng-container class="button" *ngIf="(!multi && selection.length === 0) || (multi && selection.length < (max ?? Infinity))">
  <div class="wrapper" matRipple #wrapper
       (drop)="handleDrop($event, wrapper)"
       (dragover)="handleDragover($event, wrapper)"
       (dragenter)="handleDragEnter($event, wrapper)"
       (dragleave)="handleDragLeave($event, wrapper)"
       (click)="handleClick($event, wrapper, input)"
  >
    <div class="label">
      <div class="left">
        <ng-container>
          <span>{{'messages.filePickerPlaceholder' | transloco}}</span>
        </ng-container>
      </div>
      <div class="right">
        <ng-container>
          <mat-icon>add</mat-icon>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<!--<ng-container class="content">-->
<!--  <app-media-list (itemRemoved)="handleItemRemoved($event, input)" [list]="fileList"-->
<!--                  (itemMoved)="handleItemMoved($event)"-->
<!--  ></app-media-list>-->
<!--</ng-container>-->

<input (change)="handleFileSelected($event, input)" [accept]="type" type="file" [multiple]="multi" hidden #input/>
