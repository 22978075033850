import * as maplibre from "maplibre-gl";
import {ItineroLayer} from "../utility/maps/lib/ItineroLayer.interface";

export const ItineroMapDefaults: Partial<maplibre.MapOptions> = {
  bounds: [
    [-1.4434362972604617, 35.763009310751926],
    [27.751570768097025, 48.566297762184035],
  ],
  center: [0, 0],
  zoom: 5.5,
  attributionControl: false,
  trackResize: true,
  style: `https://api.maptiler.com/maps/streets-v2/style.json?key=9DQs1SfNdRm8t0xncrBB`,
};

export const ItineroMapDefaultAssets = {
  defaultMarker: {
    url: 'assets/map/mapbox-marker-icon-20px-green.png',
    id: 'green-marker'
  },
  blueMarker: {
    url: 'assets/map/mapbox-marker-icon-20px-blue.png',
    id: 'blue-marker'
  },
  redMarker: {
    url: 'assets/map/mapbox-marker-icon-20px-red.png',
    id: 'red-marker'
  }
};

export const ItineroMapDefaultLayers = {
  defaultMarkers: {
    id: 'default-markers',
    layerSettings: {
      type: "symbol",
      layout: {
        "icon-image": ItineroMapDefaultAssets.defaultMarker.id,
        "icon-size": 1.5,
      }
    }
  } as ItineroLayer,
  defaultMarkersShadow: {
    id: 'default-markers-shadow',
    layerSettings: {
      type: "symbol",
      layout: {
        "icon-image": ItineroMapDefaultAssets.defaultMarker.id,
        "icon-size": 1.5,
      },
      paint: {
        "icon-opacity": 0.5
      }
    }
  } as ItineroLayer,
  defaultLineStrings: {
    id: 'default-lines',
    layerSettings: {
      type: "line",
      // layout: {
      //   "icon-image": ItineroMapDefaultAssets.defaultMarker.id,
      //   "icon-size": 1.5,
      // }
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#117AF6',
        'line-width': 4
      }
    }
  },
  // defaultCircles: {
  //   id: 'default-circles',
  //   layerSettings: {
  //     type: "circle",
  //     paint: {
  //       'circle-color': '#6DC54F',
  //       'circle-radius':  [
  //         'interpolate',
  //         ['linear'],
  //         ['zoom'],
  //         0, // Start zoom level
  //         ['/', ['get', 'radius'], 10], // Convert meters to pixels using a factor (adjust as needed)
  //         10, // End zoom level
  //         ['/', ['get', 'radius'], 5] // Adjust the factor based on your use case
  //       ],
  //       'circle-opacity': 0.5,
  //       'circle-stroke-width': 2,
  //       'circle-stroke-color': '#469623'
  //     }
  //   }
  // }
  defaultCircles: {
    id: 'default-circles',
    layerSettings: {
      type: "fill",
      paint: {
        'fill-color': '#6DC54F', // Fill color (replace with your desired color)
        'fill-opacity': 0.5 // Opacity of the fill
        // 'circle-color': '#6DC54F',
        // 'circle-radius':  [
        //   'interpolate',
        //   ['linear'],
        //   ['zoom'],
        //   0, // Start zoom level
        //   ['/', ['get', 'radius'], 10], // Convert meters to pixels using a factor (adjust as needed)
        //   10, // End zoom level
        //   ['/', ['get', 'radius'], 5] // Adjust the factor based on your use case
        // ],
        // 'circle-opacity': 0.5,
        // 'circle-stroke-width': 2,
        // 'circle-stroke-color': '#469623'
      }
    }
  }
};
