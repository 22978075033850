import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {SystemCityDto} from "../../dtos/local/SystemCity.dto";

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  private readonly baseUrl = `${environment.api.host}/city`;

  constructor(
    private http: HttpClient
  ) { }

  async getCities() {
    return firstValueFrom(
      this.http.get<SystemCityDto[]>(
        this.baseUrl
      )
    );
  }

  async getCity(id: number, langCode?: string) {
    let params: any = {};
    if (langCode) {
      params.langCode = langCode;
    }

    return firstValueFrom(
      this.http.get<SystemCityDto>(
        `${this.baseUrl}/${id}`,
        {
          params: params
        }
      )
    );
  }

  async updateCity(cityId: number, payload: FormData) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/updatecity/${cityId}`,
        payload
      )
    );
  }

  async createCity(payload: FormData) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}`,
        payload
      )
    );
  }

  async setWorkingCity(cityId: number) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/setworkingcity`,
        cityId
      )
    );
  }

  async deleteCity(cityId: number) {
    return firstValueFrom(
      this.http.delete(`${this.baseUrl}/${cityId}`)
    );
  }
}
