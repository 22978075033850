import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  homepageLeftPaneVisibility = new BehaviorSubject(true);

  constructor() { }

  hideHomeLayoutLeftPane() {
    this.homepageLeftPaneVisibility.next(false);
  }

  showHomeLayoutLeftPane() {
    this.homepageLeftPaneVisibility.next(true);
  }

}
