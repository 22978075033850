<!--<button mat-raised-button (click)="exit()">-->
<!--  back-->
<!--</button>-->
<div class="form-wrapper">

  <ng-container *ngIf="dto">
    <button mat-raised-button class="accent_info add-waypoint" (click)="createPoiFromWaypoint()" *ngIf="dto.id !== -1 && !dto.relatedPoiId" style="width: 100%; margin: 5px 0;">
      <mat-icon>add</mat-icon>
      {{ 'messages.paths.createPoiFromWaypoint' | transloco }}
    </button>

<!--    <button mat-raised-button class="accent_info add-waypoint" (click)="viewRelatedWaypoint()" *ngIf="dto.relatedPoiId !== null" style="width: 100%; margin: 5px 0;">-->
<!--      <mat-icon>visibility</mat-icon>-->
<!--      {{ 'messages.paths.viewRelatedWaypoint' | transloco }}-->
<!--    </button>-->
  </ng-container>

  <form class="form" [formGroup]="form">

    <ng-container id="coordinates">
      <section class="center">
        <h3>Coordinate Punto</h3>
        <mat-form-field appearance="outline" floatLabel="always" style="margin-right: 20px;">
          <input type="text" matInput [formControl]="form.controls.coordinates" placeholder="Coordinate">
        </mat-form-field>
        <button mat-raised-button class="accent_success" (click)="setPoint()">
          <mat-icon>edit</mat-icon>
          Imposta punto
        </button>
      </section>
    </ng-container>
    <ng-container id="name">
      <h3>Nome Punto</h3>
      <app-geocode-textbox [formControl]="form.controls.name"
                           [placeholder]="'generic.fields.name' | transloco"
                           (geocodingOptionSelected)="updatePositionViaGeocoding($event)"
      ></app-geocode-textbox>
<!--      <mat-form-field appearance="outline" floatLabel="always">-->
<!--        <input type="text" matInput [formControl]="form.controls.name" placeholder="Nome">-->
<!--      </mat-form-field>-->
    </ng-container>
    <ng-container id="description">
      <h3>Descrizione Punto</h3>
      <mat-form-field appearance="outline" floatLabel="always" style="width: 100%">
        <textarea matInput [formControl]="form.controls.description" placeholder="Descrizione"></textarea>
      </mat-form-field>
    </ng-container>
    <ng-container id="areaRadiusMeters">
      <h3>Area di attivazione in metri</h3>
      <mat-form-field appearance="outline" floatLabel="always">
        <input type="number" min="0" matInput [formControl]="form.controls.areaRadiusMeters">
      </mat-form-field>
    </ng-container>
    <ng-container id="video">
      <h3>Video</h3>
      <app-media-field-editor
        [type]="FileTypes.Videos"
        [formControl]="form.controls.video"
      ></app-media-field-editor>
    </ng-container>
    <ng-container id="audio">
      <h3>Audio</h3>
      <app-media-field-editor
        [type]="FileTypes.Audio"
        [formControl]="form.controls.audio"
      ></app-media-field-editor>
    </ng-container>
    <ng-container id="images">
      <h3>Immagini</h3>
      <app-media-field-editor
        [type]="FileTypes.Images"
        [formControl]="form.controls.images"
        [multi]="true"
      ></app-media-field-editor>
    </ng-container>

  </form>
</div>
