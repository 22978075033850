import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Point} from "@turf/turf";
import {LngLat} from "maplibre-gl";
import {firstValueFrom} from "rxjs";
import {environment} from "../../environments/environment";
import * as turf from "@turf/turf";

@Injectable({
  providedIn: 'root'
})
export class MapboxAPIService {

  private readonly baseUrl = `${environment.mapbox.host}`;
  private readonly directionsApi = `${this.baseUrl}/directions/v5/mapbox`;
  private readonly geocodingApi = `${this.baseUrl}/geocoding/v5`;

  constructor(
    private http: HttpClient
  ) { }

  getDirections(waypoints: turf.Feature<Point>[]) {
    const coordinates = waypoints.map(wp => wp.geometry.coordinates.join(',')).join(';');
    return firstValueFrom<any>(
      this.http.get(
        `${this.directionsApi}/walking/${coordinates}?geometries=geojson&access_token=${environment.mapbox.key}`
      )
    );
  }

  forwardGeocoding(query: string, bias?: LngLat) {
    return firstValueFrom<any>(this.$forwardGeocoding(query, bias));
  }

  $forwardGeocoding(query: string, bias?: LngLat) {
    const parameters = new URLSearchParams();
    parameters.append('access_token', environment.mapbox.key);
    if (bias) {
      parameters.append('proximity', `${bias.lng},${bias.lat}`);
    }

    return this.http.get<any>(
      `${this.geocodingApi}/mapbox.places/${query}.json?${parameters.toString()}`
    );
  }

}
