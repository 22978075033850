<div class="dialog-wrapper">
  <div class="header">
    <div matRipple mat-dialog-close class="close-icon">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="title">
    <h1>{{this.data.message | transloco}}</h1>
  </div>
  <div class="actions">
    <button mat-raised-button *ngFor="let option of data.options" [class]="option.tileImageClass" (click)="select(option)">
      {{option.tileName | transloco}}
    </button>
  </div>
</div>


