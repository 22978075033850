import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {take} from "rxjs";
import TileInterface from "../../dtos/local/Tile.interface";
import {CitiesService} from "../../services/api/cities.service";

@Component({
  selector: 'app-system-city-poi',
  templateUrl: './system-city-poi-page.component.html',
  styleUrls: ['./system-city-poi-page.component.scss']
})
export class SystemCityPoiPageComponent implements OnInit {

  cityId?: number;
  titleParams = {
    cityName: '',
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private citysvc: CitiesService
  ) {
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      const cityId = Number(params.get('cityId'));
      if (isNaN(cityId) || cityId === undefined || cityId === null) {
        console.warn("No cityId param was specified.");
        return;
      }

      const cityData = await this.citysvc.getCity(cityId);
      this.cityId = cityId;
      this.titleParams.cityName = cityData.name;
    });
  }

  ngOnInit(): void {
  }

  async selectPoi($event: TileInterface) {
    await this.router.navigate([$event.tileId], { relativeTo: this.route });
  }

  async create() {
    await this.router.navigate(['new'], { relativeTo: this.route });
  }
}
