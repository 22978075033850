<div class="dialog-wrapper">
  <div class="header">
    <div matRipple mat-dialog-close class="close-icon">
      <mat-icon>close</mat-icon>
    </div>
  </div>
  <div class="title">
    <h1>{{this.data.message | transloco : this.data.messageParameters ?? {} }}</h1>
  </div>
  <div class="actions">
    <button mat-raised-button class="accent_error" (click)="deny()">
      {{(this.data.denyText || 'messages.no') | transloco}}
    </button>
    <button mat-raised-button class="accent_success" (click)="confirm()">
      {{(this.data.confirmText || 'messages.yes') | transloco}}
    </button>
  </div>
</div>


