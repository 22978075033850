import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import GroupDto from "../../dtos/local/Group.dto";
import {UserGroupDto} from "../../dtos/local/UserGroup.dto";
import {GroupsService} from "../../services/api/groups.service";

@Component({
  selector: 'app-group-editor',
  templateUrl: './group-editor.component.html',
  styleUrls: ['./group-editor.component.scss']
})
export class GroupEditorComponent implements OnInit {

  @Input() group?: GroupDto;

  public mode: 'edit' | 'create' = 'edit';
  public readonly MAX_DESCRIPTION_LENGTH = 250;
  public readonly MAX_NAME_LENGTH = 50;

  public form = new FormGroup({
    name: new FormControl<string|null>(null, [Validators.required, Validators.maxLength(this.MAX_NAME_LENGTH)]),
    description: new FormControl<string|null>(null, [Validators.maxLength(this.MAX_DESCRIPTION_LENGTH)]),
  });

  constructor(
    private svc: GroupsService,
    private tx: TranslocoService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    if (this.group) {
      this.form.setValue({
        name: this.group.name,
        description: this.group.description,
      });

      if (this.group.id < 0) {
        this.mode = "create";
      } else {
        this.mode = "edit";
      }
    }
  }

  async save() {
    if (this.group!.id > -1) {
      await this.svc.editGroup(this.group!.id, this.form.value);
    } else {
      const form = this.form.value;
      const createPayload = {
        name: form.name,
        applyNameToAllLang: false,
        disabled: true
      };
      await this.svc.createGroup(createPayload);
      await this.router.navigate(['..'], {relativeTo: this.route});
    }
  }

  async delete() {
    await this.svc.deleteGroup(this.group!.id);
  }

}
