import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import GroupDto from "../../dtos/local/Group.dto";
import {GroupRelationDto} from "../../dtos/local/GroupRelationDto";
import {UserGroupDto} from "../../dtos/local/UserGroup.dto";
import {GroupListDto} from "../../dtos/remote/service-responses/GroupList.dto";

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  private readonly baseUrl = `${environment.api.host}/group`;

  constructor(
      private http: HttpClient
  ) { }

  public async getGroups(): Promise<GroupListDto> {
    return firstValueFrom(
        this.http.get<GroupListDto>(
          this.baseUrl
        )
    );
  }

  public async getGroup(id: number): Promise<GroupDto> {
    return firstValueFrom(
        this.http.get<GroupDto>(
          `${this.baseUrl}/${id}`
        )
    );
  }

  async createGroup(form: any) {
    return firstValueFrom(
      this.http.post(
        this.baseUrl,
        form
      )
    );
  }

  async editGroup(id: number, value: any) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/${id}`,
        value
      )
    );
  }

  async deleteGroup(id: number) {
    return firstValueFrom(
      this.http.delete(
        `${this.baseUrl}/${id}`
      )
    );
  }

  async removeUserFromGroup(user: GroupRelationDto) {
    return firstValueFrom(
      this.http.delete(
        `${this.baseUrl}/removeuserfromgroup/${user.groupId}/${user.userId}`
      )
    );
  }

  async addUserToGroup(groupId: number, userId: number, roleId: number) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/addusertogroup`,
        {
          groupId: groupId,
          userId: userId,
          roleId: roleId
        }
      )
    );
  }

}
