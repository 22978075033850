<app-media-picker #picker *ngIf="listItems.length === 0 || multi"
                  [formControl]="pickerControl"
                  [type]="type"
                  [multi]="multi"
                  [max]="max"
></app-media-picker>
<app-media-list *ngIf="galleryItems.length > 0"
                [list]="listItems"
                (itemMoved)="moveItem($event)"
                (itemRemoved)="removeItem($event)"
></app-media-list>
<app-media-gallery #gallery *ngIf="galleryItems.length > 0"
                   [medias]="galleryItems"
></app-media-gallery>
