import {inject} from "@angular/core";
import {CanActivateFn, Router} from "@angular/router";
import {UserMetadataService} from "../services/user-metadata.service";

export const IsSuperAdminGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const umd = inject(UserMetadataService);
  // todo: check if user is super admin
  return true;
};
