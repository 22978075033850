import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {
  systemAvatarDetailRoute,
  systemAvatarsRoute,
} from "../../../config/routes";
import {SaveAvatarPayload} from "../../../dtos/local/SaveAvatarPayload";
import {SystemAvatarDto} from "../../../dtos/local/SystemAvatar.dto";
import {AvatarService} from "../../../services/api/avatar.service";
import {parameterizePathString} from "../../../utility/router.utility";
import {FilePickerComponent} from "../../file-picker/file-picker.component";
import {FileTypes} from "../../file-picker/FileTypes.enum";
import {FormComponentInterface} from "../FormComponent.interface";

@Component({
  selector: 'app-system-avatar-form',
  templateUrl: './system-avatar-form.component.html',
  styleUrls: ['./system-avatar-form.component.scss']
})
export class SystemAvatarFormComponent implements OnChanges, FormComponentInterface {

  @Input({required: true}) dto?: SystemAvatarDto;

  loaded = false;
  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    thumbnail: new FormControl<string | File | null>(null, [Validators.required]),
  });

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public svc: AvatarService
  ) {
    (window as any).form = this.form;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['dto']) {
      return;
    }

    const dtoChange = changes['dto'];
    if (!dtoChange.currentValue) {
      return;
    }

    this.form.setValue({
      name: this.dto!.name,
      thumbnail: this.dto!.url
    });
  }

  reset(): void {
  }

  async delete() {
    try {
      await this.svc.deleteAvatar(this.dto!.avatarId);
      await this.router.navigate([systemAvatarsRoute.path]);
    } catch (err) {
      console.log('error during delete', err);
    }
  }

  async save() {
    const payload = this.form.value as SaveAvatarPayload;
    if (this.dto) {
      await this.svc.updateAvatar(this.dto.avatarId, payload);
    } else {
      // create avatar and navigate to page (id should be 'new' when creating entity)
      const id = await this.svc.createAvatar(payload);
      const pathUrl = parameterizePathString(
        systemAvatarDetailRoute.path!,
        { avatarId: id }
      );
      await this.router.navigate([pathUrl]);
    }
  }

  protected readonly FileTypes = FileTypes;
}
