<!--<section id="page-header">-->
<!--  <div id="title">-->
<!--    <app-page-title-header></app-page-title-header>-->
<!--  </div>-->
<!--  <div id="controls">-->
<!--  </div>-->
<!--  <hr>-->
<!--</section>-->
<!--<section id="content">-->
<!--  <app-system-user-list></app-system-user-list>-->
<!--</section>-->
<app-system-page-layout>
  <ng-container content>
    <app-system-user-list></app-system-user-list>
  </ng-container>
</app-system-page-layout>
