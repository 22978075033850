import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {ActivatedRoute, Router} from "@angular/router";
import {take} from "rxjs";
import {SystemPermissionDto} from "../../dtos/local/SystemPermission.dto";
import {PermissionsService} from "../../services/api/permissions.service";
import {RolesService} from "../../services/api/roles.service";
import {default as AdministrativeCategory} from './permission-category-administrative.json';
import {default as Category1} from './permission-category-1.json';

interface PermissionState {
  checked: boolean;
  permission: SystemPermissionDto;
}

interface PermissionCategory {
  name: string;
  status: undefined | boolean;
  codes: string[];
  states: PermissionState[]
}

@Component({
  selector: 'app-system-role-detail-page',
  templateUrl: './system-role-detail-page.component.html',
  styleUrls: ['./system-role-detail-page.component.scss']
})
export class SystemRoleDetailPageComponent implements OnInit {

  initialized = false;

  form = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    permissions: new FormControl<number[]>([])
  });

  categories: PermissionCategory[] = [
    {
      name: "pages.systemRoles.permissionCategories.administrative",
      status: undefined,
      codes: AdministrativeCategory,
      states: []
    },
    {
      name: "pages.systemRoles.permissionCategories.other",
      status: undefined,
      codes: Category1,
      states: []
    },
    {
      name: 'pages.systemRoles.permissionCategories.uncategorized',
      status: undefined,
      codes: [],
      states: []
    }
  ];

  systemPermissions: SystemPermissionDto[] = [];
  rolePermissions: SystemPermissionDto[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private rolesvc: RolesService,
    private permissionsvc: PermissionsService,
    private cdr: ChangeDetectorRef
  ) {
    (window as any).form  = this.form;
  }

  async ngOnInit() {
    // get role id
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      this._roleId = Number(params.get('roleId'));
      await this.loadData();
    });
  }

  private async loadData() {
    if (this._roleId === undefined) {
      return;
    }

    // load role dto
    const roleData = await this.rolesvc.getRole(this._roleId);
    this.form.setValue({
      name: roleData.localizedName,
      permissions: roleData.permissions.map(p => p.id)
    });

    // get role permissions
    this.rolePermissions = roleData.permissions;

    // get system permissions
    this.systemPermissions = await this.permissionsvc.getPermissions();

    // get active permission ids list in role
    const activePermissions = roleData.permissions.map(p => p.id);

    // categorize permissions
    const uncategorized = this.categories[2];
    this.systemPermissions.forEach(permission => {
      const category = this.categories
        .find(cat => cat.codes.includes(permission.code)) ?? uncategorized;

      category.states.push({
        checked: activePermissions.includes(permission.id),
        permission: permission
      });
    });

    // categories should be populated manually
    this.categories.forEach(cat => this.updateCategoryStatus(cat));
    this.initialized = true;
  }

  categoryStateChanged($event: MatCheckboxChange, category: PermissionCategory) {
    category.states.forEach(state => state.checked = $event.checked);
    this.updatePermissionsControl();
  }

  permissionStateChanged($event: MatCheckboxChange, systemPermission: PermissionState, category: PermissionCategory) {
    systemPermission.checked = $event.checked;
    this.updatePermissionsControl();
    this.updateCategoryStatus(category);
  }

  async delete() {
    await this.rolesvc.deleteRole(this._roleId!);
    await this.router.navigate(['..'], { relativeTo: this.route });
  }

  async save() {
    await this.rolesvc.saveRole(this._roleId!, this.form.value);
  }

  private updateCategoryStatus(category: PermissionCategory) {
    if (category.states.every(s => s.checked)) {
      category.status = true
    } else {
      if (category.states.every(s => !s.checked)) {
        category.status = false;
      } else {
        category.status = undefined;
      }
    }
    this.cdr.detectChanges();
  }

  private updatePermissionsControl() {
    this.form.controls.permissions.patchValue(
      this.categories.flatMap(category =>
        category.states
          .filter(s => s.checked)
          .map(s => s.permission.id)
      )
    );
    this.form.controls.permissions.markAsDirty();
  }

  private _roleId?: number;
}
