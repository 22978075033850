import {Component, Input} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {map, Observable} from "rxjs";

@Component({
  selector: 'app-page-title-header',
  templateUrl: './page-title-header.component.html',
  styleUrls: ['./page-title-header.component.scss']
})
export class PageTitleHeaderComponent {

  @Input() type: 1 | 2 | 3 | 4 | 5 | 6 | 'p' = 1;
  @Input() params?: any;

  @Input() overrideTitle?: string;

  protected pageTitle: Observable<string>;

  constructor(private route: ActivatedRoute) {
    this.pageTitle = this.route.data.pipe(map(d => d['routeName']));
  }

}
