import {Route, Routes} from "@angular/router";
import {IsAuthenticatedGuard} from "../guards/IsAuthenticated.guard";
import {IsSuperAdminGuard} from "../guards/IsSuperAdmin.guard";
import {CityGroupPathsPageComponent} from "../pages/city-group-paths-page/city-group-paths-page.component";
import {CreateGroupPathPageComponent} from "../pages/create-group-path-page/create-group-path-page.component";
import {CreateSystemUserPageComponent} from "../pages/create-system-user-page/create-system-user-page.component";
import {FaqPageComponent} from "../pages/faq-page/faq-page.component";
import {GroupDetailPageComponent} from "../pages/group-detail-page/group-detail-page.component";
import {GroupPathDetailPageComponent} from "../pages/group-path-detail-page/group-path-detail-page.component";
import {GroupPathsPageComponent} from "../pages/group-paths-page/group-paths-page.component";
import {GroupUsersPageComponent} from "../pages/group-users-page/group-users-page.component";
import {HomePageComponent} from "../pages/home-page/home-page.component";
import {LoginPageComponent} from "../pages/login-page/login-page.component";
import {PathTypesInCityPageComponent} from "../pages/path-types-in-city-page/path-types-in-city-page.component";
import {SystemAvatarDetailPageComponent} from "../pages/system-avatar-detail-page/system-avatar-detail-page.component";
import {SystemAvatarsPageComponent} from "../pages/system-avatars-page/system-avatars-page.component";
import {SystemCitiesPageComponent} from "../pages/system-cities-page/system-cities-page.component";
import {SystemCityDetailPageComponent} from "../pages/system-city-detail-page/system-city-detail-page.component";
import {SystemCityPoiPageComponent} from "../pages/system-city-poi-page/system-city-poi-page.component";
import {SystemGroupsPageComponent} from "../pages/system-groups-page/system-groups-page.component";
import {
  SystemPathTypeDetailPageComponent
} from "../pages/system-path-type-detail-page/system-path-type-detail-page.component";
import {SystemPathTypesPageComponent} from "../pages/system-path-types-page/system-path-types-page.component";
import {SystemPermissionsPageComponent} from "../pages/system-permissions-page/system-permissions-page.component";
import {SystemPoisPageComponent} from "../pages/system-pois-page/system-pois-page.component";
import {SystemRoleDetailPageComponent} from "../pages/system-role-detail-page/system-role-detail-page.component";
import {SystemRolesPageComponent} from "../pages/system-roles-page/system-roles-page.component";
import {SystemUsersPageComponent} from "../pages/system-users-page/system-users-page.component";
import {TestPageComponent} from "../pages/test-page/test-page.component";
import {UserSettingsPageComponent} from "../pages/user-settings-page/user-settings-page.component";
import { v4 as uuid } from 'uuid';
import {SystemPoiDetailPageComponent} from "../pages/system-poi-detail-page/system-poi-detail-page.component";
import {getAncestryPathFromTo} from "../utility/router.utility";

export const loginRoute: Route = {
  path: "login",
  component: LoginPageComponent,
  data: {
    routeName: "routes.login"
  }
};

export const systemGroupDetailRoute: Route = {
  path: ":groupId",
  component: GroupDetailPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.systemGroupDetail"
  }
};

export const createGroupPathRoute: Route = {
  path: "new",
  component: CreateGroupPathPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.createGroupPath"
  }
};

export const groupPathDetailRoute: Route = {
  path: ":pathId",
  component: GroupPathDetailPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.groupPathDetail"
  }
};

// export const cityGroupPathsRoute: Route = {
//   path: ":cityId",
//   component: CityGroupPathsPageComponent,
//   canActivate: [IsAuthenticatedGuard],
//   data: {
//     routeName: "routes.groupPaths"
//   },
//   children: [
//     groupPathDetailRoute
//   ]
// };

export const pathTypesInCityRoute: Route = {
  path: ":cityId",
  component: PathTypesInCityPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.groupPaths"
  },
  children: [
    groupPathDetailRoute
  ]
};

export const groupPathsRoute: Route = {
  path: "group/paths",
  component: GroupPathsPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.groupPaths"
  },
  children: [
    // cityGroupPathsRoute,
    pathTypesInCityRoute,
    createGroupPathRoute,
  ]
};

export const groupUsersRoute: Route = {
  path: "group/users",
  component: GroupUsersPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.groupUsers"
  }
};

export const systemPoiDetailRoute: Route = {
  path: ":poiId",
  component: SystemPoiDetailPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemCityPoiDetail"
  },
  children: []
};

export const systemCityPoiRoute: Route = {
  path: ":cityId",
  component: SystemCityPoiPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemCityPoi"
  },
  children: [
    systemPoiDetailRoute
  ]
};

export const systemPoisRoute: Route = {
  path: "system/pois",
  component: SystemPoisPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemPois"
  },
  children: [
    systemCityPoiRoute
  ]
};

export const systemCityDetailRoute: Route = {
  path: ":cityId",
  component: SystemCityDetailPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemCityDetail"
  },
  children: []
};

export const createSystemCityRoute: Route = {
  path: "new",
  component: SystemCityDetailPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.createSystemCity"
  },
  children: []
};

export const systemCitiesRoute: Route = {
  path: "system/cities",
  component: SystemCitiesPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemCities"
  },
  children: [
    createSystemCityRoute,
    systemCityDetailRoute
  ]
};

export const systemPathTypeDetailRoute: Route = {
  path: ":pathTypeId",
  component: SystemPathTypeDetailPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemPathTypeDetail"
  },
  children: []
};

export const systemPathTypesRoute: Route = {
  path: "system/pathTypes",
  component: SystemPathTypesPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemPathTypes"
  },
  children: [
    systemPathTypeDetailRoute
  ]
};

export const systemAvatarDetailRoute: Route = {
  path: ":avatarId",
  component: SystemAvatarDetailPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemAvatarDetail"
  },
  children: []
};

export const systemAvatarsRoute: Route = {
  path: "system/avatars",
  component: SystemAvatarsPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemAvatars"
  },
  children: [
    systemAvatarDetailRoute
  ]
};

export const systemGroupsRoute: Route = {
  path: "system/groups",
  component: SystemGroupsPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemGroups"
  },
  children: [
    systemGroupDetailRoute
  ]
};

export const createSystemUserRoute: Route = {
  path: ":userId",
  component: CreateSystemUserPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemUserDetail"
  }
};

export const systemUsersRoute: Route = {
  path: "system/users",
  component: SystemUsersPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemUsers"
  },
  children: [
    createSystemUserRoute
  ]
};

export const systemRoleDetailRoute: Route = {
  path: ":roleId",
  component: SystemRoleDetailPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemRoleDetail"
  }
};

export const systemRolesRoute: Route = {
  path: "system/roles",
  component: SystemRolesPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemRoles"
  },
  children: [
    systemRoleDetailRoute
  ]
};

// export const systemPermissionDetailRoute: Route = {
//   path: ":permissionId",
//   component: SystemCreatePermissionPageComponent,
//   canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
//   data: {
//     routeName: "routes.systemPermissionDetail"
//   }
// };

export const systemPermissionsRoute: Route = {
  path: "system/permissions",
  component: SystemPermissionsPageComponent,
  canActivate: [IsAuthenticatedGuard, IsSuperAdminGuard],
  data: {
    routeName: "routes.systemPermissions"
  },
  children: [
    // systemPermissionDetailRoute
  ]
};

export const userSettingsRoute: Route = {
  path: "settings",
  component: UserSettingsPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.userSettings"
  }
};

export const faqRoute: Route = {
  path: "faq",
  component: FaqPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.faq"
  }
};

export const testingRoute: Route = {
  path: "test",
  component: TestPageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.test"
  }
};

export const homePageRoute: Route = {
  path: "",
  component: HomePageComponent,
  canActivate: [IsAuthenticatedGuard],
  data: {
    routeName: "routes.homepage"
  },
  children: [
    groupPathsRoute,
    groupUsersRoute,
    systemPoisRoute,
    systemPathTypesRoute,
    systemCitiesRoute,
    systemAvatarsRoute,
    systemGroupsRoute,
    systemUsersRoute,
    systemRolesRoute,
    systemPermissionsRoute,
    // systemRoleCreationRoute,
    // systemPermissionCreationRoute,
    userSettingsRoute,
    faqRoute,
    testingRoute
  ]
};

const routes: Routes = [
  loginRoute,
  homePageRoute
];

function setRouteMetadata(routes: Routes, parent?: Route) {
  routes.forEach(route => {
    route.data!['id'] = uuid();
    if (parent) {
      route.data!['parent'] = parent;
    }

    if (route.children) {
      setRouteMetadata(route.children, route);
    }
  });
}

function unpackRoutes(routes: Routes, toRoute: Route) {
  routes.forEach(route => {
    if (route.children) {
      unpackRoutes(route.children, toRoute);
    }
  });

  if (routes === toRoute.children) {
    return;
  }

  while (routes.length > 0) {
    const moving = routes.pop()!;
    moving.path = getAncestryPathFromTo(moving, toRoute);
    toRoute.children?.push(moving);
  }
}

// recursively set 'id' and 'parent' fields on route.data
setRouteMetadata(routes);

// recursively unpack all child routes to homePageRoute children level.
// this is necessary because if routes were kept nested, each nested component would require a separate router-outlet
// fixme: it might be possible to just remove this part here and define routes as children of homePageComponent directly
unpackRoutes(homePageRoute.children!, homePageRoute);

console.log(routes);

export default routes;
