<app-system-page-layout>
  <ng-container header-subtitle>View a list of all system defined permissions.</ng-container>
  <ng-container content>
    <div class="list-wrapper">
      <app-wide-icon-button *ngFor="let permission of systemPermissions">
        <ng-container ngProjectAs="content">
          <div class="title">
            <h2 class="name">{{permission.name}}</h2>
            <h4 class="code">{{permission.code}}</h4>
          </div>
          <h3 class="description">{{permission.description}}</h3>
        </ng-container>
      </app-wide-icon-button>
    </div>
  </ng-container>
</app-system-page-layout>
