import {CdkDrag, CdkDropList} from "@angular/cdk/drag-drop";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ChangeDetectorRef, NgModule} from "@angular/core";
import {FormsModule, NgControl, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRippleModule} from "@angular/material/core";
import {MatDialog, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatListModule} from "@angular/material/list";
import {MatMenuModule} from "@angular/material/menu";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSelectModule} from "@angular/material/select";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatSortModule} from "@angular/material/sort";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {BrowserModule} from '@angular/platform-browser';
import {MatCardModule} from "@angular/material/card";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {NgOptimizedImage} from "@angular/common";
import {AddAuthenticationSourceHeaderInterceptor} from "./interceptors/add-authentication-source-header.interceptor";
import {AddBearerTokenHeaderInterceptor} from "./interceptors/add-bearer-token-header.interceptor";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RequestLoaderInterceptor} from "./interceptors/request-loader.interceptor";
import {HomePageComponent} from './pages/home-page/home-page.component';
import {LoaderComponent} from './components/loader/loader.component';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {LogoComponent} from './components/logo/logo.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {FooterComponent} from './components/footer/footer.component';
import {NavigationSidebarComponent} from './components/navigation-sidebar/navigation-sidebar.component';
import {GroupSelectorComponent} from './components/group-selector/group-selector.component';
import {MenuSectionComponent} from './components/menu-section/menu-section.component';
import {ProfileTagComponent} from './components/profile-tag/profile-tag.component';
import {GroupUsersPageComponent} from './pages/group-users-page/group-users-page.component';
import {GroupPathsPageComponent} from './pages/group-paths-page/group-paths-page.component';
import {SystemCityPoiPageComponent} from "./pages/system-city-poi-page/system-city-poi-page.component";
import {SystemGroupsPageComponent} from './pages/system-groups-page/system-groups-page.component';
import {UserSettingsPageComponent} from './pages/user-settings-page/user-settings-page.component';
import {FaqPageComponent} from './pages/faq-page/faq-page.component';
import {TranslocoRootModule} from './modules/transloco-root.module';
import {PathTileComponent} from "./components/path-tile/path-tile.component";
import {CreateGroupPathPageComponent} from './pages/create-group-path-page/create-group-path-page.component';
import {TileListComponent} from './components/tile-list/tile-list.component';
import {PathMetadataFormComponent} from './components/path-metadata-form/path-metadata-form.component';
import {ShadedPlaceholderIconComponent} from './components/shaded-placeholder-icon/shaded-placeholder-icon.component';
import {IconAccordionComponent} from './components/icon-accordion/icon-accordion.component';
import {PageTitleHeaderComponent} from './components/page-title-header/page-title-header.component';
import {WideIconButtonComponent} from './components/wide-icon-button/wide-icon-button.component';
import {GroupDetailPageComponent} from './pages/group-detail-page/group-detail-page.component';
import {BackButtonComponent} from './components/back-button/back-button.component';
import {GroupEditorComponent} from './components/group-editor/group-editor.component';
import {GroupUserListComponent} from './components/group-user-list/group-user-list.component';
import {SystemUserListComponent} from './components/system-user-list/system-user-list.component';
import {SystemUsersPageComponent} from './pages/system-users-page/system-users-page.component';
import {DividerComponent} from './components/divider/divider.component';
import {UserMetadataService} from "./services/user-metadata.service";
import {UserPickerComponent} from './user-picker/user-picker.component';
import {SystemPageLayoutComponent} from './components/layouts/system-page-layout/system-page-layout.component';
import {SystemRolesPageComponent} from './pages/system-roles-page/system-roles-page.component';
import {SystemPermissionsPageComponent} from './pages/system-permissions-page/system-permissions-page.component';
import {SystemRoleDetailPageComponent} from './pages/system-role-detail-page/system-role-detail-page.component';
import {CreateSystemUserPageComponent} from './pages/create-system-user-page/create-system-user-page.component';
import {CreateUserComponent} from './components/create-user/create-user.component';
import {SystemPoisPageComponent} from './pages/system-pois-page/system-pois-page.component';
import {SystemPathTypesPageComponent} from './pages/system-path-types-page/system-path-types-page.component';
import {SystemCitiesPageComponent} from './pages/system-cities-page/system-cities-page.component';
import {SystemAvatarsPageComponent} from './pages/system-avatars-page/system-avatars-page.component';
import {SystemPoiDetailPageComponent} from './pages/system-poi-detail-page/system-poi-detail-page.component';
import {SystemCitiesListComponent} from './components/entity-lists/system-cities-list/system-cities-list.component';
import {
  SystemCityPoiListComponent
} from './components/entity-lists/system-city-poi-list/system-city-poi-list.component';
import {SystemCityDetailPageComponent} from './pages/system-city-detail-page/system-city-detail-page.component';
import {SystemCityFormComponent} from './components/forms/system-city-form/system-city-form.component';
import {MapPageLayoutComponent} from './components/layouts/map-page-layout/map-page-layout.component';
import {FilePickerComponent} from './components/file-picker/file-picker.component';
import {SystemPoiFormComponent} from './components/forms/system-poi-form/system-poi-form.component';
import {
  SystemPathTypeDetailPageComponent
} from './pages/system-path-type-detail-page/system-path-type-detail-page.component';
import {SystemPathTypeFormComponent} from './components/forms/system-path-type-form/system-path-type-form.component';
import {SystemAvatarDetailPageComponent} from './pages/system-avatar-detail-page/system-avatar-detail-page.component';
import {SystemAvatarFormComponent} from './components/forms/system-avatar-form/system-avatar-form.component';
import {
  CityGroupPathsListComponent
} from './components/entity-lists/city-group-paths-list/city-group-paths-list.component';
import {CityGroupPathsPageComponent} from './pages/city-group-paths-page/city-group-paths-page.component';
import {GroupPathDetailPageComponent} from './pages/group-path-detail-page/group-path-detail-page.component';
import {
  PathWaypointsListFormComponent
} from './components/forms/path-waypoints-form/path-waypoints-list-form.component';
import {
  PathWaypointDetailFormComponent
} from './components/forms/path-waypoint-detail-form/path-waypoint-detail-form.component';
import {
  SystemPathTypesListComponent
} from './components/entity-lists/system-path-types-list/system-path-types-list.component';
import {StepperComponent} from './components/stepper/stepper.component';
import {SystemAvatarListComponent} from './components/entity-lists/system-avatar-list/system-avatar-list.component';
import {
  SystemAvatarSelectorComponent
} from './components/entity-lists/system-avatar-selector/system-avatar-selector.component';
import {ImageGalleryComponent} from './components/image-gallery/image-gallery.component';
import {MediaPickerComponent} from './components/media-picker/media-picker.component';
import {MediaListComponent} from './components/media-list/media-list.component';
import {TestPageComponent} from './pages/test-page/test-page.component';
import {MediaGalleryComponent} from './components/media-gallery/media-gallery.component';
import {MediaUrlPipe} from './pipes/media-url.pipe';
import {MediaFieldEditorComponent} from './components/media-field-editor/media-field-editor.component';
import {ReorderableItemListComponent} from './components/reorderable-item-list/reorderable-item-list.component';
import {PathTypesInCityPageComponent} from './pages/path-types-in-city-page/path-types-in-city-page.component';
import {ConfirmDialogComponent} from './components/dialogs/confirm-dialog/confirm-dialog.component';
import {ChoiceDialogComponent} from './components/dialogs/choice-dialog/choice-dialog.component';
import {
  WideForwardGeocodeButtonComponent
} from './components/wide-forward-geocode-button/wide-forward-geocode-button.component';
import {DataLanguagePickerComponent} from './components/data-language-picker/data-language-picker.component';
import {CoordinatePickerComponent} from './components/coordinate-picker/coordinate-picker.component';
import {GeocodeTextboxComponent} from './components/geocode-textbox/geocode-textbox.component';
import { InputDialogComponent } from './components/dialogs/input-dialog/input-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LoaderComponent,
    LoginPageComponent,
    LoginFormComponent,
    LogoComponent,
    SpinnerComponent,
    FooterComponent,
    NavigationSidebarComponent,
    GroupSelectorComponent,
    MenuSectionComponent,
    ProfileTagComponent,
    GroupUsersPageComponent,
    GroupPathsPageComponent,
    SystemGroupsPageComponent,
    UserSettingsPageComponent,
    FaqPageComponent,
    PathTileComponent,
    CreateGroupPathPageComponent,
    TileListComponent,
    PathMetadataFormComponent,
    ShadedPlaceholderIconComponent,
    IconAccordionComponent,
    PageTitleHeaderComponent,
    WideIconButtonComponent,
    GroupDetailPageComponent,
    BackButtonComponent,
    GroupEditorComponent,
    GroupUserListComponent,
    SystemUserListComponent,
    SystemUsersPageComponent,
    DividerComponent,
    UserPickerComponent,
    SystemPageLayoutComponent,
    SystemRolesPageComponent,
    SystemPermissionsPageComponent,
    SystemRoleDetailPageComponent,
    CreateSystemUserPageComponent,
    CreateUserComponent,
    SystemPoisPageComponent,
    SystemPathTypesPageComponent,
    SystemCitiesPageComponent,
    SystemAvatarsPageComponent,
    SystemCityPoiPageComponent,
    SystemPoiDetailPageComponent,
    SystemCitiesListComponent,
    SystemCityPoiListComponent,
    SystemCityDetailPageComponent,
    SystemCityFormComponent,
    MapPageLayoutComponent,
    FilePickerComponent,
    SystemPoiFormComponent,
    SystemPathTypeDetailPageComponent,
    SystemPathTypeFormComponent,
    SystemAvatarDetailPageComponent,
    SystemAvatarFormComponent,
    CityGroupPathsListComponent,
    CityGroupPathsPageComponent,
    GroupPathDetailPageComponent,
    PathWaypointsListFormComponent,
    PathWaypointDetailFormComponent,
    SystemPathTypesListComponent,
    StepperComponent,
    SystemAvatarListComponent,
    SystemAvatarSelectorComponent,
    ImageGalleryComponent,
    MediaPickerComponent,
    MediaListComponent,
    TestPageComponent,
    MediaGalleryComponent,
    MediaUrlPipe,
    MediaFieldEditorComponent,
    ReorderableItemListComponent,
    PathTypesInCityPageComponent,
    ConfirmDialogComponent,
    ChoiceDialogComponent,
    WideForwardGeocodeButtonComponent,
    DataLanguagePickerComponent,
    CoordinatePickerComponent,
    GeocodeTextboxComponent,
    InputDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatMenuModule,
    MatSelectModule,
    TranslocoRootModule,
    MatCardModule,
    NgOptimizedImage,
    MatSlideToggleModule,
    MatStepperModule,
    MatExpansionModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatListModule,
    MatDialogModule,
    MatCheckboxModule,
    CdkDropList,
    CdkDrag,
    MatAutocompleteModule,
    MatProgressBarModule,
  ],
  providers: [
    UserMetadataService,
    {provide: HTTP_INTERCEPTORS, useClass: AddBearerTokenHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AddAuthenticationSourceHeaderInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: RequestLoaderInterceptor, multi: true},
    UserPickerComponent,
    MatSnackBar
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
