export interface IRequestLoaderSettings {
  url: string | RegExp;
  exclude?: boolean;
  enableParams?: any;
}

export const requestLoaderSettings: IRequestLoaderSettings[] = [
  {
    url: /.*(auth\/login).*/gi,
    exclude: true
  }
];
