import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LIGHT_BLUE_SHADING} from "../../components/shaded-placeholder-icon/shaded-placeholder-icon.component";
import {SystemRoleDto} from "../../dtos/local/SystemRole.dto";
import {RolesService} from "../../services/api/roles.service";

@Component({
  selector: 'app-system-roles-page',
  templateUrl: './system-roles-page.component.html',
  styleUrls: ['./system-roles-page.component.scss']
})
export class SystemRolesPageComponent implements OnInit {

  systemRoles: SystemRoleDto[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svc: RolesService,
  ) { }

  async ngOnInit() {
    this.systemRoles = await this.svc.getRoles() as SystemRoleDto[];
  }

  async viewRoleDetail(role: SystemRoleDto) {
    await this.router.navigate([role.id], {relativeTo: this.route});
  }

  protected readonly LIGHT_BLUE_SHADING = LIGHT_BLUE_SHADING;

}
