import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {languageIcons} from "../../config/languages";
import {SystemLanguageDto} from "../../dtos/local/SystemLanguage.dto";
import {LanguageService} from "../../services/api/language.service";
import {UserMetadataService} from "../../services/user-metadata.service";

@Component({
  selector: 'app-data-language-picker',
  templateUrl: './data-language-picker.component.html',
  styleUrls: ['./data-language-picker.component.scss']
})
export class DataLanguagePickerComponent implements OnInit, OnDestroy {

  @Input({required: true}) control!: FormControl;
  @Input() mffClass?: string;

  languages: SystemLanguageDto[] = [];

  constructor(
    private umd: UserMetadataService,
    public languagesvc: LanguageService,
  ) { }

  async ngOnInit() {
    this._initialLanguage = this.umd.dataLanguage;
    this.languages = await this.languagesvc.getLanguages();
    await this.initializeUmdHook();
  }

  async ngOnDestroy() {
    if (this._initialLanguage && this.umd.dataLanguage !== this._initialLanguage) {
      console.log("resetting language");
      await this.umd.setDataLanguage(this._initialLanguage);
    }
  }

  getLanguageLabel(value: string | null) {
    if (!value) {
      return;
    }

    const lang = this.languages.find(l => l.code === value);
    return lang?.name;
  }

  getLanguageIcon(value: string | null) {
    if (!value) {
      return;
    }

    return languageIcons[value];
  }

  private async initializeUmdHook() {
    const behavior = async () => {
      if (this.control.value) {
        console.log("set data language to:", this.control.value);
        await this.umd.setDataLanguage(this.control.value);
      }
    }

    await behavior();
    this.control.valueChanges.subscribe(async value => {
      await behavior();
    });
  }

  private _initialLanguage?: string;

}
