import {group} from "@angular/animations";
import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {environment} from "../../environments/environment";
import {loginRoute} from "../config/routes";
import {UserGroupDto} from "../dtos/local/UserGroup.dto";
import {DecodedJwtPayloadDto} from "../dtos/remote/DecodedJwtPayload.dto";
import {RawJwtPayloadDto} from "../dtos/remote/JwtPayload.dto";
import LoginRequestDto from "../dtos/remote/LoginRequest.dto";
import {StorageService} from "./storage.service";
import jwt_decode from 'jwt-decode';

/*

  LOGIN PIPELINE:

  REQUEST -> JWT -> jwt_decode -> RawJWTPayloadDto -> RemoteJwtPayload -> JwtUserdata

 */

@Injectable({
  providedIn: 'root'
})
export class UserMetadataService {

  private readonly JWT_KEY = 'jwt';
  private readonly USERDATA_KEY = 'userdata';
  private readonly GROUP_KEY = 'currentWorkingGroup';
  private readonly WORKING_LANGUAGE_KEY = 'dataLanguage';
  private readonly LOCAL_LANGUAGE_KEY  = 'uiLanguage';

  public authenticated = new BehaviorSubject<boolean>(false);
  public jwt?: string;
  public userdata?: DecodedJwtPayloadDto;
  public currentGroup?: UserGroupDto;

  public dataLanguage?: string;
  public uiLanguage?: string;

  private authUrl = `${environment.api.host}/auth/login`;
  private setGroupUrl = `${environment.api.host}/group/setworkinggroup`;
  private setLanguageUrl = `${environment.api.host}/language/setuserlanguage`;

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private router: Router,
    private txsvc: TranslocoService
  ) {
    (window as any).umd = this;
    this.loadStateFromStorage();
  }

  public async login(dto: LoginRequestDto) {
    /* fixme: this.authenticated.value doesn't seem to reset properly when the session expires.
    this causes problems when the user tries to login again, causing the first re-log attempt fail.
     */

    // if (this.authenticated.value) {
    //   this.exit("Attempted to login while already authenticated.");
    //   return;
    // }

    await this.doLoginRequest(dto);
    if (await this.setDefaultWorkingGroup()) {
      this.saveStateToStorage();
      this.loadStateFromStorage();
    }
  }

  public async logout(reload: boolean = true) {
    this.storage.clear();
    this.jwt = undefined;
    this.userdata = undefined;
    this.currentGroup = undefined;
    this.dataLanguage = undefined;
    this.uiLanguage = undefined;
    this.authenticated.next(false);
    if (reload) {
      await this.router.navigate([loginRoute.path]);
      // location.reload();
    }
  }

  async setGroup(group: UserGroupDto) {
    const response= await firstValueFrom(this.http.post(
      this.setGroupUrl,
      group.GroupId,
      { observe: "response" }
    )) as any as Response;

    if (response.status !== 200) {
      return this.exit("Failed to set working group: server rejected request with non-200 status.");
    }

    this.currentGroup = group;
    this.storage.writeJSON(this.GROUP_KEY, group);
    return group;
  }

  async setDataLanguage(languageCode: string) {
    const response = await firstValueFrom(this.http.post(
      this.setLanguageUrl,
      JSON.stringify(languageCode),
      {
        headers: {
          "Content-Type": "application/json"
        },
        observe: "response"
      }
    )) as any as Response;

    if (response.status !== 200) {
      return this.exit("Failed to set language: server rejected request with non-200 status.");
    }

    this.dataLanguage = languageCode;
    this.storage.writeJSON(this.WORKING_LANGUAGE_KEY, languageCode);
    return languageCode;
  }

  setUiLanguage(languageCode: string) {
    this.uiLanguage = this.storage.writeJSON(this.LOCAL_LANGUAGE_KEY, languageCode);
    this.txsvc.setActiveLang(this.uiLanguage!);
  }

  public hasPermission(code: string): boolean {
    if (!this.currentGroup) {
      return false;
    }

    return !!this.currentGroup.UserRoleInGroup.Permissions.find(p => p.Code === code);
  }

  private loadStateFromStorage() {
    const jwt = this.storage.readJSON<string>(this.JWT_KEY);
    if (!jwt) {
      console.warn("JWT not found: session not authenticated.");
      return;
    }

    let userdata = this.storage.readJSON<DecodedJwtPayloadDto>(this.USERDATA_KEY);
    let groupdata = this.storage.readJSON<UserGroupDto>(this.GROUP_KEY);
    if (!userdata) {
      this.exit("Userdata not found: session is invalid.");
      return;
    }

    if (!groupdata) {
      // an attempt to recover the session could be made here by setting the default working group
      this.exit("Groupdata not found: session is invalid.");
      return;
    }

    // TODO: check that contents of userdata and groupdata match structures. if they don't, invalidate session and reload
    this.jwt = jwt;
    this.userdata = userdata;
    this.currentGroup = groupdata;

    this.checkTokenExpiration();
    this.authenticated.next(true);

    this.setUiLanguageFromStorage();
    // TODO: set data language from storage

    const storedDataLanguage = this.storage.readJSON<string | null>(this.WORKING_LANGUAGE_KEY);
    this.dataLanguage = storedDataLanguage || this.uiLanguage;
    if (this.dataLanguage !== storedDataLanguage) {
      console.warn("Data Language was reset to UI language");
    }
  }

  private setUiLanguageFromStorage() {
    this.setUiLanguage(this.storage.readOrWriteJSON(this.LOCAL_LANGUAGE_KEY, 'it'));
  }

  private async doLoginRequest(dto: LoginRequestDto) {
    // execute login request and set default working group
    const response = await firstValueFrom(this.http.post(
        this.authUrl,
        dto,
        { responseType: "text" }
    ));
    this.jwt = response;
    this.userdata = this.parseJwtPayload(this.jwt);
  }

  private async setDefaultWorkingGroup() {
    if (!this.userdata) {
      this.exit("Cannot set default working group: userdata is required.");
      return false;
    }

    const availableGroups = this.userdata.User_Groups;
    if (availableGroups.length === 0) {
      this.exit("Cannot login: user does not have any available groups to select.")
      return false;
    }

    try {
      const defaultGroup = availableGroups[0];
      await this.setGroup(defaultGroup);
    } catch (e) {
      this.exit(e as any);
      return false;
    }

    return true;
  }

  private exit(message: string) {
    console.log(message);
    this.logout();
  }

  private parseJwtPayload(jwt: string) {
    const raw: RawJwtPayloadDto = jwt_decode(jwt);
    const decoded = raw as any as DecodedJwtPayloadDto;
    decoded.User = JSON.parse(raw.User);
    decoded.User_Groups = JSON.parse(raw.User_Groups);
    return decoded;
  }

  private saveStateToStorage() {
    this.storage.writeJSON(this.JWT_KEY, this.jwt);
    this.storage.writeJSON(this.USERDATA_KEY, this.userdata);
    this.storage.writeJSON(this.GROUP_KEY, this.currentGroup);
  }

  private checkTokenExpiration() {
    if (!this.isStoredTokenExpired()) {
      const expirationTime = this.getExpirationTimeMs();
      if (expirationTime !== null) {
        const timeToExpiration = expirationTime - new Date().getTime();
        setTimeout(() => {
          this.checkTokenExpiration();
        }, timeToExpiration + 1000);
      }
    } else {
      this.exit("Got an expired token from storage, logging out!");
      return;
    }
  }

  private isStoredTokenExpired(): boolean {
    // return true;
    const delta = this.getExpirationDeltaMs();
    return delta !== null ? delta < 0 : false;
  }

  private getExpirationTimeMs(): number | null {
    if (!this.userdata) {
      return null;
    }

    return this.userdata.exp * 1000;
  }

  private getExpirationDeltaMs(): number | null {
    const now = (new Date()).getTime();
    const expirationTime = this.getExpirationTimeMs();
    return expirationTime !== null ? expirationTime - now : null;
  }

}
