import {ChangeDetectorRef, Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  enabled = new BehaviorSubject(false);

  constructor() {
  }

  public async enable(params?: any) {
    return new Promise<true>((res, rej) => {
      setTimeout(() => {
        this.enabled.next(true);
        res(true);
      });
    });
  }

  public async disable() {
    return new Promise<false>((res, rej) => {
      setTimeout(() => {
        this.enabled.next(false);
        res(false);
      });
    });
  }

}
