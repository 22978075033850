import {Component, Input} from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {parameterizePath} from "../../utility/router.utility";

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {

  @Input() ClickOverride?: Function;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  async back() {
    await this.hierarchyBack();
  }

  async hierarchyBack() {
    if (this.ClickOverride) {
      await this.ClickOverride(this);
      return;
    }

    await this.defaultBackBehavior();
  }

  async defaultBackBehavior() {
    console.log('url', this.router.url);
    console.log('route', this.route);
    console.log('pm', this.route.snapshot.params);
    const data = await firstValueFrom(this.route.data);
    const parent: Route | undefined = data['parent'];
    if (!parent) {
      return;
    }

    await this.router.navigate(
      parameterizePath(
        parent.path!,
        this.route.snapshot.params
      )
    );
  }

}
