import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../environments/environment";

@Pipe({
  name: 'mediaUrl'
})
export class MediaUrlPipe implements PipeTransform {

  transform(value: string, resolve = true): string {
    if (!resolve) {
      return value;
    }

    return environment.resources.imagePath(value);
  }

}
