import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {UserMetadataService} from "../services/user-metadata.service";

@Injectable()
export class AddBearerTokenHeaderInterceptor implements HttpInterceptor {

  constructor(
      private umd: UserMetadataService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let final = request;
    if (this.umd.jwt) {
      // console.log("Adding Bearer token header to request.");
      final = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.umd.jwt}`)
      });
    }

    return next.handle(final);
  }
}
