<app-system-page-layout>
  <ng-container content>

<!--    <app-media-picker [type]="FileTypes.Images" ></app-media-picker>-->
    <mat-tab-group>
      <mat-tab label="Geocode Textbox">
        <app-geocode-textbox #gtc [formControl]="geocodeTextbox"></app-geocode-textbox>
        <p>control value: {{geocodeTextbox.value}}</p>
        <p>control position: {{gtc.position | json}}</p>
      </mat-tab>
      <mat-tab label="Media List">
        <p>A media list is a component that displays a sortable list of media files.</p>
        <app-media-list [list]="serverMediaList"></app-media-list>
      </mat-tab>
      <mat-tab label="Media Picker">
        <p>A media picker is a control that allows you to upload Media Files and display them.</p>
        <p>It uses a Media List to display the uploaded files in a compact list.</p>

        <h1>Images</h1>
        <app-media-picker [formControl]="mediaField1" [multi]="true" [type]="FileTypes.Images"></app-media-picker>

        <h1>Video</h1>
        <app-media-picker [formControl]="mediaFiled2" [multi]="true" [type]="FileTypes.Videos"></app-media-picker>

        <h1>Audio</h1>
        <app-media-picker [formControl]="mediaFiled3" [multi]="true" [type]="FileTypes.Audio"></app-media-picker>
      </mat-tab>
      <mat-tab label="Media Gallery">
        <ng-template matTabContent>
          <p>A media gallery is a component that displays thumbnails for mixed media content.</p>
          <app-media-gallery [medias]="galleryMediaList"></app-media-gallery>
        </ng-template>
      </mat-tab>
      <mat-tab label="Media Field Editor">
        <p>To edit a media field of any given entity, the previous components can be combined.</p>
      </mat-tab>
      <mat-tab label="Reorderable Item List">
        <app-reorderable-item-list [list]="list">
          <ng-template #image let-item let-index="index">
            <mat-icon>home</mat-icon> ({{index}})
          </ng-template>
        </app-reorderable-item-list>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</app-system-page-layout>
