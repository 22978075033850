import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {LayoutService} from "../../services/layout.service";

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, AfterViewInit {

  @ViewChild('wrapper') wrapper?: ElementRef<HTMLElement>;

  public routeData: any;

  constructor(
    private route: ActivatedRoute,
    public layoutsvc: LayoutService
  ) {
    this.route.data.subscribe((data) => {
      this.routeData = data;
      console.log("route data:", data);
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.layoutsvc.homepageLeftPaneVisibility.subscribe((viz) => {
      this.wrapper!.nativeElement.classList.toggle('onePane', !viz);
    });
  }

}
