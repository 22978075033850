<!-- TODO: disable ripple if disabled -->
<!-- TODO: change style if disabled (set class and define style) -->
<!-- TODO: fix style -->

<ng-container class="button" *ngIf="isEmpty">
  <div class="wrapper" matRipple #wrapper
       (drop)="handleDrop($event, wrapper)"
       (dragover)="handleDragover($event, wrapper)"
       (dragenter)="handleDragEnter($event, wrapper)"
       (dragleave)="handleDragLeave($event, wrapper)"
       (click)="handleClick($event, wrapper, input)"
  >
    <div class="label">
      <div class="left">
        <ng-container>
          <span>{{'generic.actions.clickOrDragToUpload' | transloco}}</span>
        </ng-container>
      </div>
      <div class="right">
        <ng-container>
          <mat-icon>add</mat-icon>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-container class="content">
  <button *ngIf="!isEmpty" (click)="editSelection(input)" mat-raised-button class="accent_success">
    <mat-icon>edit</mat-icon>
    {{'generic.actions.edit' | transloco}}
  </button>
  <ng-container *ngIf="accept === FileTypes.Images && imageUrls.length > 0">
<!--    <app-image-gallery [imageUrls]="imageUrls"></app-image-gallery>-->
<!--    <ol *ngIf="selection.length > 0">-->
<!--      <li *ngFor="let file of selection">-->
<!--        {{file.name}}-->
<!--      </li>-->
<!--    </ol>-->
    <app-media-list [list]="imageUrls"></app-media-list>
  </ng-container>
  <ng-container *ngIf="accept === FileTypes.Videos">
    <ol *ngIf="selection.length > 0">
      <li *ngFor="let file of selection">
        {{file.name}}
      </li>
    </ol>
  </ng-container>
  <ng-container *ngIf="accept === FileTypes.Audio">
    <ol *ngIf="selection.length > 0">
      <li *ngFor="let file of selection">
        {{file.name}}
      </li>
    </ol>
  </ng-container>
</ng-container>

<input (change)="handleFileSelected($event, input)" [accept]="accept" type="file" [multiple]="multi" hidden #input/>
