import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChoiceDialogComponent} from "../choice-dialog/choice-dialog.component";

export interface ConfirmDialogData {
  message: string;
  messageParameters?: any;
  confirmText?: string;
  denyText?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private ref: MatDialogRef<ChoiceDialogComponent>
  ) {
    this.ref.updateSize(window.innerWidth * .35 + 'px', window.innerHeight * .35 + 'px');
  }

  deny() {
    this.ref.close(false);
  }

  confirm() {
    this.ref.close(true);
  }

}
