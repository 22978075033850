import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MediaListItem} from "./MediaListItem.interface";

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent {

  @Input({ required: true }) list: MediaListItem[] = [];
  @Output() itemRemoved = new EventEmitter<{ item: MediaListItem, index: number }>();
  @Output() itemMoved = new EventEmitter<{ item: MediaListItem, oldIndex: number, newIndex: number}>();

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.list, event.previousIndex, event.currentIndex);
  }

  itemDropped(item: MediaListItem, $event: CdkDragDrop<any>) {
    // remove any leftover ripples
    this.itemMoved.emit({
      item: item,
      oldIndex: $event.previousIndex,
      newIndex: $event.currentIndex
    });
    $event.item.element.nativeElement.querySelectorAll('.mat-ripple-element')
      .forEach(ripple => ripple.remove())
    ;
  }

  handleRemoveItem(item: MediaListItem, index: number) {
    this.list.splice(index, 1);
    this.itemRemoved.emit({ item, index });
  }

}
