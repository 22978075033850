import {moveItemInArray} from "@angular/cdk/drag-drop";
import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {FileTypes} from "../file-picker/FileTypes.enum";
import {MediaGalleryItem} from "../media-gallery/media-gallery-item.interface";
import {MediaGalleryComponent} from "../media-gallery/media-gallery.component";
import {MediaListItem} from "../media-list/MediaListItem.interface";
import {MediaPickerComponent} from "../media-picker/media-picker.component";

@Component({
  selector: 'app-media-field-editor',
  templateUrl: './media-field-editor.component.html',
  styleUrls: ['./media-field-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaFieldEditorComponent),
      multi: true
    }
  ],
  host: {
    '(blur)': '_onTouched()'
  }
})
export class MediaFieldEditorComponent implements OnInit, ControlValueAccessor {

  @ViewChild('picker') pickerRef!: MediaPickerComponent;
  @ViewChild('gallery') galleryRef!: MediaGalleryComponent;

  @Input({ required: true }) type!: FileTypes;
  @Input() multi: boolean = false;
  @Input() max?: number = Infinity;

  pickerControl = new FormControl();

  listItems: MediaListItem[] = [];
  galleryItems: MediaGalleryItem[] = [];

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef
  ) {
    (window as any).mediaEditor = this;
    this.pickerControl.valueChanges.subscribe(value => {
      // merge of listItems and pickerControl.value
      // let aggregate: any[] = [];
      let currentValue: any = this._value.value;
      let newValue = this.pickerControl.value;
      // if (currentValue !== null) {
      //   if (Array.isArray(currentValue)) {
      //     aggregate = [...newValue, ...currentValue];
      //   } else {
      //     aggregate = [newValue, currentValue];
      //   }
      // }

      const aggregate = [newValue, currentValue].flat().filter(x => x);
      this._value.next(this.multi ? aggregate : aggregate.pop());
      this.emitCVAChangedEvent();
    });
  }

  ngOnInit(): void {
    this._value.subscribe((val: string | string[] | File | File[] | null) => {
      if (val === null) {
        return;
      }

      const mediaType = this.getMediaType();
      let values;
      if (Array.isArray(val)) {
        values = val;
      } else {
        values = [val];
      }

      const galleryItems: MediaGalleryItem[] = [];
      const listItems: MediaListItem[] = [];
      values.forEach((value, index) => {
        let mediaName: string;
        let thumbnailUrl: string;
        let mediaUrl: string;
        let isFile = false;

        if (value instanceof File) {
          const objectURL = URL.createObjectURL(value);
          isFile = true;
          mediaName = value.name;
          thumbnailUrl = mediaType === 'image' ? objectURL : '';
          mediaUrl = objectURL;

          this._objectUrls.push(objectURL);
        } else {
          mediaName = `${mediaType} ${index + 1}`;
          thumbnailUrl = mediaType === 'image' ? value : '';
          mediaUrl = value;
        }

        galleryItems.push({
          mediaType: mediaType,
          mediaName: mediaName,
          thumbnailUrl: thumbnailUrl,
          mediaUrl: mediaUrl,
          resolveThumbnailUrl: !isFile,
          resolveMediaUrl: !isFile,
        });
        listItems.push({
          mediaType: mediaType,
          mediaName: mediaName,
          thumbnailUrl: thumbnailUrl,
          resolveThumbnailUrl: !isFile
        });
      });

      this.galleryItems = galleryItems;
      this.listItems = listItems;
    });
  }

  // <editor-fold desc="CVA Interface Members">
  registerOnChange(fn: ($event: any) => void): void {
    // console.log('registering onchange', fn);
    // this._onChange = (x) => {
    //   console.log('onchange called');
    //   fn(x);
    // }; // fn;
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    console.log('mediapicker writevalue', value);
    this._renderer.setProperty(this._elementRef.nativeElement, 'value', value);
    this._value.next(value);
  }
  // </editor-fold>

  moveItem($event: { item: MediaListItem; oldIndex: number; newIndex: number }) {
    const cv = this._value.value;
    moveItemInArray(this.listItems, $event.oldIndex, $event.newIndex);
    moveItemInArray(this.galleryItems, $event.oldIndex, $event.newIndex);
    moveItemInArray(cv, $event.oldIndex, $event.newIndex);
    this._value.next(cv);
    this.emitCVAChangedEvent();
  }

  async removeItem($event: { item: MediaListItem; index: number }) {
    if (!this.multi) {
      await new Promise((res, rej) => {
        setTimeout(() => {
          this.pickerRef.reset();
          res(null);
        }, 100);
      })
    }
    let cv = this._value.value;
    this.listItems.splice($event.index, 1);
    this.galleryItems.splice($event.index, 1);
    if (this.multi) {
      cv.splice($event.index, 1);
    } else {
      cv = null;
    }
    this._value.next(cv);
    this.emitCVAChangedEvent();
  }

  private emitCVAChangedEvent() {
    this._onChange(this._value.value);
  }

  private getMediaType() {
    switch (this.type) {
      case FileTypes.Images:
        return 'image';
      case FileTypes.Videos:
        return 'video';
      case FileTypes.Audio:
        return 'audio';
    }
  }

  private _objectUrls: string[] = [];
  private _value = new BehaviorSubject<any>(null);

  _onChange!: (_: any) => void;
  _onTouched!: any;

  protected readonly FileTypes = FileTypes;
  protected readonly File = File;
}
