import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import TileInterface from "../../dtos/local/Tile.interface";
import {PathsService} from "../../services/api/paths.service";

@Component({
  selector: 'app-system-path-types-page',
  templateUrl: './system-path-types-page.component.html',
  styleUrls: ['./system-path-types-page.component.scss']
})
export class SystemPathTypesPageComponent implements OnInit {

  pathTypeTiles: TileInterface[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public svc: PathsService
  ) {
  }

  async ngOnInit() {
    const avatars = await this.svc.getPathTypes();
    this.pathTypeTiles = avatars.map(type => {
      const tile: TileInterface = {
        tileId: type.id,
        tileImage: '',
        tileName: type.name
      };

      return tile;
    });
  }

  async create() {
    await this.router.navigate(['new'], {relativeTo: this.route});
  }

  async edit(tile: TileInterface) {
    // or show create modal?
    await this.router.navigate([tile.tileId], {relativeTo: this.route});
  }

}
