import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import TileInterface from "../../../dtos/local/Tile.interface";
import {AvatarService} from "../../../services/api/avatar.service";
import {PathsService} from "../../../services/api/paths.service";

@Component({
  selector: 'app-system-path-types-list',
  templateUrl: './system-path-types-list.component.html',
  styleUrls: ['./system-path-types-list.component.scss']
})
export class SystemPathTypesListComponent implements OnInit {

  @Output() tileClicked = new EventEmitter<TileInterface>();

  pathTypeTiles: TileInterface[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public svc: PathsService,
    public avatarSvc: AvatarService
  ) {
  }

  async ngOnInit() {
    const pathTypes = await this.svc.getPathTypes();
    const avatars = await this.avatarSvc.getAvatars();
    this.pathTypeTiles = pathTypes.map(type => {
      const avatar = avatars.find(a => a.avatarId === type.avatarId);
      const tile: TileInterface = {
        tileId: type.id,
        // todo: use mediaUrl pipe
        tileImage: avatar ? environment.resources.imagePath(avatar.url) : '',
        tileName: type.name
      };

      return tile;
    });
  }

}
