import { Component } from '@angular/core';

@Component({
  selector: 'app-wide-icon-button',
  templateUrl: './wide-icon-button.component.html',
  styleUrls: ['./wide-icon-button.component.scss']
})
export class WideIconButtonComponent {

}
