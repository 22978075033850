import { Component } from '@angular/core';

@Component({
  selector: 'app-group-users-page',
  templateUrl: './group-users-page.component.html',
  styleUrls: ['./group-users-page.component.scss']
})
export class GroupUsersPageComponent {

}
