<app-system-page-layout [backButton]="true" [titleParams]="titleParams">
  <ng-container header-title-controls>
    <button mat-raised-button class="accent_success" (click)="create()">
      <mat-icon>
        add
      </mat-icon>
      {{ 'generic.actions.createPOI' | transloco }}
    </button>
  </ng-container>
  <ng-container content>
    <app-system-city-poi-list [cityId]="cityId" (tileClicked)="selectPoi($event)"></app-system-city-poi-list>
  </ng-container>
</app-system-page-layout>
