import {HttpClient} from "@angular/common/http";
import { Injectable } from '@angular/core';
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {SystemCityDto} from "../../dtos/local/SystemCity.dto";
import {SystemPoiDto} from "../../dtos/local/SystemPoi.dto";

@Injectable({
  providedIn: 'root'
})
export class PoiService {

  private readonly baseUrl = `${environment.api.host}/poi`;

  constructor(
    private http: HttpClient
  ) { }

  async getPoisByCity(cityId: number) {
    return firstValueFrom(
      this.http.get<SystemPoiDto[]>(
        `${this.baseUrl}/getallspotsbycityid/${cityId}`
      )
    );
  }

  async getPoi(poiId: number, languageCode?: string) {
    let params: any = {};
    if (languageCode) {
      params.langCode = languageCode;
    }

    return firstValueFrom(
      this.http.get<SystemPoiDto>(
        `${this.baseUrl}/${poiId}`,
        {
          params: params
        }
      )
    );
  }

  async updatePoi(poiId: number, payload: FormData) {
    return firstValueFrom(
      this.http.post(
        `${this.baseUrl}/updatepoi/${poiId}`,
        payload
      )
    );
  }

  async createPoi(payload: FormData) {
    return firstValueFrom(
      this.http.post<number>(
        `${this.baseUrl}`,
        payload
      )
    );
  }

  async delete(id: number) {
    return firstValueFrom(
      this.http.delete(`${this.baseUrl}/${id}`)
    );
  }

  async createPoiFromWaypoint(pathId: number, waypointId: number) {
    return firstValueFrom(
      this.http.post<number>(
        `${this.baseUrl}/createpoibypathpoiid`,
        { pathPoiId: waypointId, pathId: pathId }
      )
    );
  }

}
