import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslocoService} from "@ngneat/transloco";
import {take} from "rxjs";
import {FormComponentInterface} from "../../components/forms/FormComponent.interface";
import {SystemPathTypeDto} from "../../dtos/local/SystemPathType.dto";
import {PathsService} from "../../services/api/paths.service";
import {UserMetadataService} from "../../services/user-metadata.service";

@Component({
  selector: 'app-system-path-type-detail-page',
  templateUrl: './system-path-type-detail-page.component.html',
  styleUrls: ['./system-path-type-detail-page.component.scss']
})
export class SystemPathTypeDetailPageComponent  implements OnInit {

  @ViewChild('form') formRef?: FormComponentInterface;

  entityId?: number;
  entity?: SystemPathTypeDto;
  create = false;

  constructor(
    private umd: UserMetadataService,
    private route: ActivatedRoute,
    private tx: TranslocoService,
    private pathsvc: PathsService
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.pipe(take(1)).subscribe(async params => {
      const id = params.get(this.paramName);
      if (id === null) {
        console.warn(`No ${this.paramName} param was specified.`);
        return;
      }

      if (id !== 'new') {
        this.entityId = Number(id);
        this.entity = await this.pathsvc.getPathTypeById(this.entityId);
        this.create = false;
      } else {
        this.entityId = undefined;
        this.entity = undefined;
        this.create = true;
      }
    });
  }

  private readonly paramName = 'pathTypeId';

}
