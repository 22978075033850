<form [formGroup]="form">
  <h2>{{ 'generic.fields.name' | transloco }}</h2>
  <mat-form-field class="name" appearance="outline" floatLabel="always">
    <input type="text" matInput [formControl]="form.controls.name" placeholder="Nome Avatar">
  </mat-form-field>
  <h2>{{ 'generic.actions.selectImage' | transloco }}</h2>
  <div class="avatarThumbEditor">
    <app-media-field-editor
      [type]="FileTypes.Images" [multi]="false" [formControl]="form.controls.thumbnail"
    ></app-media-field-editor>
  </div>
</form>
