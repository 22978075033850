import { Component } from '@angular/core';

@Component({
  selector: 'app-system-users-page',
  templateUrl: './system-users-page.component.html',
  styleUrls: ['./system-users-page.component.scss']
})
export class SystemUsersPageComponent {

}
